import { getAnalytics, logEvent } from "firebase/analytics";
import { SquareArrowLeft, SquareArrowRight, SquareX, ChevronRight, Lock, Menu } from 'lucide-react';
import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import ConsultantHamburger from "./ConsultantHamburger";
import AddRowModal from './ui/AddRowModal';
import StatusBanner from './ui/StatusBanner';
import SubmitConfirmationModal from './ui/SubmitConfirmationModal';
import DeleteConfirmationModal from './ui/DeleteConfirmationModal';
import ApprovedTimesheetsPage from "./ApprovedTimesheetsPage.js";
import PendingApprovalTimesheetsPage from "./PendingApprovalTimesheetsPage.js";
import UnsubmittedTimesheetsPage from "./UnsubmittedTimesheetsPage.js";

const ConsultantTimesheet = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { weekStartDate } = useParams();
  const [timesheetRows, setTimesheetRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isAddRowModalOpen, setIsAddRowModalOpen] = useState(false);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [savingStatus, setSavingStatus] = useState(null);
  const [saveTimer, setSaveTimer] = useState(null);
  const [timesheetStatus, setTimesheetStatus] = useState('draft');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState('Timesheet');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [rowToDelete, setRowToDelete] = useState(null);

  const renderTabs = () => (
    <div className="flex space-x-4 mb-4">
      {['Timesheet', 'Pending Approval', 'Unsubmitted', 'Approved'].map((tab) => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`${statusFilterStyles.base} ${
            activeTab === tab
              ? statusFilterStyles.active
              : statusFilterStyles.default
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  );
  
  const renderContent = () => {
    switch (activeTab) {
      case 'Approved':
        return <ApprovedTimesheetsPage />;
      case 'Pending Approval':
        return <PendingApprovalTimesheetsPage />;
      case 'Unsubmitted':
        return <UnsubmittedTimesheetsPage />;
      default: // Timesheet tab
        return (
          <>
            {/* Week Navigation */}
            <div className={`${timeNavigationStyles.wrapper} justify-between`}>
              <div className="flex items-center">
                <button
                  onClick={() => changeWeek('prev')}
                  className={timeNavigationStyles.button}
                >
                  <SquareArrowLeft size={20} />
                </button>
                <button
                  onClick={() => changeWeek('next')}
                  className={timeNavigationStyles.button}
                >
                  <SquareArrowRight size={20} />
                </button>
                <h2 className={timeNavigationStyles.dateText}>
                  Week of {weekStart.toLocaleDateString()}
                </h2>
              </div>
              <div>
                <span
                  className={`px-3 py-1 rounded-full text-sm font-medium
                  ${timesheetStatus === 'approved' ? 'bg-green-100 text-green-800' :
                    timesheetStatus === 'submitted' ? 'bg-yellow-100 text-yellow-800' :
                    timesheetStatus === 'saved' ? 'bg-blue-100 text-blue-800' :
                    'bg-gray-100 text-gray-800'}`}
                >
                  {timesheetStatus.charAt(0).toUpperCase() + timesheetStatus.slice(1)}
                </span>
              </div>
            </div>

            {/* Timesheet Grid */}
            <div className="overflow-x-auto">
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-1 text-left">Project/Task</th>
                    {[...Array(7)].map((_, i) => {
                      const date = new Date(weekStart);
                      date.setDate(date.getDate() + i);
                      return (
                        <th key={i} className="text-center">
                          <div>{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][i]}</div>
                          <div className="text-sm text-gray-500">
                            {date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}
                          </div>
                        </th>
                      );
                    })}
                    <th className="text-right">Total</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {timesheetRows.map((row, rowIndex) => (
                    <tr key={rowIndex} className={`border-t ${timesheetStatus === 'approved' ? rowStyles.approved : rowStyles.default}`}>
                      <td className="py-2 text-left">
                        <div className="font-medium">{row.projectTitle}</div>
                        <div className="text-sm text-gray-500">
                          {row.taskTitle}
                          {!row.is_billable && (
                            <span className="ml-2 text-xs text-red-500">(Non-billable)</span>
                          )}
                        </div>
                      </td>
                      {row.hours.map((hours, dayIndex) => (
                        <td key={dayIndex} className="px-2">
                          <input
                            type="number"
                            value={hours}
                            onChange={(e) => handleHourChange(rowIndex, dayIndex, e.target.value)}
                            className="w-20 text-center fetch-input"
                            step="0.25"
                            min="0"
                            max="24"
                            disabled={timesheetStatus === 'submitted' || timesheetStatus === 'approved'}
                          />
                        </td>
                      ))}
                      <td className="font-medium">{rowTotal(rowIndex)}</td>
                      <td className="px-2 text-right justify-end">
                        {timesheetStatus === 'submitted' || timesheetStatus === 'approved' ? (
                          <Lock className="text-gray-500" aria-label={timesheetStatus === 'submitted' ? "Submitted" : "Approved"} />
                        ) : (
                          <button
                            onClick={() => {
                              setRowToDelete(rowIndex);
                              setIsDeleteModalOpen(true);
                            }}
                            className="ml-2 text-gray-500 hover:text-red-600"
                            aria-label="Delete Row"
                          >
                            <SquareX />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr className="border-t text-left font-bold">
                    <td>Daily Total</td>
                    {columnTotals().map((total, index) => (
                      <td key={index} className="text-center">{total}</td>
                    ))}
                    <td className="text-center">{grandTotal()}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            {/* Action Buttons */}
            <div className="mt-6 flex justify-between">
              {timesheetStatus === 'null' || timesheetStatus === 'draft' || timesheetStatus === 'saved' ? (
                <>
                  <button
                    onClick={() => setIsAddRowModalOpen(true)}
                    className={actionButtonStyles.addRow}
                  >
                    Add Row
                  </button>

                  <div className="space-x-4">
                    <button
                      onClick={handleSaveButtonClick}
                      className={actionButtonStyles.save}
                    >
                      Save
                    </button>

                    <button
                      onClick={() => setIsSubmitModalOpen(true)}
                      className={actionButtonStyles.submit}
                      disabled={timesheetRows.length === 0}
                    >
                      Submit for Approval
                    </button>
                  </div>
                </>
              ) : timesheetStatus === 'submitted' ? (
                <button
                  onClick={() => setTimesheetStatus('saved')}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-6 py-2 rounded"
                >
                  Edit Timesheet
                </button>
              ) : null}
            </div>
          </>
        );
    }
  };

  const rowStyles = {
    default: '',
    approved: 'bg-gray-100',
  };

  const statusFilterStyles = {
    base: "px-4 py-2 text-sm font-medium",
    default: "text-gray-500 hover:bg-gray-100",
    active: "bg-brandPrimary text-white rounded-md"
  };
  
  const timeNavigationStyles = {
    wrapper: "px-1 flex items-center space-x-4 bg-white p-4 rounded-lg shadow-sm",
    button: "p-2 text-gray-400 hover:text-gray-600",
    dateText: "text-lg font-semibold text-gray-700",
    weekRange: "text-sm text-gray-500"
  };
  
  const actionButtonStyles = {
    save: "bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded",
    addRow: "bg-brandPrimary text-white px-6 py-2 rounded flex items-center",
    submit: "bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded"
  };

  const [weekStart, setWeekStart] = useState(() => {
    if (weekStartDate) {
      return new Date(weekStartDate);
    } else {
      return getWeekStart(new Date());
    }
  });

  // Helper Functions
  function formatDate(date) {
    return date.getFullYear() + '-' +
           String(date.getMonth() + 1).padStart(2, '0') + '-' +
           String(date.getDate()).padStart(2, '0');
  }
  
  function parseDate(dateStr) {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  // Helper function to get week start date
  function getWeekStart(date) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() - d.getDay());
    return d;
  }

  // Fetch user data on mount
  useEffect(() => {
    fetchUser();
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Timesheet',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  // Fetch timesheet data when week changes
  useEffect(() => {
    if (user) {
      fetchTimesheetData();
    }
  }, [user, weekStart]);

  // Auto-save debounce handler
  // const debouncedSave = (() => {
  //   let timer = null;
  //   let pendingUpdates = [];
  
  //   return (updates) => {
  //     pendingUpdates = [...pendingUpdates, ...updates];
  
  //     if (timer) clearTimeout(timer); // Clear the previous timer
  
  //     timer = setTimeout(async () => {
  //       try {
  //         if (pendingUpdates.length === 0) {
  //           setSavingStatus(null);
  //           return;
  //         }
  
  //         setSavingStatus('saving'); // Show saving status before saving
  
  //         await saveTimesheetUpdates(pendingUpdates); // Save to database
  //         pendingUpdates = []; // Clear pending updates
  //         setSavingStatus('saved');
  //         setTimeout(() => setSavingStatus(null), 2000); // Clear status after 2 seconds
  //       } catch (error) {
  //         console.error('Error saving timesheet:', error);
  //         setSavingStatus('error');
  //       }
  //     }, 5000); // Wait for 5 seconds of inactivity
  //   };
  // })();

  const fetchUser = async () => {
    try {
      const { data: { user: authUser }, error } = await supabase.auth.getUser();
      if (error) throw error;
      setUser(authUser);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to fetch user data');
    }
  };

  const fetchTimesheetData = async () => {
    try {
      setIsLoading(true);
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      const { data, error } = await supabase
        .from('timesheet_entries')
        .select(`
          *,
          timesheet_tasks (
            id,
            title,
            is_billable
          ),
          engagements (
            id,
            job:job_id (
              title,
              client:client_id (
                name
              )
            )
          )
        `)
        .eq('consultant_id', user.id)
        .gte('date', formatDate(weekStart))
        .lte('date', formatDate(weekEnd))
        .order('created_at', { ascending: true });

      if (error) throw error;

      console.log ("timesheetdata", data)
      // Process and organize the data
      const organizedData = organizeTimesheetData(data);
      setTimesheetRows(organizedData);
      
      // Set timesheet status
      const statuses = new Set(data.map(entry => entry.status));
      if (statuses.has('approved')) {
        setTimesheetStatus('approved');
      } else if (statuses.has('submitted')) {
        setTimesheetStatus('submitted');
      } else if (statuses.has('saved')) {
        setTimesheetStatus('saved');
      } else {
        setTimesheetStatus('draft');
      }

    } catch (error) {
      console.error('Error fetching timesheet data:', error);
      setError('Failed to load timesheet data');
    } finally {
      setIsLoading(false);
    }
  };

// Organize timesheet data to correctly map entryIds
const organizeTimesheetData = (data) => {
  const rows = {};
  data.forEach(entry => {
    const rowKey = `${entry.engagement_id}-${entry.task_id}`;
    if (!rows[rowKey]) {
      rows[rowKey] = {
        engagement_id: entry.engagement_id,
        task_id: entry.task_id,
        projectTitle: `${entry.engagements.job.title} (${entry.engagements.job.client.name})`,
        taskTitle: entry.timesheet_tasks.title,
        is_billable: entry.timesheet_tasks.is_billable,
        hours: Array(7).fill(''),
        entryIds: Array(7).fill(null)
      };
    } 
    const entryDate = parseDate(entry.date);
    const dayIndex = Math.floor((entryDate - weekStart) / (1000 * 60 * 60 * 24));
    rows[rowKey].hours[dayIndex] = entry.hours.toString();
    rows[rowKey].entryIds[dayIndex] = entry.id; // Ensure entryIds are correctly mapped
  });
  return Object.values(rows);
};

// Save timesheet updates with correct ids to update existing entries
const saveTimesheetUpdates = async (updates) => {
  try {
    const { data, error } = await supabase
      .from('timesheet_entries')
      .upsert(updates, { onConflict: 'id' }) // Ensure upsert uses 'id' for conflict resolution
      .select();

    if (error) throw error;

    const newTimesheetRows = [...timesheetRows];
    data.forEach((entry) => {
      const rowKey = `${entry.engagement_id}-${entry.task_id}`;
      const rowIndex = newTimesheetRows.findIndex(row =>
        row.engagement_id === entry.engagement_id &&
        row.task_id === entry.task_id
      );

      if (rowIndex !== -1) {
        const entryDate = parseDate(entry.date); // Use parseDate instead of new Date
        const dayIndex = Math.floor((entryDate - weekStart) / (1000 * 60 * 60 * 24));
        newTimesheetRows[rowIndex].entryIds[dayIndex] = entry.id;
        newTimesheetRows[rowIndex].hours[dayIndex] = entry.hours.toString();
      }
    });

    setTimesheetRows(newTimesheetRows);

    const analytics = getAnalytics();
    logEvent(analytics, 'timesheet_saved', {
      week_start: weekStart.toISOString(),
      entry_count: updates.length,
    });
  } catch (error) {
    console.error('Error saving timesheet:', error);
    throw error;
  }
};

const handleDeleteRow = async () => {
  const row = timesheetRows[rowToDelete];
  const entryIdsToDelete = row.entryIds.filter(id => id !== null);

  if (entryIdsToDelete.length > 0) {
    try {
      const { error } = await supabase
        .from('timesheet_entries')
        .delete()
        .in('id', entryIdsToDelete);

      if (error) throw error;

      // Remove the row from state
      const newRows = [...timesheetRows];
      newRows.splice(rowToDelete, 1);
      setTimesheetRows(newRows);

      const analytics = getAnalytics();
      logEvent(analytics, 'timesheet_row_deleted', {
        engagement_id: row.engagement_id,
        task_id: row.task_id,
      });
    } catch (error) {
      console.error('Error deleting timesheet entries:', error);
      setError('Failed to delete time entries');
    }
  } else {
    // If there are no entries to delete, simply remove the row
    const newRows = [...timesheetRows];
    newRows.splice(rowToDelete, 1);
    setTimesheetRows(newRows);
  }

  setIsDeleteModalOpen(false);
  setRowToDelete(null);
};

// Handle hour changes by updating existing entries
const handleHourChange = (rowIndex, dayIndex, value) => {
  if (timesheetStatus === 'submitted' || timesheetStatus === 'approved') return;

  const newRows = [...timesheetRows];
  newRows[rowIndex].hours[dayIndex] = value;
  setTimesheetRows(newRows);

  const row = newRows[rowIndex];
  const date = new Date(weekStart);
  date.setDate(date.getDate() + dayIndex);

  const update = {
    consultant_id: user.id,
    engagement_id: row.engagement_id,
    task_id: row.task_id,
    date: formatDate(date),
    hours: parseFloat(value) || 0,
    status: 'saved',
  };

  if (row.entryIds[dayIndex]) {
    update.id = row.entryIds[dayIndex]; // Include id to update existing entry
  }

  // Trigger debounced save
//   debouncedSave([update]);
};

const handleAddRow = (rowData) => {
  setTimesheetRows([...timesheetRows, {
    engagement_id: rowData.engagement.id,
    task_id: rowData.task.id,
    projectTitle: rowData.projectTitle,
    taskTitle: rowData.taskTitle,
    is_billable: rowData.is_billable,
    hours: Array(7).fill(''),
    entryIds: Array(7).fill(null)
  }]);

  const analytics = getAnalytics();
  logEvent(analytics, 'timesheet_row_added', {
    engagement_id: rowData.engagement.id,
    task_id: rowData.task.id
  });
};

// Handle immediate save on Save button click
const handleSaveButtonClick = async () => {
  const updates = timesheetRows.flatMap((row, rowIndex) =>
    row.hours.map((hour, dayIndex) => {
      const date = new Date(weekStart);
      date.setDate(date.getDate() + dayIndex);
      const hours = parseFloat(hour);
      if (isNaN(hours) || hours <= 0) return null;

      const update = {
        consultant_id: user.id,
        engagement_id: row.engagement_id,
        task_id: row.task_id,
        date: formatDate(date), // Use formatDate
        hours,
        status: 'saved',
      };

      if (row.entryIds[dayIndex]) {
        update.id = row.entryIds[dayIndex];
      }

      return update;
    }).filter(Boolean)
  );

  if (updates.length === 0) {
    setSavingStatus('error');
    return;
  }

  try {
    setSavingStatus('saving');
    await saveTimesheetUpdates(updates);
    setSavingStatus('saved');
    setTimeout(() => setSavingStatus(null), 2000);
  } catch (error) {
    setSavingStatus('error');
  }
};

const handleSubmitTimesheet = async () => {
  try {
    setIsSubmitting(true);

    const updates = timesheetRows.flatMap((row) =>
      row.hours.map((hour, dayIndex) => {
        const date = new Date(weekStart);
        date.setDate(date.getDate() + dayIndex);
        const hours = parseFloat(hour);
        if (isNaN(hours) || hours <= 0) return null;

        const update = {
          consultant_id: user.id,
          engagement_id: row.engagement_id,
          task_id: row.task_id,
          date: formatDate(date), // Use formatDate
          hours,
          status: 'submitted',
          submitted_at: new Date().toISOString(),
        };

        if (row.entryIds[dayIndex]) {
          update.id = row.entryIds[dayIndex];
        }

        return update;
      }).filter(Boolean)
    );

    if (updates.length === 0) {
      console.error('No valid timesheet entries to submit.');
      setSavingStatus('error');
      setIsSubmitting(false);
      setIsSubmitModalOpen(false);
      return;
    }

    await saveTimesheetUpdates(updates);

    const analytics = getAnalytics();
    logEvent(analytics, 'timesheet_submitted', {
      week_start: weekStart.toISOString(),
    });

    setTimesheetStatus('submitted');
    setSavingStatus('success');
    setTimeout(() => setSavingStatus(null), 3000);
  } catch (error) {
    console.error('Error submitting timesheet:', error);
    setSavingStatus('error');
  } finally {
    setIsSubmitting(false);
    setIsSubmitModalOpen(false);
  }
};

const changeWeek = (direction) => {
  const newWeekStart = new Date(weekStart);
  newWeekStart.setDate(newWeekStart.getDate() + (direction === 'next' ? 7 : -7));
  setWeekStart(newWeekStart);
};

const rowTotal = (rowIndex) => {
  const row = timesheetRows[rowIndex];
  return row.hours.reduce((sum, h) => sum + (parseFloat(h) || 0), 0).toFixed(2);
};

const columnTotals = () => {
  const totals = Array(7).fill(0);
  timesheetRows.forEach(row => {
    row.hours.forEach((h, i) => {
      totals[i] += parseFloat(h) || 0;
    });
  });
  return totals.map(total => total.toFixed(2));
};

const grandTotal = () => {
  return timesheetRows.reduce((sum, row, rowIndex) => 
    sum + parseFloat(rowTotal(rowIndex)), 0
  ).toFixed(2);
};

if (isLoading) {
  return (
    <div className="min-h-screen bg-gray-100 flex items-center justify-center">
      <div className="text-gray-500">Loading...</div>
    </div>
  );
}

return (
  <>
    <Helmet>
      <title>Weekly Timesheet | fetchConsultant</title>
      <meta name="description" content="Submit and manage your weekly timesheet entries on fetchConsultant." />
    </Helmet>

    <div className="min-h-screen bg-gray-100 flex">
      <ConsultantHamburger 
        user={user} 
        currentPage="timesheet" 
        isOpen={isMenuOpen} 
        onClose={() => setIsMenuOpen(false)} 
      />
      
      <div className="flex-1 flex flex-col md:ml-64">
        <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>
            <h1 className="text-xl font-bold">Weekly Timesheet</h1>
          </div>
          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>

        <main className="flex-1 p-4">
          <div className="max-w-7xl mx-auto">
            {renderTabs()}
            {renderContent()}
            <div className="mb-4">
              <StatusBanner
                status={savingStatus}
                message={
                  savingStatus === 'saving'
                    ? 'Saving changes...'
                    : savingStatus === 'saved'
                    ? 'All changes saved'
                    : savingStatus === 'error'
                    ? 'Error saving changes'
                    : savingStatus === 'success'
                    ? 'Timesheet submitted successfully'
                    : ''
                }
              />
            </div>
          </div>
        </main>
      </div>
    </div>

    {/* Modals */}
    <AddRowModal
      isOpen={isAddRowModalOpen}
      onClose={() => setIsAddRowModalOpen(false)}
      onAddRow={handleAddRow}
    />

    <SubmitConfirmationModal
      isOpen={isSubmitModalOpen}
      onClose={() => setIsSubmitModalOpen(false)}
      onConfirm={handleSubmitTimesheet}
      isSubmitting={isSubmitting}
    />

    <DeleteConfirmationModal
      isOpen={isDeleteModalOpen}
      onClose={() => setIsDeleteModalOpen(false)}
      onConfirm={handleDeleteRow}
    />
  </>
);
};

export default ConsultantTimesheet;