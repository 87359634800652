// src/components/VerifyEmail.js

import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Slider from 'react-slick';

// Import slick carousel styles
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const VerifyEmail = () => {
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Verify Email',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  return (
    <>
      <Helmet>
        <title>Verify Your Email - FetchConsultant</title>
        <meta name="description" content="Please verify your email address to continue with FetchConsultant. Learn about the benefits of our platform while you wait." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col justify-center items-center p-4">
        <div className="max-w-4xl text-left w-full bg-white rounded-lg shadow-xl overflow-hidden">
          <div className="md:flex">
            <div className="md:flex-shrink-0">
              <img 
                className="h-auto max-w-xs max-h-40 object-contain md:max-w-md md:max-h-48 mx-auto md:ml-4 mt-4" 
                src="/images/verify-your-email.png" 
                alt="Verify your email" 
              />
            </div>
            <div className="p-8 flex flex-col justify-center h-52">
              <p className="mt-2 text-gray-600">
                We've sent a verification link to your email address. Please check your inbox and click the link to verify your account.
              </p>
              <p className="mt-2 text-gray-600">
                After verifying, you'll be automatically redirected to complete your account setup.
              </p>
            </div>
          </div>
          <div className="p-8">
            <Slider {...carouselSettings} className="fetchCarousel">
              <div className="px-4">
                <h2 className="uppercase tracking-wide text-m text-center text-indigo-600 font-semibold mb-2">Privacy and Control</h2>
                <p classname="text-gray-600">While LinkedIn generated $15.7 billion in 2023 from Talent Solutions, your information on fetchConsultant remains private. Your profile is only shared when you apply for contracts, giving you full control over your data.</p>
              </div>
              <div className="px-4">
                <h2 className="uppercase tracking-wide text-m text-center text-indigo-600 font-semibold mb-2">Disrupting the Consulting Space</h2>
                <p classname="text-gray-600">Accenture earned $64.1 billion in revenue in 2023. With fetchConsultant, we're disrupting this space by cutting out the middleman. This means more savings for clients and higher earnings for consultants.</p>
              </div>
              <div className="px-4">
                <h2 className="uppercase tracking-wide text-m text-center text-indigo-600 font-semibold mb-2">Fair Economics</h2>
                <p classname="text-gray-600">Traditional consulting firms have an incentive to charge clients as much as possible while paying consultants as little as possible. fetchConsultant aligns incentives, ensuring fair rates for both clients and consultants.</p>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyEmail;