// Avatar.js
import React from 'react';

const Avatar = ({ photo, name, className }) => {
  if (photo) {
    return (
      <img 
        src={photo} 
        alt={name || 'User'} 
        className={`rounded-full ${className}`}
      />
    );
  } else {
    const initials = name
      ? name
          .split(' ')
          .map(n => n[0])
          .join('')
          .toUpperCase()
      : '';
    const colors = ['bg-red-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500'];
    const colorIndex = name ? name.charCodeAt(0) % colors.length : 0;
    const bgColor = colors[colorIndex];

    return (
      <div
        className={`rounded-full flex items-center justify-center text-white ${bgColor} ${className}`}
      >
        {initials}
      </div>
    );
  }
};

export default Avatar;