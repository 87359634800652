import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const DateRangePicker = ({ startDate, endDate, onChange }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="date-picker-wrapper">
        <DatePicker
          selected={startDate ? new Date(startDate) : null}
          onChange={date => onChange({ startDate: date, endDate })}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          className="fetch-input date-picker-custom"
          dateFormat="MM/dd/yyyy"
        />
      </div>
      <span className="text-gray-500">to</span>
      <div className="date-picker-wrapper">
        <DatePicker
          selected={endDate ? new Date(endDate) : null}
          onChange={date => onChange({ startDate, endDate: date })}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          className="fetch-input date-picker-custom"
          dateFormat="MM/dd/yyyy"
        />
      </div>
    </div>
  );
};

export default DateRangePicker;