import React, { useEffect, useState, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import ClientHamburger from './ClientHamburger';
import { Menu, Search, Star, MoreHorizontal, Filter } from 'lucide-react';
import { getAnalytics, logEvent } from "firebase/analytics";
import { UserContext } from '../UserContext';
import NoteModal from './ui/NoteModal';

const ClientInterviewNotes = () => {
  const { user } = useContext(UserContext);
  const [notes, setNotes] = useState([]);
  const [aiSummaries, setAiSummaries] = useState({});
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('All Notes');
  const [searchTerm, setSearchTerm] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [applicantId, setApplicantId] = useState(null);
  const [applicantName, setApplicantName] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const [consultants, setConsultants] = useState([]);
  const [selectedConsultant, setSelectedConsultant] = useState('');
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Interview Notes',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchConsultants();

    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    console.log('Location state changed:', location.state);
    if (location.state?.applicantId) {
      setApplicantId(location.state.applicantId);
      setApplicantName(location.state.applicantName);
      setSelectedConsultant(location.state.applicantId);
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      fetchNotes();
    }
  }, [user, sortConfig, selectedConsultant, applicantId]);

  const fetchConsultants = async () => {
    try {
      const { data, error } = await supabase
        .from('users')
        .select('user_id, name')
        .eq('user_type_id', 2);

      if (error) throw error;
      setConsultants(data);
    } catch (error) {
      console.error('Error fetching consultants:', error);
    }
  };

  const fetchNotes = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      console.log('Fetching notes for consultant:', selectedConsultant || applicantId || 'All Consultants');
      let query = supabase
        .from('interview_notes')
        .select(`
          *,
          notewriter:notewriter_id(name, employer),
          consultant:consultant_id(name, user_id),
          interview:interview_id(
            interview_time,
            ai_summary,
            user_job:user_jobs(
              job:jobs(title)
            )
          )
        `)
        .eq('notewriter.employer', user.employer)
        .order('created_at', { ascending: false });

      if (selectedConsultant) {
        query = query.eq('consultant_id', selectedConsultant);
      } else if (applicantId) {
        query = query.eq('consultant_id', applicantId);
      }

      const { data, error } = await query;
      if (error) throw error;
      console.log('Fetched notes:', data);
      setNotes(data);
      filterNotes(data, selectedConsultant || applicantId);
      fetchAiSummaries(data);
    } catch (error) {
      console.error('Error fetching notes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAiSummaries = async (notesData) => {
    const summaries = {};
    for (const note of notesData) {
      if (note.interview && note.interview.id) {
        try {
          const { data, error } = await supabase
            .from('interviews')
            .select('ai_summary')
            .eq('id', note.interview.id)
            .single();

          if (error) throw error;
          summaries[note.id] = data.ai_summary;
        } catch (error) {
          console.error('Error fetching AI summary:', error);
          summaries[note.id] = null;
        }
      }
    }
    setAiSummaries(summaries);
  };

  const filterNotes = (notesToFilter = notes, consultantId = selectedConsultant) => {
    const filtered = notesToFilter.filter(note => {
      const isMatchingTab = activeTab === 'All Notes' || 
                           (activeTab === 'AI Summaries' ? note.note_type ===  'AI Summary' : note.note_type === activeTab);
      const isMatchingConsultant = consultantId === '' || !consultantId || note.consultant_id === consultantId;
      const isMatchingSearch = searchTerm === '' || 
        note.content?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.interview?.ai_summary?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.notewriter?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.consultant?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.skills?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.strengths?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        note.weaknesses?.toLowerCase().includes(searchTerm.toLowerCase());
  
      return isMatchingTab && isMatchingConsultant && isMatchingSearch;
    });
  
    setFilteredNotes(filtered);
  };

  useEffect(() => {
    filterNotes(notes, selectedConsultant);
  }, [selectedConsultant, notes, activeTab, searchTerm]);

  const handleSeeNote = (note) => {
    setSelectedNote(note);
  };

  const handleEditNote = (note) => {
    navigate('/app/create-interview-note', { state: { note, consultantId: note.consultant_id } });
  };

  const handleCreateNote = () => {
    navigate('/app/create-interview-note', { 
      state: { 
        consultantId: applicantId || selectedConsultant,
        consultantName: applicantName || (selectedConsultant ? consultants.find(c => c.user_id === selectedConsultant)?.name : null)
      } 
    });
  };

  const handleDeleteNote = async (noteId) => {
    if (window.confirm('Are you sure you want to delete this note?')) {
      try {
        const { error } = await supabase
          .from('interview_notes')
          .delete()
          .eq('id', noteId);

        if (error) throw error;
        fetchNotes();
      } catch (error) {
        console.error('Error deleting note:', error);
      }
    }
  };

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const toggleDropdown = (noteId) => {
    setActiveDropdown(activeDropdown === noteId ? null : noteId);
  };

  const renderFeedbackScore = (note) => {
    if (note.note_type === 'Interview') {
      return (
        <div className="flex">
          {[1, 2, 3, 4, 5].map((star) => (
            <Star
              key={star}
              size={16}
              className={star <= note.feedback_score ? 'text-yellow-400' : 'text-gray-300'}
              fill={star <= note.feedback_score ? 'currentColor' : 'none'}
            />
          ))}
        </div>
      );
    }
    return null;
  };

  const renderNotesTable = () => (
<div className="bg-white text-left shadow-md rounded-lg overflow-x-auto relative isolate">
<table className="min-w-full table-auto">
        <thead className="bg-gray-100">
          <tr>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('notewriter.name')} className="cursor-pointer">
                Notewriter {sortConfig.key === 'notewriter.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('created_at')} className="cursor-pointer">
                Date {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
              <div onClick={() => handleSort('note_type')} className="cursor-pointer">
                Type {sortConfig.key === 'note_type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
              </div>
            </th>
            <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">Rating</th>
            <th className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody>
          {filteredNotes.map((note) => (
            <tr
            key={note.id}
            className="hover:bg-gray-50 cursor-pointer"
            onClick={() => handleSeeNote(note)}
          >
              <td className="px-6 py-4 text-sm text-gray-700">{note.notewriter?.name ?? 'AI Summary'}</td>
              <td className="px-6 py-4 text-sm text-gray-700">{new Date(note.created_at).toLocaleDateString()}</td>
              <td className="px-6 py-4 text-sm text-gray-700">{note.interview?.ai_summary ? 'AI Summary' : note.note_type}
              </td>
              <td className="px-6 py-4">
                {renderFeedbackScore(note)}
              </td>
              <td className="px-6 py-4 text-right">
                <MoreHorizontal
                  size={24}
                  className="cursor-pointer z-10"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents the row's onClick from firing
                    toggleDropdown(note.id);
                  }}
                />
                {activeDropdown === note.id && (
                  <div ref={dropdownRef} className="absolute right-0 text-left mt-2 w-48 bg-white rounded-md shadow-lg z-20">
                    {user && user.user_id === note.notewriter_id && (
                      <>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleEditNote(note)}
                        >
                          Edit Note
                        </div>
                        <div
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleDeleteNote(note.id)}
                        >
                          Delete Note
                        </div>
                      </>
                    )}
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <NoteModal note={selectedNote} onClose={() => setSelectedNote(null)} />
    </div>
  );

  const renderNotesCards = () => (
    <div className="grid grid-cols-1 gap-4">
      {filteredNotes.map((note) => (
        <div 
          key={note.id} 
          className="bg-white text-left shadow-md rounded-lg p-4 relative hover:bg-gray-50 cursor-pointer"
          onClick={() => handleSeeNote(note)}
        >
          <div className="flex justify-between items-start mb-2">
            <div>
              <p className="font-semibold">{note.notewriter?.name ?? 'AI Summary'}</p>
              <p className="text-sm text-gray-500">{new Date(note.created_at).toLocaleDateString()}</p>
              <p className="text-sm text-gray-500">Type: {note.note_type}</p>
            </div>
            <div className="flex items-center">
              <MoreHorizontal
                size={24}
                className="cursor-pointer"
                onClick={() => toggleDropdown(note.id)}
              />
              {activeDropdown === note.id && (
                <div ref={dropdownRef} className="absolute right-0 mt-8 w-48 bg-white rounded-md shadow-lg z-10">
                  {user && user.user_id === note.notewriter_id && (
                    <>
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleEditNote(note)}
                      >
                        Edit Note
                      </div>
                      <div
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                        onClick={() => handleDeleteNote(note.id)}
                      >
                        Delete Note
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="mt-2">
            {renderFeedbackScore(note)}
          </div>
        </div>
      ))}
      <NoteModal note={selectedNote} onClose={() => setSelectedNote(null)} />
    </div>
  );

  if (!user) {
    return <div>Loading user...</div>;
  }

  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Helmet>
        <title>Interview Notes | fetchConsultant</title>
        <meta name="description" content="View and manage interview notes and AI-generated summaries for candidates on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="notes" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Interview Notes</h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={handleCreateNote}
                className="bg-indigo-600 text-white px-4 py-2 rounded mr-4"
              >
                Create Note
              </button>
              <NotificationBell />
            </div>
          </header>
          
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="bg-white shadow rounded-lg p-6">
              <div className="mb-6">
                <div className="flex items-center mb-4">
                  <div className="relative flex-grow mr-4">
                    <Search size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <input
                      type="text"
                      placeholder="Search notes..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="pl-10 pr-12 py-2 w-full border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  <div className="relative">
                    <Filter size={20} className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                    <select
                      value={selectedConsultant}
                      onChange={(e) => {
                        setSelectedConsultant(e.target.value);
                        if (e.target.value === '') {
                          setApplicantId(null);
                        }
                      }}
                      className="pl-10 pr-12 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
                    >
                      <option value="">All Consultants</option>
                      {consultants.map((consultant) => (
                        <option key={consultant.user_id} value={consultant.user_id}>
                          {consultant.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex space-x-4 mb-4 overflow-x-auto">
                  {['All Notes', 'Interview', 'AI Summaries', 'General', 'Comment'].map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={`px-3 py-2 rounded-md text-sm font-medium whitespace-nowrap ${
                        activeTab === tab
                          ? 'bg-indigo-100 text-indigo-700'
                          : 'text-gray-500 hover:text-gray-700'
                      }`}
                    >
                      {tab}
                    </button>
                  ))}
                </div>
              </div>

              {isMobile ? renderNotesCards() : renderNotesTable()}

              {Object.keys(aiSummaries).length > 0 && (
                <div className="mt-8">
                  <h3 className="text-lg font-semibold mb-4">AI-Generated Summaries</h3>
                  {Object.entries(aiSummaries).map(([interviewId, summary]) => (
                    <div key={interviewId} className="bg-gray-50 p-4 rounded-lg mb-4">
                      <h4 className="font-semibold mb-2">Interview Summary</h4>
                      <p>{summary}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientInterviewNotes;