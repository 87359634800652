import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, Plus, X } from 'lucide-react';
import { ArrowLongLeftIcon, HomeIcon } from '@heroicons/react/20/solid';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";


const ClientProposeAlternativeTimes = () => {
  const [interviewSlots, setInterviewSlots] = useState(['']);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { userJobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Propose Alternative Times',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchInterviewDetails();
  }, [userJobId]);

  const fetchInterviewDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs(title),
          consultant:users!user_jobs_user_id_fkey(name)
        `)
        .eq('user_job_id', userJobId)
        .single();

      if (error) throw error;
      setInterviewDetails(data);
    } catch (error) {
      console.error('Error fetching interview details:', error.message);
    }
  };

  const handleAddSlot = () => {
    if (interviewSlots.length < 5) {
      setInterviewSlots([...interviewSlots, '']);
    }
  };

  const handleRemoveSlot = (index) => {
    const newSlots = interviewSlots.filter((_, i) => i !== index);
    setInterviewSlots(newSlots);
  };

  const handleSlotChange = (index, value) => {
    const newSlots = [...interviewSlots];
    newSlots[index] = value;
    setInterviewSlots(newSlots);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({
          status: 'Interview Requested',
          client_slots: interviewSlots,
        })
        .eq('user_job_id', userJobId);

      if (error) throw error;

      const analytics = getAnalytics();
      logEvent(analytics, 'interview_requested', {
        user_job_id: userJobId,
      });

      alert('Interview request sent successfully!');
      navigate('/app/client-interviews');
    } catch (error) {
      console.error('Error requesting interview:', error.message);
      alert('Failed to request interview. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Propose Alternative Times | fetchConsultant</title>
        <meta name="description" content="Request an interview with a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
      <ClientHamburger isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      
      <div className="flex-1 md:ml-64 flex flex-col">
        <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>

            {/* Use isMenuOpen to conditionally render breadcrumb or back arrow */}
            {isMenuOpen ? (
              <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
                <ArrowLongLeftIcon className="h-6 w-6 text-gray-500" />
                <span className="text-gray-500">Back to Applicants</span>
              </div>
            ) : (
              <nav className="flex items-center text-sm space-x-2">
                <HomeIcon className="h-4 w-4 text-gray-500 cursor-pointer" onClick={() => navigate('/app/client-dashboard')} aria-hidden="true" />
                <span className="text-gray-400">/</span>
                <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-3)}>Applicants</span>
                <span className="text-gray-400">/</span>
                <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-2)}>Applicant Details</span>
                <span className="text-gray-400">/</span>
                <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-1)}>Schedule Interview</span>
                <span className="text-gray-400">/</span>
                <span className="text-gray-500">Propose Alternative Times</span>
              </nav>
            )}
          </div>

          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>
        <main className="fetch-grow container mx-auto px-4">
            <div className="fetch-card">
                {interviewDetails && (
                <div className="text-left">
                    <h2 className="text-2xl text-center font-bold mb-2">{interviewDetails.job.title}</h2>
                    <p className="mb-6 text-center">{interviewDetails.consultant.name}</p>
                    <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="fetch-label mb-4">Please propose a few times you are available to interview the consultant. All slots are for one hour.<span className="fetch-required">*</span></label>
                        {interviewSlots.map((slot, index) => (
                            <div key={index} className="mb-4 flex flex-col items-start w-full">
                            <label htmlFor={`slot-${index}`} className="fetch-label mb-2">Slot {index + 1}</label>
                            <div className="w-full flex items-center">
                                <div className="w-full">
                                <DatePicker
                                id={`slot-${index}`}
                                selected={slot ? new Date(slot) : null}
                                onChange={(date) => handleSlotChange(index, date)}
                                showTimeSelect
                                timeIntervals={30}
                                timeFormat="h:mm aa"
                                timeCaption="Time"
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="fetch-input w-full date-picker-custom"
                                placeholderText="Select date and time"
                                popperClassName="custom-popper date-picker-popper"
                                calendarClassName="custom-calendar"
                                dayClassName={() => "custom-day"}
                                timeClassName={() => "custom-time"}
                                wrapperClassName="date-picker-wrapper"
                                />
                                </div>
                                {index > 0 && (
                                <button
                                    type="button"
                                    onClick={() => handleRemoveSlot(index)}
                                    className="text-red-600 ml-2"
                                    aria-label="Remove slot"
                                >
                                    <X size={20} />
                                </button>
                                )}
                            </div>
                            </div>
                        ))}
                        {interviewSlots.length < 5 && (
                        <button
                            type="button"
                            onClick={handleAddSlot}
                            className="flex items-center text-brandPrimary mt-2"
                        >
                            <Plus size={20} className="mr-1" />
                            Add Another Slot
                        </button>
                        )}
                        </div>
                    <button type="submit" className="w-full bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300">
                        Send Request
                    </button>
                    </form>
                </div>
                )}
            </div>
          </main>
          </div>
      </div>
    </>
  );
};

export default ClientProposeAlternativeTimes;