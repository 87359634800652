import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, MoreHorizontal, Download, Mail, DollarSign, Clock } from 'lucide-react';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import SystemAdminHamburger from './SystemAdminHamburger';
import NotificationBell from "./NotificationBell";
import DateRangePicker from './ui/DateRangePicker';
import Modal from './ui/Modal';

const SystemAdminInvoices = () => {
  const [invoices, setInvoices] = useState([]);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isPaidModalOpen, setIsPaidModalOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [paidAmount, setPaidAmount] = useState('');
  const [paidDate, setPaidDate] = useState('');
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'sent_date', direction: 'desc' });
  const [statusFilter, setStatusFilter] = useState('All');
  const [consultantFilter, setConsultantFilter] = useState('All');
  const [ownerFilter, setOwnerFilter] = useState('All');
  const [clientFilter, setClientFilter] = useState('All');
  const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });
  const [searchTerm, setSearchTerm] = useState('');
  const [consultants, setConsultants] = useState([]);
  const [owners, setOwners] = useState([]);
  const [clients, setClients] = useState([]);
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Invoices',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    fetchData();
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, [statusFilter, sortConfig, consultantFilter, ownerFilter, clientFilter, dateRange, searchTerm]);

  const fetchData = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      const userResponse = await fetch(`${supabaseUrl}/functions/v1/user-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'fetchUser',
          userId: authUser.id
        })
      });

      if (!userResponse.ok) throw new Error('Failed to fetch user data');
      const userData = await userResponse.json();
      setUser(userData.data);

      const invoicesResponse = await fetch(`${supabaseUrl}/functions/v1/invoice-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'fetchAllInvoices',
          userId: authUser.id, 
          filters: {
            status: statusFilter,
            consultant: consultantFilter,
            owner: ownerFilter,
            client: clientFilter,
            dateRange,
            searchTerm,
            sortKey: sortConfig.key,
            sortDirection: sortConfig.direction
          }
        })
      });

      if (!invoicesResponse.ok) throw new Error('Failed to fetch invoices data');
      const invoicesData = await invoicesResponse.json();
      setInvoices(invoicesData.data);

      // Set filter options
      const uniqueConsultants = [...new Set(invoicesData.data.map(invoice => invoice.consultant_name))];
      const uniqueOwners = [...new Set(invoicesData.data.map(invoice => invoice.approved_by_name))];
      const uniqueClients = [...new Set(invoicesData.data.map(invoice => invoice.client_name))];
      setConsultants(uniqueConsultants);
      setOwners(uniqueOwners);
      setClients(uniqueClients);

    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  };

  const handleMarkAsPaid = async (invoice) => {
    setSelectedInvoice(invoice);
    setPaidAmount(invoice.amount.toString());
    setPaidDate(new Date().toISOString().split('T')[0]);
    setIsPaidModalOpen(true);
  };

  const handleSubmitPaid = async () => {
    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/invoice-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({
          action: 'markInvoicePaid',
          invoiceId: selectedInvoice.id,
          data: {
            paid_amount: parseFloat(paidAmount),
            paid_date: paidDate
          }
        })
      });

      if (!response.ok) throw new Error('Failed to mark invoice as paid');

      const analytics = getAnalytics();
      logEvent(analytics, 'invoice_marked_paid', {
        invoice_id: selectedInvoice.id,
        amount: paidAmount
      });

      setIsPaidModalOpen(false);
      fetchData();

    } catch (error) {
      console.error('Error marking invoice as paid:', error);
    }
  };

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const toggleDropdown = (invoiceId) => {
    setActiveDropdown(activeDropdown === invoiceId ? null : invoiceId);
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleDownloadInvoice = async (e, invoiceId) => {
    e.stopPropagation();
    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/generate-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({ invoiceId })
      });

      if (!response.ok) throw new Error('Failed to generate invoice');
      
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoice-${invoiceId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Error downloading invoice:', error);
    }
  };

  const handleResendEmail = async (e, invoiceId) => {
    e.stopPropagation();
    try {
      const response = await fetch(`${supabaseUrl}/functions/v1/send-invoice`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
        },
        body: JSON.stringify({ invoiceId })
      });

      if (!response.ok) throw new Error('Failed to resend invoice');
      // Show success message
    } catch (error) {
      console.error('Error resending invoice:', error);
    }
  };

  return (
    <>
      <Helmet>
        <title>System Admin Invoices | fetchConsultant</title>
        <meta name="description" content="Manage and track all invoices across the fetchConsultant platform." />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <SystemAdminHamburger user={user} currentPage="invoices" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">System Admin Invoices</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            <div className="mb-4 flex flex-wrap gap-4 items-center justify-between">
              <div className="flex flex-wrap gap-4 items-center">
                {/* Include all filters from ClientInvoices.js plus client filter */}
                <select
                  value={clientFilter}
                  onChange={(e) => setClientFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Clients</option>
                  {clients.map(client => (
                    <option key={client} value={client}>{client}</option>
                  ))}
                </select>
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Statuses</option>
                  <option value="Draft">Draft</option>
                  <option value="Sent">Sent</option>
                  <option value="Paid">Paid</option>
                </select>

                <select
                  value={consultantFilter}
                  onChange={(e) => setConsultantFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Consultants</option>
                  {consultants.map(consultant => (
                    <option key={consultant} value={consultant}>{consultant}</option>
                  ))}
                </select>

                <select
                  value={ownerFilter}
                  onChange={(e) => setOwnerFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="All">All Owners</option>
                  {owners.map(owner => (
                    <option key={owner} value={owner}>{owner}</option>
                  ))}
                </select>

                <DateRangePicker
                  startDate={dateRange.startDate}
                  endDate={dateRange.endDate}
                  onChange={setDateRange}
                />

                <input
                  type="text"
                  placeholder="Search invoices..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="fetch-input"
                />
              </div>

              <div className="flex items-center space-x-4">
                <div className="text-sm font-medium">
                  Total Outstanding: ${invoices
                    .filter(inv => inv.status !== 'Paid')
                    .reduce((sum, inv) => sum + inv.amount, 0)
                    .toFixed(2)}
                </div>
                <div className="text-sm font-medium">
                  Total Paid: ${invoices
                    .filter(inv => inv.status === 'Paid')
                    .reduce((sum, inv) => sum + inv.amount, 0)
                    .toFixed(2)}
                </div>
              </div>
            </div>

            {invoices.length === 0 ? (
              <p className="text-gray-600 text-center">No invoices found.</p>
            ) : isMobile ? (
              <div className="grid grid-cols-1 gap-4">
                {invoices.map((invoice) => (
                  <div 
                    key={invoice.id} 
                    className="bg-white shadow-md rounded-lg p-4 relative"
                    onClick={() => navigate(`/app/invoices/${invoice.id}`)}
                  >
                    <div className="flex justify-between items-start mb-2">
                      <div>
                        <h3 className="font-medium">{invoice.invoice_number}</h3>
                        <p className="text-sm text-gray-500">{new Date(invoice.sent_date).toLocaleDateString()}</p>
                      </div>
                      <span className={`px-2 py-1 rounded-full text-sm ${
                        invoice.status === 'Paid' ? 'bg-green-100 text-green-800' :
                        invoice.status === 'Sent' ? 'bg-blue-100 text-blue-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {invoice.status}
                      </span>
                    </div>
                    <div className="text-sm">
                      <p>Consultant: {invoice.consultant_name}</p>
                      <p>Amount: ${invoice.amount.toFixed(2)}</p>
                    </div>
                    <div className="absolute top-4 right-4">
                      <MoreHorizontal 
                        size={20} 
                        className="cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleDropdown(invoice.id);
                        }}
                      />
                      {activeDropdown === invoice.id && (
                        <div
                          className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 overflow-visible"
                          ref={dropdownRef}
                          onClick={(e) => e.stopPropagation()}
                        >                          
                          <div 
                            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                            onClick={(e) => handleDownloadInvoice(e, invoice.id)}
                          >
                            <Download size={16} className="mr-2" />
                            Download PDF
                          </div>
                          {invoice.status === 'Sent' && (
                            <div 
                              className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                              onClick={(e) => handleResendEmail(e, invoice.id)}
                            >
                              <Mail size={16} className="mr-2" />
                              Resend Email
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full">
                  <thead className="bg-gray-50">
                    <tr>
                      <th onClick={() => handleSort('invoice_number')} className="cursor-pointer">
                        Invoice Number {sortConfig.key === 'invoice_number' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('sent_date')} className="cursor-pointer">
                        Date {sortConfig.key === 'sent_date' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('due_date')} className="cursor-pointer">
                        Due Date {sortConfig.key === 'due_date' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('consultant_name')} className="cursor-pointer">
                        Consultant {sortConfig.key === 'consultant_name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('approved_by_name')} className="cursor-pointer">
                        Owner {sortConfig.key === 'approved_by_name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('status')} className="cursor-pointer">
                        Status {sortConfig.key === 'status' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('purchase_order')} className="cursor-pointer">
                        PO Number {sortConfig.key === 'purchase_order' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('total_hours')} className="cursor-pointer text-right">
                        Hours {sortConfig.key === 'total_hours' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th onClick={() => handleSort('amount')} className="cursor-pointer text-right">
                        Amount {sortConfig.key === 'amount' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.map((invoice) => (
                      <tr 
                        key={invoice.id}
                        className="hover:bg-gray-50 cursor-pointer"
                        onClick={() => navigate(`/app/invoices/${invoice.id}`)}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm font-medium text-gray-900">{invoice.invoice_number}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {new Date(invoice.sent_date).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-500">
                            {new Date(invoice.due_date).toLocaleDateString()}
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{invoice.consultant_name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{invoice.approved_by_name}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                            invoice.status === 'Paid' ? 'bg-green-100 text-green-800' :
                            invoice.status === 'Sent' ? 'bg-blue-100 text-blue-800' :
                            'bg-gray-100 text-gray-800'
                          }`}>
                            {invoice.status}
                          </span>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          <div className="text-sm text-gray-900">{invoice.purchase_order}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <div className="text-sm text-gray-900">{invoice.total_hours.toFixed(2)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right">
                          <div className="text-sm text-gray-900">${invoice.amount.toFixed(2)}</div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium relative">
                          <MoreHorizontal 
                            size={20} 
                            className="ml-auto cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleDropdown(invoice.id);
                            }}
                          />
                          {activeDropdown === invoice.id && (
                            <div
                              className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-50 overflow-visible"
                              ref={dropdownRef}
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div 
                                className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                onClick={(e) => handleDownloadInvoice(e, invoice.id)}
                              >
                                <Download size={16} className="mr-2" />
                                Download PDF
                              </div>
                              {invoice.status === 'Sent' && (
                                <div 
                                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center cursor-pointer"
                                  onClick={(e) => handleResendEmail(e, invoice.id)}
                                >
                                  <Mail size={16} className="mr-2" />
                                  Resend Email
                                </div>
                              )}
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </main>
        </div>
      </div>

      <Modal
        isOpen={isPaidModalOpen}
        onClose={() => setIsPaidModalOpen(false)}
        title="Mark Invoice as Paid"
      >
        <div className="p-6">
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Amount Paid</label>
            <input
              type="number"
              value={paidAmount}
              onChange={(e) => setPaidAmount(e.target.value)}
              className="fetch-input"
              step="0.01"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Payment Date</label>
            <input
              type="date"
              value={paidDate}
              onChange={(e) => setPaidDate(e.target.value)}
              className="fetch-input"
            />
          </div>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsPaidModalOpen(false)}
              className="px-4 py-2 border border-gray-300 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={handleSubmitPaid}
              className="px-4 py-2 bg-green-600 text-white rounded-md"
            >
              Mark as Paid
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SystemAdminInvoices;