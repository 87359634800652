import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient.js';

const AuthCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        // 1. Get current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;

        if (!session?.user) {
          navigate('/app/auth');
          return;
        }

        console.log('Processing auth callback for user:', session.user.id);

        // 2. Retrieve tokens from URL or localStorage
        const tokens = getStoredTokens();
        console.log('Retrieved tokens:', tokens);

        // 3. Ensure user has a record in the DB
        const userRecord = await ensureUserRecord(session.user);
        console.log('User record:', userRecord);

        // 4. Process referral token if present
        if (tokens.referralToken) {
          await processReferral(tokens.referralToken, session.user);
        }

        // Process review token if present
        if (tokens.reviewToken) {
          await processReview(tokens.reviewToken, session.user);
        }

        // 5. Clear stored tokens
        clearStoredTokens();

        // 6. Determine redirect path
        const redirectPath = await determineRedirectPath(userRecord, tokens);
        navigate(redirectPath);

      } catch (error) {
        console.error('Error in auth callback:', error);
        navigate('/app/auth');
      }
    };

    handleAuthCallback();
  }, [navigate, location]);

  return <div>Finalizing your account setup...</div>;
};

// ------------------- Helper Functions -------------------

// Retrieve any referral/review tokens from URL or localStorage
function getStoredTokens() {
  try {
    const params = new URLSearchParams(window.location.search);
    const urlReferral = params.get('referral');
    const urlReview = params.get('token');

    const storedReferral = localStorage.getItem('pending_referral_token');
    const storedReview = localStorage.getItem('pending_review_token');
    const storedJobId = localStorage.getItem('pending_job_id');

    return {
      referralToken: urlReferral || storedReferral || undefined,
      reviewToken: urlReview || storedReview || undefined,
      jobId: storedJobId || undefined
    };
  } catch (error) {
    console.error('Error getting stored tokens:', error);
    return {};
  }
}

// Remove stored tokens from localStorage
function clearStoredTokens() {
  try {
    localStorage.removeItem('pending_referral_token');
    localStorage.removeItem('pending_review_token');
    localStorage.removeItem('pending_job_id');
  } catch (error) {
    console.error('Error clearing stored tokens:', error);
  }
}

// Ensure a user record exists in the 'users' table; if not, create it
async function ensureUserRecord(authUser) {
  try {
    // Try to fetch existing record
    const { data: existingUser, error: fetchError } = await supabase
      .from('users')
      .select('*')
      .eq('user_id', authUser.id)
      .single();

    // If there's another error that isn't 'no row found'
    if (fetchError && fetchError.code !== 'PGRST116') {
      throw fetchError;
    }

    if (existingUser) {
      return existingUser;
    }

    // Create new record if none found
    const { data: newUser, error: createError } = await supabase
      .from('users')
      .insert([
        {
          user_id: authUser.id,
          login_email: authUser.email,
          user_type_id: 1, // Default new user type
          created_at: new Date().toISOString()
        }
      ])
      .select()
      .single();

    if (createError) throw createError;
    return newUser;
  } catch (error) {
    console.error('Error ensuring user record:', error);
    throw error;
  }
}

// Process referral token by invoking a Supabase function
async function processReferral(referralToken, authUser) {
  try {
    console.log('Processing referral token:', referralToken);

    const { data: referralData, error: referralError } = await supabase.functions.invoke(
      'referral-bonus-operations', 
      {
        body: {
          action: 'handleReferralCompletion',
          data: {
            referralToken,
            userId: authUser.id,
            userEmail: authUser.email
          }
        }
      }
    );

    if (referralError) throw referralError;

    // If a jobId is returned, store it in sessionStorage
    if (referralData?.jobId) {
      try {
        sessionStorage.setItem('referredJobId', referralData.jobId);
      } catch (err) {
        console.error('Error storing job ID:', err);
      }
    }

    return referralData;
  } catch (error) {
    console.error('Error processing referral:', error);
    throw error;
  }
}

// Process review token to associate a reviewer with a pending review request
async function processReview(reviewToken, authUser) {
  try {
    console.log('Processing review token:', reviewToken);

    // Verify review token is valid and pending
    const { data: reviewTokenData, error: tokenError } = await supabase
      .from('review_tokens')
      .select('*')
      .eq('token', reviewToken)
      .eq('status', 'pending')
      .single();

    if (tokenError) throw tokenError;

    // Update review token status and associate it with the user
    const { error: updateError } = await supabase
      .from('review_tokens')
      .update({
        status: 'accepted',
        reviewer_id: authUser.id,
        updated_at: new Date().toISOString()
      })
      .eq('token', reviewToken);

    if (updateError) throw updateError;

    // Send notification to the consultant who requested the review
    await supabase.rpc('insert_notification', {
      p_user_id: reviewTokenData.consultant_id,
      p_notif_type: 'review_accepted',
      p_content: `${authUser.email} has accepted your review request`,
      p_reviewed_user_job: reviewTokenData.reviewed_user_job
    });

    return reviewTokenData;
  } catch (error) {
    console.error('Error processing review:', error);
    throw error;
  }
}

// Determine where the user should be redirected based on their user type
async function determineRedirectPath(userRecord) {
  try {
    // Redirect based solely on user type
    switch (userRecord.user_type_id) {
      case 1:
        return '/app/client-or-consultant';
      case 2:
        return '/app/consultant-dashboard';
      case 3:
        return '/app/client-onboarding-personal';
      case 4:
        return '/app/client-dashboard';
      case 5:
        return '/app/system-admin-dashboard';
      default:
        return '/app/client-or-consultant';
    }
  } catch (error) {
    console.error('Error determining redirect path:', error);
    return '/app/client-or-consultant';
  }
}

export default AuthCallback;