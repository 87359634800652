import React, { useEffect, useState } from 'react';
import { checkNotificationPermission, requestNotificationPermission } from '../firebase/fcm';
import { supabase } from '../supabaseClient';

const NotificationPermissionWarning = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [browser, setBrowser] = useState('');
  const [isStandalone, setIsStandalone] = useState(false);

  useEffect(() => {
    detectPlatform();
    checkPermissions();
  }, []);

  const detectPlatform = () => {
    const userAgent = navigator.userAgent;
    
    // Detect iOS
    setIsIOS(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);

    // Detect mobile device
    const isMobileDevice = /Mobi|Android/i.test(userAgent);
    setIsMobile(isMobileDevice);

    // Detect if the app is already installed (standalone)
    const standaloneMode = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches;
    setIsStandalone(standaloneMode);

    // Detect browser type
    if (userAgent.includes("Chrome")) {
      setBrowser("Chrome");
    } else if (userAgent.includes("Firefox")) {
      setBrowser("Firefox");
    } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
      setBrowser("Safari");
    } else {
      setBrowser("Other");
    }
  };

  const checkPermissions = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: settings } = await supabase
        .from('notification_settings')
        .select('*')
        .eq('user_id', user.id);

      const pushEnabled = settings.some(setting => setting.notification_options.push);
      const permissionGranted = checkNotificationPermission();

      setShowWarning(pushEnabled && !permissionGranted);
    }
  };

  const handleEnableNotifications = async () => {
    const granted = await requestNotificationPermission();
    if (granted) {
      setShowWarning(false);
    }
  };
    
    if (!showWarning) return null;

    return (
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 text-left" role="alert">
        <p className="font-bold">App Setup Instructions</p>
        {isMobile && !isStandalone ? (
          <div>
            <p>To have the best experience, add this app to your home screen for easy access:</p>
            {isIOS ? (
              <p>1. Tap the share icon at the bottom of the screen. <br /> 2. Select "Add to Home Screen".</p>
            ) : (
              <p>1. Tap the menu button (three dots) at the top-right. <br /> 2. Select "Add to Home screen".</p>
            )}
            <p>Also, please enable notifications to stay updated on important information.</p>
            <button
              onClick={handleEnableNotifications}
              className="mt-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
            >
              Enable Notifications
            </button>
          </div>
        ) : (
          <div>
            <p>To enable notifications in your browser:</p>
            {browser === 'Chrome' && (
              <p>
                1. Click the lock icon next to the URL. <br />
                2. Set "Notifications" to "Allow".
                * To turn push notifications off, go to Settings and toggle Push off.
              </p>
            )}
            {browser === 'Firefox' && (
              <p>
                1. Click the shield icon next to the URL. <br />
                2. Click "Options" and allow notifications.
                * To turn push notifications off, go to Settings and toggle Push off.
              </p>
            )}
            {browser === 'Safari' && (
              <p>
                1. Open Safari Preferences. <br />
                2. Go to the "Websites" tab and select "Notifications". <br />
                3. Allow notifications for this site.
                * To turn push notifications off, go to Settings and toggle Push off.
              </p>
            )}
            {browser === 'Other' && (
              <p>
                Refer to your browser's settings to enable notifications. <br />
                * To turn push notifications off, go to Settings and toggle Push off.
              </p>
            )}
            <button
              onClick={handleEnableNotifications}
              className="mt-2 bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded"
            >
              Enable Notifications
            </button>
          </div>
        )}
      </div>
    );
  };
  
  export default NotificationPermissionWarning;