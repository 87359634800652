import React from 'react';

const Terms = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm fixed w-full z-10">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <div className="text-2xl font-bold text-indigo-600">fetchConsultant</div>
          </div>
        </nav>
      </header>

      <main className="pt-16">
        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Terms of Service</h2>
            <div className="bg-white p-6 rounded-lg shadow-md text-left">
              <h3 className="text-xl font-semibold mb-4">1. Acceptance of Terms</h3>
              <p className="text-gray-600 mb-4">
                By accessing or using the fetchConsultant platform (“Platform”), you acknowledge that you have read, understood, and agree to be bound by these Terms of Service (“Terms”). If you do not agree to these Terms, you must immediately stop using the Platform.
              </p>

              <h3 className="text-xl font-semibold mb-4">2. Description of Services</h3>
              <p className="text-gray-600 mb-4">
                fetchConsultant provides a digital platform that enables clients to connect with consultants for specific project contracts. The Platform serves as a marketplace where clients can post opportunities and consultants can apply for suitable engagements.
              </p>

              <h3 className="text-xl font-semibold mb-4">3. User Accounts</h3>
              <p className="text-gray-600 mb-4">
                <strong>Registration:</strong> To use the Platform, users must register by providing accurate and up-to-date information. Failure to provide correct information may result in suspension or termination of your account.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Account Security:</strong> You are responsible for safeguarding your account credentials and any activity under your account. If you suspect any unauthorized use of your account, you must notify fetchConsultant immediately.
              </p>

              <h3 className="text-xl font-semibold mb-4">4. Use of the Platform</h3>
              <p className="text-gray-600 mb-4">
                <strong>Clients:</strong> Clients may post project opportunities and review applications from consultants. You agree to use the Platform exclusively for legitimate purposes, such as project postings, job applications, or hiring.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Consultants:</strong> Consultants may browse and apply for opportunities that align with their expertise. Consultants are solely responsible for the accuracy and completeness of their profiles and application submissions.
              </p>

              <h3 className="text-xl font-semibold mb-4">5. Prohibited Actions</h3>
              <p className="text-gray-600 mb-4">
                <strong>Software Abuse:</strong> Users are prohibited from reverse-engineering, duplicating, modifying, or otherwise tampering with any portion of the Platform's code or software.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Off-Platform Engagement:</strong> Users may not engage with one another or enter into contracts for opportunities discovered on the Platform outside of the Platform’s processes. Doing so may lead to termination of your account, and fetchConsultant reserves the right to seek damages for such violations.
              </p>

              <h3 className="text-xl font-semibold mb-4">6. Privacy Policy</h3>
              <p className="text-gray-600 mb-4">
                <strong>Personal Information:</strong> Your email and phone number will never be shared, sold, or used for purposes beyond those outlined in the Platform, without your explicit permission.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Profile Confidentiality:</strong> Consultant profiles are kept private and only shared with clients when the consultant applies for a specific opportunity.
              </p>

              <h3 className="text-xl font-semibold mb-4">7. Consent to Notifications</h3>
              <p className="text-gray-600 mb-4">
                Users agree to receive notifictions from fetchConsultant per their notificaiton settings which may include, push, text and email notifications.
              </p>

              <h3 className="text-xl font-semibold mb-4">8. Intellectual Property Rights</h3>
              <p className="text-gray-600 mb-4">
                All content and materials available on the Platform, including but not limited to logos, designs, text, graphics, and code, are owned by fetchConsultant or its licensors and are protected by intellectual property laws. Unauthorized use is strictly prohibited.
              </p>

              <h3 className="text-xl font-semibold mb-4">9. Confidentiality</h3>
              <p className="text-gray-600 mb-4">
                Users agree to maintain the confidentiality of any proprietary or sensitive information obtained while using the Platform. This obligation survives the termination of your account.
              </p>

              <h3 className="text-xl font-semibold mb-4">10. Limitation of Liability</h3>
              <p className="text-gray-600 mb-4">
                fetchConsultant is not liable for any indirect, incidental, special, or consequential damages arising out of the use or inability to use the Platform. Users agree that the use of the Platform is at their own risk.
              </p>

              <h3 className="text-xl font-semibold mb-4">11. Indemnification</h3>
              <p className="text-gray-600 mb-4">
                Users agree to indemnify and hold harmless fetchConsultant, its affiliates, and employees from any claims, damages, or liabilities arising out of their use of the Platform, breach of these Terms, or violation of any laws or rights of a third party.
              </p>

              <h3 className="text-xl font-semibold mb-4">12. Termination</h3>
              <p className="text-gray-600 mb-4">
                fetchConsultant reserves the right to suspend or terminate user accounts at its sole discretion for violations of these Terms or for any conduct that is deemed harmful to the Platform or its users.
              </p>

              <h3 className="text-xl font-semibold mb-4">13. Governing Law</h3>
              <p className="text-gray-600 mb-4">
                These Terms are governed by and construed in accordance with the laws of the state of Texas, without regard to its conflict of law provisions.
              </p>

              <h3 className="text-xl font-semibold mb-4">14. Changes to Terms</h3>
              <p className="text-gray-600 mb-4">
                fetchConsultant reserves the right to modify these Terms at any time. Changes will be posted on the Platform, and continued use of the Platform after such changes constitute acceptance of the new Terms.
              </p>

              <h3 className="text-xl font-semibold mb-4">15. Contact Information</h3>
              <p className="text-gray-600 mb-4">
                If you have any questions regarding these Terms, please contact us at <a href="mailto:info@fetchconsultant.com" className="text-indigo-600">info@fetchconsultant.com</a>.
              </p>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Terms;