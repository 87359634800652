import { getAnalytics, logEvent } from "firebase/analytics";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const UnsubmittedTimesheetsPage = () => {
  const [unsubmittedTimesheets, setUnsubmittedTimesheets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Helper Functions
  function formatDate(date) {
    return date.getFullYear() + '-' +
           String(date.getMonth() + 1).padStart(2, '0') + '-' +
           String(date.getDate()).padStart(2, '0');
  }

  function formatDateForUrl(date) {
    return (
      String(date.getMonth() + 1).padStart(2, '0') + '-' + // Month
      String(date.getDate()).padStart(2, '0') + '-' + // Day
      String(date.getFullYear()).slice(-2) // Two-digit year
    );
  }

  function parseDate(dateStr) {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  function getWeekStart(date) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0); // Set to midnight
    d.setDate(d.getDate() - d.getDay()); // Adjust to Sunday
    return d;
  }

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Unsubmitted Timesheets',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchUnsubmittedTimesheets();
    }
  }, [user]);

  const fetchUser = async () => {
    try {
      const { data: { user: authUser }, error } = await supabase.auth.getUser();
      if (error) throw error;
      
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', authUser.id)
        .single();
        
      if (userError) throw userError;
      setUser(userData);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to fetch user data');
    }
  };

  const fetchUnsubmittedTimesheets = async () => {
    try {
      setIsLoading(true);
      const { data, error } = await supabase
        .from('timesheet_entries')
        .select(`
          *,
          timesheet_tasks (
            is_billable
          ),
          engagements (
            id,
            contract_rate,
            job:job_id (
              title,
              client:client_id (
                name
              )
            )
          )
        `)
        .eq('consultant_id', user.user_id)
        .eq('status', 'saved')
        .order('date', { ascending: false });

      if (error) throw error;

      const timesheetsByWeek = processTimesheetData(data);
      setUnsubmittedTimesheets(timesheetsByWeek);
    } catch (error) {
      console.error('Error fetching unsubmitted timesheets:', error);
      setError('Failed to load unsubmitted timesheets');
    } finally {
      setIsLoading(false);
    }
  };

  const processTimesheetData = (data) => {
    const timesheetsByWeek = {};
    data.forEach((entry) => {
      const entryDate = parseDate(entry.date);
      const weekStartDateObj = getWeekStart(entryDate);
      const weekStartDate = formatDate(weekStartDateObj);

      if (!timesheetsByWeek[weekStartDate]) {
        timesheetsByWeek[weekStartDate] = {
          weekStartDate,
          entries: [],
          project: entry.engagements?.job?.title || 'Unknown Project',
          client: entry.engagements?.job?.client?.name || 'Unknown Client',
          contractRate: entry.engagements?.contract_rate || 0,
          totalAmount: 0,
          totalHours: 0
        };
      }
      timesheetsByWeek[weekStartDate].entries.push(entry);
      timesheetsByWeek[weekStartDate].totalHours += parseFloat(entry.hours) || 0;
      timesheetsByWeek[weekStartDate].totalAmount += 
        (parseFloat(entry.hours) || 0) * (entry.engagements?.contract_rate || 0);
    });
    
    // Convert the object to an array and sort by weekStartDate in descending order
    return Object.values(timesheetsByWeek).sort((a, b) => 
      new Date(b.weekStartDate) - new Date(a.weekStartDate)
    );
  };

  const handleRowClick = (weekStartDate) => {
    const formattedDate = formatDateForUrl(parseDate(weekStartDate));
    navigate(`/app/consultant-timesheet/${formattedDate}`);
  };

  if (isLoading) return <div className="min-h-screen bg-gray-100 flex items-center justify-center">Loading...</div>;
  if (error) return <div className="min-h-screen bg-gray-100 flex items-center justify-center text-red-500">{error}</div>;

  return (
    <>
          <main className="flex-1 p-4">
            <div className="max-w-7xl mx-auto">
              <div className="bg-white shadow-md rounded-lg overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Week</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Project</th>
                      <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Client</th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Hours</th>
                      <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y text-left divide-gray-200">
                    {unsubmittedTimesheets.map((timesheet) => {
                      const weekStartDateObj = parseDate(timesheet.weekStartDate);
                      const weekEnd = new Date(weekStartDateObj);
                      weekEnd.setDate(weekEnd.getDate() + 6);

                      const formattedWeekStart = weekStartDateObj.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });
                      const formattedWeekEnd = weekEnd.toLocaleDateString(undefined, { month: 'short', day: 'numeric', year: 'numeric' });

                      return (
                        <tr 
                          key={timesheet.weekStartDate}
                          onClick={() => handleRowClick(timesheet.weekStartDate)}
                          className="hover:bg-gray-50 cursor-pointer"
                        >
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                            {formattedWeekStart} - {formattedWeekEnd}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{timesheet.project}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{timesheet.client}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">{timesheet.totalHours.toFixed(2)}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">${timesheet.totalAmount.toFixed(2)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </main>
    </>
  );
};

export default UnsubmittedTimesheetsPage;