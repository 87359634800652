import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
export default function ConfirmationDialog({ 
  isOpen, 
  onClose, 
  onConfirm, 
  title, 
  message, 
  confirmButtonText = 'Confirm',
  confirmButtonColor = 'blue',
  showReasonField = false,
  reason = '',
  setReason
}) {
  const handleConfirm = () => {
    onConfirm(reason); // Pass the reason to the parent
    if (setReason) setReason(''); // Reset the reason if provided
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-10">
      {/* Dialog backdrop */}
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

      {/* Dialog panel */}
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
            <div>
              <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                {title}
              </Dialog.Title>
              <p className="mt-2 text-sm text-gray-500">{message}</p>

              {/* Conditional Reason Field */}
              {showReasonField && (
                <textarea
                  className="w-full mt-4 rounded-md border border-gray-300 p-2 text-sm"
                  rows="3"
                  placeholder="Enter your reason (optional)"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                />
              )}
            </div>

            {/* Action buttons */}
            <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                onClick={handleConfirm}
                className={`inline-flex justify-center rounded-md ${confirmButtonColor === 'red' ? 'bg-red-600 hover:bg-red-500' : 'bg-blue-600 hover:bg-blue-500'} px-4 py-2 text-sm font-medium text-white shadow-sm`}
              >
                {confirmButtonText}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="mt-3 sm:mt-0 sm:ml-3 inline-flex justify-center rounded-md bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}