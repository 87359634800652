import { Bell } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const NotificationBell = () => {
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [pendingReviews, setPendingReviews] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Fetch unread notifications
      const { data: unreadData, error: unreadError } = await supabase
        .from('notifications_log')
        .select('notification_type, created_at')
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (unreadError) throw unreadError;

      // Count unique combinations of notification_type and created_at
      const uniqueNotifications = new Set(
        unreadData.map(n => `${n.notification_type}_${n.created_at}`)
      );
      setUnreadNotifications(uniqueNotifications.size);

      // Fetch pending reviews count (unchanged)
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('login_email, work_email')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      const { count: reviewCount, error: reviewError } = await supabase
        .from('review_tokens')
        .select('*', { count: 'exact' })
        .is('used_at', null)
        .or(`reviewer_email.eq.${userData.login_email},reviewer_email.eq.${userData.work_email}`);

      if (reviewError) throw reviewError;
      setPendingReviews(reviewCount);
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
    }
  };

  const handleBellClick = () => {
    navigate('/app/notifications');
  };

  const totalUnreadCount = unreadNotifications + pendingReviews;

  return (
    <div className="relative">
      <button onClick={handleBellClick} className="relative">
        <Bell size={24} />
        {totalUnreadCount > 0 && (
          <span className="absolute top-[-4px] right-[-4px] text-red-500 font-bold bg-white rounded-full w-5 h-5 flex items-center justify-center text-small">
            {totalUnreadCount}
          </span>
        )}
      </button>
    </div>
  );
};

// Mark all notifications as read function
export const markAllAsRead = async (userId, setGeneralNotifications, setUnreadNotifications) => {
  if (!userId) {
    console.error('User ID is undefined');
    return;
  }

  try {
    const { error } = await supabase
      .from('notifications_log')
      .update({ is_read: true })
      .eq('user_id', userId)
      .eq('is_read', false);

    if (error) throw error;

    setGeneralNotifications([]);
    setUnreadNotifications(0);
  } catch (error) {
    console.error('Error marking all notifications as read:', error.message);
  }
};

export default NotificationBell;