import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";
import SystemAdminHamburger from "./SystemAdminHamburger";

const SystemAdminDashboard = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    fetchUser();
  }, []);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>System Admin Dashboard | fetchConsultant</title>
        <meta name="description" content="System administration dashboard for fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Solicit Review | fetchConsultant" />
        <meta property="og:description" content="How good of an Epic consultant are you? Solicit a review from a manager or peer on fetchConsultant." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/solicit-review" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <SystemAdminHamburger user={user} currentPage="dashboard" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">System Admin Dashboard</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {/* Add system admin dashboard content here */}
            <p>Welcome to the System Admin Dashboard</p>
            <Link to="/app/system-admin-hours-dashboard" className="fetch-button mt-4">
              Consultant Hours
            </Link>
            <Link to="/app/system-admin-users" className="fetch-button mt-4">
              Users
            </Link>
            <Link to="/app/system-admin-jobs" className="fetch-button mt-4">
              Jobs
            </Link>
            <Link to="/app/system-admin-interview-notes" className="fetch-button mt-4">
              Interview Notes
            </Link>
            <Link to="/app/system-admin-onboarding-requirements" className="fetch-button mt-4">
              Onboarding Requirements
            </Link>
          </main>
        </div>
      </div>
    </>
  );
};

export default SystemAdminDashboard;