import { getMessaging, getToken } from "firebase/messaging";
import { supabase } from '../supabaseClient.js';
import { saveFcmToken } from '../utils/fcmUtils.js';

export const checkNotificationPermission = () => {
  if (!('Notification' in window)) {
    console.log("This browser does not support desktop notification");
    return false;
  }
  return Notification.permission === "granted";
};

export const requestNotificationPermission = async () => {
  if (!('Notification' in window)) {
    console.log("This browser does not support desktop notification");
    return false;
  }

  let permission = await Notification.requestPermission();
  
  if (permission === "granted") {
    console.log("Notification permission granted.");
    await initializeFCM();
    return true;
  } else {
    console.log("Notification permission denied.");
    return false;
  }
};

export const initializeFCM = async () => {
  try {
    const messaging = getMessaging();
    const currentToken = await getToken(messaging, { vapidKey: 'BC8M13my3b9gFIngokcWX-Ph-vmAGUZdkAcIXFvD0GOqN_Z4kqZPuEWp-S6HrKYkSkT_57ATYmzZvZ8i57cnWhU' });
    
    if (currentToken) {
      console.log('FCM token obtained:', currentToken);
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        await saveFcmToken(user.id, currentToken);
        console.log('FCM token saved successfully');
      }
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.error('An error occurred while retrieving or saving the token:', err);
  }
};