import { getAnalytics, logEvent } from "firebase/analytics";
import { Bell, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell, { markAllAsRead } from './NotificationBell';

const Notifications = () => {
  const [reviewNotifications, setReviewNotifications] = useState([]);
  const [generalNotifications, setGeneralNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [filter, setFilter] = useState('Unread');
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Notifications',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  useEffect(() => {
    fetchAllNotifications();
  }, [filter]);

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchAllNotifications = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      // Fetch unread notifications count
      const { count, error: unreadError } = await supabase
        .from('notifications_log')
        .select('*', { count: 'exact' })
        .eq('user_id', user.id)
        .eq('is_read', false);

      if (unreadError) throw unreadError;
      setUnreadNotifications(count);

      // Fetch review notifications
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('login_email, work_email')
        .eq('user_id', user.id)
        .single();

      if (userError) throw userError;

      const reviewQuery = supabase
        .from('review_tokens')
        .select(`
          *,
          consultant:users!review_tokens_consultant_id_fkey(name)
        `)
        .or(`reviewer_email.eq.${userData.login_email},reviewer_email.eq.${userData.work_email}`)
        .order('created_at', { ascending: false });

      if (filter === 'Unread') {
        reviewQuery.is('used_at', null);
      } else {
        reviewQuery.not('used_at', 'is', null);
      }

      const { data: reviewData, error: reviewError } = await reviewQuery;

      if (reviewError) throw reviewError;

      setReviewNotifications(reviewData);

      const { data: generalData, error: generalError } = await supabase
        .from('notifications_log')
        .select('*')
        .eq('user_id', user.id)
        .eq('is_read', filter === 'Unread' ? false : true)
        .order('created_at', { ascending: false });

      if (generalError) throw generalError;

      // Group notifications by notification_type and created_at
      const groupedNotifications = generalData.reduce((acc, notification) => {
        const key = `${notification.notification_type}_${notification.created_at}`;
        if (!acc[key] || new Date(notification.created_at) > new Date(acc[key].created_at)) {
          acc[key] = notification;
        }
        return acc;
      }, {});

      setGeneralNotifications(Object.values(groupedNotifications));

    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    const analytics = getAnalytics();

    if (notification.token) {
      navigate(`/app/submit-review/${notification.token}`);
    } else {
      try {
        // Mark all notifications with the same notification_type and created_at as read
        const { error } = await supabase
          .from('notifications_log')
          .update({ is_read: true })
          .eq('user_id', notification.user_id)
          .eq('notification_type', notification.notification_type)
          .eq('created_at', notification.created_at);
    
        if (error) throw error;
    
        // Filter out all notifications that match the current notification_type and created_at
        setGeneralNotifications(prevNotifications =>
          prevNotifications.filter(notif =>
            notif.notification_type !== notification.notification_type ||
            notif.created_at !== notification.created_at
          )
        );
    
        // Update unread count
        setUnreadNotifications(prevCount => prevCount - 1);
    
        // Log the event after successfully marking the notification as read
        logEvent(analytics, 'notification_action', {
          notification_type: notification.notification_type,
          action_url: notification.action_url,
          action: 'mark_as_read'
        });
    
        // Navigate to action_url if it exists
        if (notification.action_url) {
          window.location.href = notification.action_url;
        }
    
      } catch (error) {
        console.error('Error marking notifications as read:', error.message);
        
        // Log the error event
        logEvent(analytics, 'notification_action_error', {
          notification_type: notification.notification_type,
          error: error.message
        });
      }
    }
  };

  if (isLoading) {
    return <div>Loading notifications...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Notifications | fetchConsultant</title>
        <meta name="description" content="View and manage your notifications on fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Notifications | fetchConsultant" />
        <meta property="og:description" content="View and manage your notifications on fetchConsultant." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/notifications" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        
        {/* Display ConsultantHamburger or ClientHamburger based on user_type_id */}
        {user && user.user_type_id === 2 && (
          <ConsultantHamburger user={user} currentPage="notifications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}
        {user && user.user_type_id === 4 && (
          <ClientHamburger user={user} currentPage="notifications" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        )}

        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Notifications</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="mb-4 flex justify-between items-center">
              <div className="flex items-center">
                <label htmlFor="filterSelect" className="mr-2">Filter:</label>
                <select
                  id="filterSelect"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                  className="fetch-select"
                >
                  <option value="Unread">Unread</option>
                  <option value="Read">Read</option>
                </select>
              </div>
              {unreadNotifications > 0 && user && (
                <button
                  onClick={() => markAllAsRead(user.user_id, setGeneralNotifications, setUnreadNotifications)}
                  className="bg-brandPrimary text-white px-4 py-2 rounded hover:bg-brandHover"
                >
                  Mark All as Read
                </button>
              )}
            </div>

            {reviewNotifications.length === 0 && generalNotifications.length === 0 ? (
              <div className="text-center py-8">
                <p className="text-xl font-semibold text-gray-600">No Notifications</p>
              </div>
            ) : (
              isMobile ? (
                <div className="grid grid-cols-1 gap-4">
                  {[...reviewNotifications, ...generalNotifications].map((notification) => (
                    <div
                      key={notification.id || notification.log_id}
                      className="bg-white text-left shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow cursor-pointer"
                      onClick={() => handleNotificationClick(notification)}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <div className="text-sm font-medium text-gray-500">
                          {new Date(notification.created_at).toLocaleString()}
                        </div>
                          <Bell size={20} className="text-brandPrimary cursor-pointer"/>
                      </div>
                      <div className="text-sm text-gray-900">
                        {notification.token
                          ? `${notification.consultant.name} has requested a ${notification.review_type} review from you`
                          : notification.content.replace(/^Fetch - /, '')}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="bg-white text-left shadow-md rounded-lg">
                  <table className="min-w-full table-auto">
                    <thead className="bg-gray-50">
                      <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Content</th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Received</th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {[...reviewNotifications, ...generalNotifications].map((notification) => (
                        <tr
                          key={notification.id || notification.log_id}
                          className="hover:bg-gray-50 cursor-pointer"
                          onClick={() => handleNotificationClick(notification)}
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <Bell size={20} className="text-brandPrimary cursor-pointer mr-2"/>

                              <div className="text-sm text-gray-900">
                                {notification.token
                                  ? `${notification.consultant.name} has requested a ${notification.review_type} review from you`
                                  : notification.content.replace(/^Fetch - /, '')}
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-500">
                              {new Date(notification.created_at).toLocaleString()}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )
            )}
          </main>
        </div>
      </div>
    </>
  );
};

export default Notifications;