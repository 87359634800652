import { getAnalytics, logEvent } from "firebase/analytics";
import { Apple } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { 
  checkEmailExists, 
  signInWithEmail, 
  signInWithGoogle, 
  signUpWithEmail, 
  fetchReferralDetails 
} from '../supabaseAuth';

const AuthScreen = () => {
  const [isSignUp, setIsSignUp] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [referralData, setReferralData] = useState(null);
  const [referralToken, setReferralToken] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const analytics = getAnalytics();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const referralTokenParam = params.get('referral');
    const reviewTokenParam = params.get('token');

    // Store tokens for AuthCallback.js to process later
    if (referralTokenParam) {
      setReferralToken(referralTokenParam);
      localStorage.setItem('pending_referral_token', referralTokenParam);
      setIsSignUp(true);

      // Fetch referral details if referral token is present
      (async () => {
        try {
          const data = await fetchReferralDetails(referralTokenParam);
          if (data) {
            setReferralData(data);
            setEmail(data.referred_email || '');
          }
        } catch (error) {
          console.error('Error fetching referral details:', error);
          setError('Invalid or expired referral link.');
        }
      })();
    }

    if (reviewTokenParam) {
      localStorage.setItem('pending_review_token', reviewTokenParam);
    }

    // Check if user is already signed in
    supabase.auth.getSession().then(({ data: { session } }) => {
      if (session?.user) {
        // User already signed in, proceed to callback
        navigate('/app/auth/callback');
      }
    });

    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {
        handleSuccessfulAuth(session.user);
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [navigate, location]);

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/app/auth/callback`,
        },
      });
  
      if (error) throw error;
    } catch (error) {
      console.error('Google sign-in error:', error);
      setError(error.message);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      // Currently set to 'google' as placeholder, change to 'apple' when Apple Auth is available
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: `${window.location.origin}/app/auth/callback`,
        },
      });
  
      if (error) throw error;
    } catch (error) {
      console.error('Apple sign-in error:', error);
      setError(error.message);
    }
  };

  const handleEmailAuth = async (e) => {
    e.preventDefault();
    setError(null);
  
    try {
      const emailExists = await checkEmailExists(email);
      let result;
  
      if (isSignUp && !emailExists) {
        // Sign up user
        result = await signUpWithEmail(email, password);
        logEvent(analytics, 'sign_up', { method: 'email' });
  
        if (result.user && !result.session) {
          // User must verify their email
          navigate('/app/verify-email');
          return;
        }

        // If no email confirmation needed (uncommon scenario):
        // AuthCallback will handle user record creation and referral/review processing.
        if (result.user && result.session) {
          // Just redirect to callback; it will finalize everything
          navigate('/app/auth/callback');
        }
      } else {
        // Sign in user
        result = await signInWithEmail(email, password);
        logEvent(analytics, 'sign_in', { method: 'email' });
  
        if (result.user) {
          // Once signed in, AuthCallback will do its job
          navigate('/app/auth/callback');
        }
      }
    } catch (error) {
      console.error("Auth error:", error);
  
      if (error.message.includes("Invalid login credentials")) {
        setError("Invalid email or password. Please try again.");
      } else if (error.message.includes("Email not confirmed")) {
        setError("Please verify your email address before signing in.");
        navigate('/app/verify-email');
      } else {
        setError(`An error occurred: ${error.message}`);
      }
    }
  };

  const handleSuccessfulAuth = async (user) => {
    try {
      // User is signed in, let AuthCallback handle the rest
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;

      const response = await fetch(`${process.env.REACT_APP_SUPABASE_URL}/functions/v1/auth-success`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
            userId: user.id
        })
      });

      if (!response.ok) throw new Error('Failed to handle successful auth');
      const { redirectPath } = await response.json();
      navigate(redirectPath, { replace: true });
    } catch (error) {
      console.error('Error handling successful auth:', error);
      // If something fails here, fallback to the default
      navigate('/app/client-or-consultant');
    }
  };

  const toggleMode = () => setIsSignUp(!isSignUp);

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>{isSignUp ? 'Sign Up' : 'Sign In'} | fetchConsultant</title>
        <meta 
          name="description" 
          content={`${isSignUp ? 'Create an account' : 'Sign in to your account'} on fetchConsultant to connect with top healthcare IT opportunities.`}
        />
      </Helmet>
      
      <div className="flex w-full max-w-4xl shadow-lg">
        
        {/* Left section (form) */}
        <div className="bg-white p-8 w-full sm:max-w-md flex flex-col justify-center items-center">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-2 text-center text-3xl font-extrabold text-gray-900">
              {isSignUp ? 'Create your account' : 'Welcome back'}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {isSignUp ? 'Sign up to get started' : 'Sign in to your account'}
            </p>
          </div>
  
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
              {referralToken && (
                <div className="text-center text-sm text-green-600 mb-4">
                  You've been invited to join fetchConsultant. Please sign up to accept the invitation.
                </div>
              )}
              <div className="space-y-6">
                <button
                  onClick={handleAppleSignIn}
                  type="button"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                >
                  <Apple className="mr-2 h-5 w-5" />
                  Continue with Apple
                </button>
  
                <button
                  onClick={handleGoogleSignIn}
                  type="button"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <svg className="mr-2 h-5 w-5" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M21.35,11.1H12.18V13.83H18.69C18.36,17.64 15.19,19.27 12.19,19.27C8.36,19.27 5,16.25 5,12C5,7.9 8.2,4.73 12.2,4.73C15.29,4.73 17.1,6.7 17.1,6.7L19,4.72C19,4.72 16.56,2 12.1,2C6.42,2 2.03,6.8 2.03,12C2.03,17.05 6.16,22 12.25,22C17.6,22 21.5,18.33 21.5,12.91C21.5,11.76 21.35,11.1 21.35,11.1V11.1Z"
                    />
                  </svg>
                  Continue with Google
                </button>
  
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300"></div>
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">or</span>
                  </div>
                </div>
  
                <form onSubmit={handleEmailAuth} className="space-y-6">
                  <div className="text-left">
                    <label htmlFor="email" className="fetch-label">
                      Email
                    </label>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="fetch-input"
                    />
                  </div>
  
                  <div className="text-left">
                    <div className="flex justify-between items-center">
                      <label htmlFor="password" className="fetch-label">
                        Password
                      </label>
                      {!isSignUp && (
                        <Link to="/app/forgot-password" className="text-sm text-brandPrimary hover:text-indigo-500">
                          Forgot Password?
                        </Link>
                      )}
                    </div>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="fetch-input"
                    />
                  </div>
  
                  {error && (
                    <div className="text-red-600 text-sm">{error}</div>
                  )}
  
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-montserrat text-white bg-brandBlack hover:bg-brandDark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandPrimary"
                  >
                    {isSignUp ? 'Sign up' : 'Sign in'}
                  </button>
                </form>
              </div>
  
              <div className="mt-6">
                <p className="text-center text-sm text-gray-500">
                  {isSignUp ? 'Already have an account?' : "Don't have an account?"}{' '}
                  <button
                    onClick={toggleMode}
                    className="font-medium text-brandPrimary hover:text-indigo-500"
                  >
                    {isSignUp ? 'Sign in' : 'Sign up'}
                  </button>
                </p>
              </div>
  
              <div className="mt-6">
                <p className="text-center text-xs text-gray-500">
                  By continuing, you agree to fetchConsultant's{' '}
                  <Link to="/terms" className="underline">Terms of Service</Link> and{' '}
                  <Link to="/app/privacy" className="underline">Privacy Policy</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
        
        {/* Right section */}
        <div className="hidden md:block bg-brandPrimary text-white p-10 w-1/2 flex flex-col justify-between">
          <div className="flex flex-col justify-center flex-grow">
            <h2 className="text-center text-3xl font-extrabold mb-8">Why fetchConsultant</h2>
              <div className="flex justify-center">
                <ul className="space-y-6 flex flex-col">
                  <li>
                    <div className="flex items-start">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Transparent, Fair Pricing</h3>
                    </div>
                    <p className="text-left ml-10">One rate with a simple 9% platform fee. No hidden costs, no inflated rates. Fair for everyone.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Real-Time Opportunities</h3>
                    </div>
                    <p className="text-left ml-10">Instant notifications for new jobs and applicants. Fill positions in days, not weeks.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Streamlined Contracting Process</h3>
                    </div>
                    <p className="text-left ml-10">From job posting to Zoom interviews, our integrated platform accelerates every step of hiring.</p>
                  </li>
                </ul>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthScreen;