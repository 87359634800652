// CalendarLink.jsx
import React, { useState, useEffect } from 'react';

function CalendarLink({ icsData }) {
  const [href, setHref] = useState('');

  useEffect(() => {
    const data = icsData.replace(/\n/g, '\r\n');
    const blob = new Blob([data], { type: 'text/calendar;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    setHref(url);

    // Clean up the URL object when the component unmounts
    return () => {
      URL.revokeObjectURL(url);
    };
  }, [icsData]);

  return (
    <a
      href={href}
      download="interview.ics"
      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
    >
      📅 Add to Calendar
    </a>
  );
}

export default CalendarLink;