import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.header}>404</h1>
            <h2 style={styles.subHeader}>Oops! Page Not Found</h2>
            <p style={styles.paragraph}>Sorry, but the page you were trying to view does not exist.</p>
            <Link to="/" style={styles.homeButton}>Go to Homepage</Link>
        </div>
    );
};

const styles = {
    container: {
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#f4f4f4',
        color: '#333',
        textAlign: 'center',
        padding: '50px',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    header: {
        fontSize: '72px',
        margin: '0',
        color: '#FF6B6B',
    },
    subHeader: {
        fontSize: '24px',
        margin: '20px 0',
    },
    paragraph: {
        fontSize: '18px',
        margin: '20px 0',
    },
    homeButton: {
        display: 'inline-block',
        padding: '10px 20px',
        color: 'white',
        backgroundColor: '#007BFF',
        textDecoration: 'none',
        borderRadius: '5px',
        marginTop: '20px',
    }
};

export default NotFoundPage;