import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";

const ClientApproveHours = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('Pending');
  const navigate = useNavigate();
  
  const statusFilterStyles = {
    base: "px-4 py-2 text-sm font-medium",
    default: "text-gray-500 hover:bg-gray-100",
    active: "bg-brandPrimary text-white rounded-md"
  };

  const renderTabs = () => (
    <div className="flex space-x-4 mb-4">
      {['Pending', 'Approved'].map((tab) => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`${statusFilterStyles.base} ${
            activeTab === tab
              ? statusFilterStyles.active
              : statusFilterStyles.default
          }`}
        >
          {tab}
        </button>
      ))}
    </div>
  );

  // Helper Functions
  function formatDate(date) {
    return date.toISOString().split('T')[0];
  }

  function parseDate(dateStr) {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  }

  function getWeekStart(date) {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() - d.getDay());
    return d;
  }

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Approve Hours',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
    
    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchTimesheets();
    }
  }, [user, activeTab]);

  const fetchUser = async () => {
    try {
      const { data: { user: authUser }, error } = await supabase.auth.getUser();
      if (error) throw error;
      
      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', authUser.id)
        .single();
        
      if (userError) throw userError;
      setUser(userData);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to fetch user data');
    }
  };

  const fetchTimesheets = async () => {
    try {
      setIsLoading(true);
      const status = activeTab === 'Pending' ? 'submitted' : 'approved';

      const { data, error } = await supabase
        .from('timesheet_entries')
        .select(`
          *,
          users:consultant_id (
            name,
            photo
          ),
          timesheet_tasks (
            is_billable
          ),
          engagements (
            contract_rate,
            job:job_id (
              title
            )
          )
        `)
        .eq('status', status)
        .order('date', { ascending: false });

      if (error) throw error;

      const timesheetsByWeek = processTimesheetData(data);
      setTimesheets(timesheetsByWeek);
    } catch (error) {
      console.error('Error fetching timesheets:', error);
      setError('Failed to load timesheets');
    } finally {
      setIsLoading(false);
    }
  };

  const processTimesheetData = (data) => {
    const timesheetsByWeek = {};
    
    data.forEach((entry) => {
      const entryDate = parseDate(entry.date);
      const weekStartDateObj = getWeekStart(entryDate);
      const weekStartDate = formatDate(weekStartDateObj);
      const consultantId = entry.consultant_id;
      const weekKey = `${weekStartDate}-${consultantId}`;

      if (!timesheetsByWeek[weekKey]) {
        timesheetsByWeek[weekKey] = {
          weekStartDate,
          weekStartDateObj,
          consultant: entry.users.name,
          consultantId: consultantId,
          consultantPhoto: entry.users.photo,
          project: entry.engagements?.job?.title || 'Unknown Project',
          contractRate: entry.engagements?.contract_rate || 0,
          totalAmount: 0,
          totalHours: 0,
          submitted_at: entry.submitted_at,
          approved_at: entry.approved_at
        };
      }

      if (entry.timesheet_tasks?.is_billable) {
        timesheetsByWeek[weekKey].totalHours += parseFloat(entry.hours) || 0;
        timesheetsByWeek[weekKey].totalAmount += 
          (parseFloat(entry.hours) || 0) * (entry.engagements?.contract_rate || 0);
      }
    });
    
    return Object.values(timesheetsByWeek).sort((a, b) => 
      b.weekStartDateObj - a.weekStartDateObj
    );
  };

  const handleRowClick = (weekStartDate, consultantId) => {
    navigate(`/app/client-timesheet-detail/${weekStartDate}/${consultantId}`);
  };

  const tabs = [
    { 
      name: 'Pending', 
      count: timesheets.length, 
      current: activeTab === 'Pending',
    },
    { 
      name: 'Approved', 
      count: null, 
      current: activeTab === 'Approved',
    }
  ];

  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Approve Hours | fetchConsultant</title>
        <meta 
          name="description" 
          content="Review and approve consultant timesheet entries on fetchConsultant." 
        />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger 
          user={user} 
          currentPage="approveHours" 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Approve Hours</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="flex-1 p-4">
            <div className="max-w-7xl mx-auto">
              {renderTabs()}
              <div className="mt-4 bg-white shadow-md rounded-lg overflow-hidden">
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Consultant
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Week
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Project
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Hours
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Amount
                        </th>
                        <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rate
                        </th>
                        {activeTab === 'Approved' && (
                          <th scope="col" className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Approved
                          </th>
                        )}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {timesheets.map((timesheet) => {
                        const weekEnd = new Date(timesheet.weekStartDateObj);
                        weekEnd.setDate(weekEnd.getDate() + 6);

                        return (
                          <tr 
                            key={`${timesheet.weekStartDate}-${timesheet.consultantId}`}
                            onClick={() => handleRowClick(timesheet.weekStartDate, timesheet.consultantId)}
                            className="hover:bg-gray-50 text-left cursor-pointer"
                          >
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  <img 
                                    className="h-10 w-10 rounded-full"
                                    src={timesheet.consultantPhoto || '/default-avatar.png'}
                                    alt={timesheet.consultant}
                                  />
                                </div>
                                <div className="ml-4">
                                  <div className="text-sm font-medium text-gray-900">
                                    {timesheet.consultant}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {timesheet.weekStartDateObj.toLocaleDateString()} - {weekEnd.toLocaleDateString()}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {timesheet.project}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                              {timesheet.totalHours.toFixed(2)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                              ${timesheet.contractRate.toFixed(0)}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                              ${timesheet.totalAmount.toFixed(2)}
                            </td>
                            {activeTab === 'Approved' && (
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-right">
                                {new Date(timesheet.approved_at).toLocaleDateString()}
                              </td>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                
                {timesheets.length === 0 && (
                  <div className="text-center py-8 text-gray-500">
                    No {activeTab.toLowerCase()} timesheets found
                  </div>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientApproveHours;