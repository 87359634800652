import { getAnalytics, logEvent } from "firebase/analytics";
import { Checkbox, Menu, Star } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from "./ClientHamburger";
import NotificationBell from "./NotificationBell";

const SubmitReview = () => {
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  const [shareContactInfo, setShareContactInfo] = useState(true);  // Default to true
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pendingReviews, setPendingReviews] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Submit Review',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    checkAuthStatus();
    fetchUser();
  }, []);


  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const checkAuthStatus = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      setIsAuthenticated(true);
      setCurrentUserId(user.id);
      fetchPendingReviews(user.id);
    } else {
      navigate(`/app/review-benefits/${token}`);
    }
  };

  const fetchPendingReviews = async (userId) => {
    try {
      let query = supabase
        .from('review_tokens')
        .select(`
          *,
          consultant:users!review_tokens_consultant_id_fkey(name, user_id),
          user_job:user_jobs!review_tokens_reviewed_user_job_fkey(
            user_job_id, job:jobs(title)
          )
        `)
        .is('used_at', null);
  
      if (token) {
        query = query.eq('token', token);
      } else {
        query = query.eq('reviewer_id', userId);
      }
  
      const { data, error } = await query;
  
      if (error) throw error;
  
      // Filter out self-reviews
      const filteredReviews = data.filter(review => review.consultant.user_id !== userId);
  
      setPendingReviews(filteredReviews);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching pending reviews:', error);
      setMessage('Error fetching pending reviews. Please try again later.');
      setIsLoading(false);
    }
  };

  const handleSubmit = async (e, reviewToken) => {
    e.preventDefault();
    setIsLoading(true);
    
    // Log the entire reviewToken object
    console.log("Review token object:", reviewToken);
    
    try {
      if (currentUserId === reviewToken.consultant.user_id) {
        throw new Error("You cannot review yourself");
      }
  
      if (rating === 0) {
        throw new Error("Please select a rating");
      }
  
      if (reviewText.trim().length === 0) {
        throw new Error("Please enter a review");
      }
  
      // Update review token status
      const { error: tokenUpdateError } = await supabase
        .from('review_tokens')
        .update({ status: 'accepted' })
        .eq('token', reviewToken.token);
  
      if (tokenUpdateError) throw tokenUpdateError;
      console.log("Review token updated successfully");
  
      const { data: existingReview, error: checkError } = await supabase
        .from('reviews')
        .select('review_id')
        .eq('consultant_id', reviewToken.consultant_id)
        .eq('reviewer_id', currentUserId)
        .single();
  
      if (checkError && checkError.code !== 'PGRST116') {
        throw checkError;
      }
  
      if (existingReview) {
        throw new Error("You have already submitted a review for this consultant.");
      }
  
      const userJobId = reviewToken.reviewed_user_job || null;

      const reviewPayload = {
        consultant_id: reviewToken.consultant_id,
        reviewer_id: currentUserId,
        rating,
        review_text: reviewText,
        review_type: reviewToken.review_type,
        user_job_id: reviewToken.reviewed_user_job,
        share_contact_info: shareContactInfo  // Add this field
      };
  
      console.log("Payload for review insert:", reviewPayload); // Log the entire payload
  
      const { error: reviewError } = await supabase
        .from('reviews')
        .insert(reviewPayload);
  
      if (reviewError) throw reviewError;
  
      // Mark the review token as used
      await supabase
        .from('review_tokens')
        .update({ used_at: new Date().toISOString() })
        .eq('id', reviewToken.id);
  
      setMessage('Review submitted successfully!');
      setRating(0);
      setReviewText('');
      fetchPendingReviews(currentUserId);
  
      // Log the event with the new field
      const analytics = getAnalytics();
      logEvent(analytics, 'review_submitted', {
        review_type: reviewToken.review_type,
        user_job_id: userJobId,
        share_contact_info: shareContactInfo
      });

    } catch (error) {
      console.error('Error submitting review:', error);
      setMessage(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthenticated) {
    return null; // The user will be redirected in checkAuthStatus
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Submit Review | fetchConsultant</title>
        <meta name="description" content="Submit a review for a consultant on fetchConsultant. Share your experience and help others make informed decisions." />
        <meta name="keywords" content="review, consultant, feedback, rating" />
        <meta property="og:title" content="Submit Review | fetchConsultant" />
        <meta property="og:description" content="Submit a review for a consultant on fetchConsultant. Share your experience and help others make informed decisions." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ClientHamburger user={user} currentPage="submit-review" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Submit Review</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
              {message && (
                <div className={`bg-${message.startsWith('Error') ? 'red' : 'green'}-100 border border-${message.startsWith('Error') ? 'red' : 'green'}-400 text-${message.startsWith('Error') ? 'red' : 'green'}-700 px-4 py-3 rounded relative mb-4`} role="alert">
                  <strong className="font-bold">{message.startsWith('Error') ? 'Error: ' : 'Success: '}</strong>
                  <span className="block sm:inline">{message}</span>
                </div>
              )}
              {pendingReviews.length === 0 ? (
                <p>No pending reviews at this time.</p>
              ) : (
                pendingReviews.map((reviewToken) => (
                  <div key={reviewToken.id} className="mb-8">
                    <h2 className="text-xl font-semibold mb-2">Review for {reviewToken.consultant.name}</h2>
                    <p className="mb-4">Review type: {reviewToken.review_type}</p>
                    {reviewToken.user_job && reviewToken.user_job.job && (
                      <p className="mb-4">Job: {reviewToken.user_job.job.title}</p>
                    )}
                    <form onSubmit={(e) => handleSubmit(e, reviewToken)} className="fetch-form">
                      <div className="mb-4">
                        <label className="fetch-label">Rating<span className="fetch-required">*</span></label>
                        <div className="flex">
                          {[1, 2, 3, 4, 5].map((star) => (
                            <Star
                              key={star}
                              size={24}
                              onClick={() => setRating(star)}
                              fill={star <= rating ? 'gold' : 'none'}
                              stroke={star <= rating ? 'gold' : 'currentColor'}
                              className="cursor-pointer"
                              role="button"
                              aria-label={`Rate ${star} stars`}
                              tabIndex={0}
                              onKeyPress={(e) => {
                                if (e.key === 'Enter' || e.key === ' ') {
                                  setRating(star);
                                }
                              }}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="mb-4">
                        <label htmlFor="reviewText" className="fetch-label">Review<span className="fetch-required">*</span></label>
                        <textarea
                          id="reviewText"
                          value={reviewText}
                          onChange={(e) => setReviewText(e.target.value)}
                          className="fetch-input"
                          rows="4"
                          required
                          aria-required="true"
                        ></textarea>
                      </div>
                      <div className="mt-4 flex items-center">
                        <input
                          type="checkbox"
                          id="share_contact_info"
                          checked={shareContactInfo}
                          onChange={(e) => setShareContactInfo(e.target.checked)}
                          className="fetch-checkbox"
                        />
                        <label htmlFor="share_contact_info" className="fetch-checkbox-label ml-2">
                          Share my contact info with prospective employers for reference check purposes
                        </label>
                      </div>
                      <button
                        type="submit"
                        className="fetch-button"
                        disabled={isLoading}
                      >
                        {isLoading ? 'Submitting...' : 'Submit Review'}
                      </button>
                    </form>
                  </div>
                ))
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default SubmitReview;