import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, SquareCheck, SquareX, Info, File } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import RejectFileModal from './RejectFileModal';

const ClientOnboardingProgress = () => {
  const [engagements, setEngagements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [sortConfig, setSortConfig] = useState([
    { key: 'requirement_name', direction: 'asc' },
    { key: 'status', direction: 'asc' },
  ]);
  const { engagementId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Onboarding Progress',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);

    fetchUserAndEngagements();

    return () => window.removeEventListener('resize', checkMobile);
  }, [engagementId]);

  useEffect(() => {
    // Re-sort requirements when sortConfig changes
    if (engagements.length > 0) {
      const sortedRequirements = sortRequirements(engagements[0].requirements);
      setEngagements([{ ...engagements[0], requirements: sortedRequirements }]);
    }
  }, [sortConfig]);

  const fetchUserAndEngagements = async () => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', authUser.id)
        .single();

      if (userError) throw userError;
      setUser(userData);

      const { data: engagementData, error: engagementError } = await supabase
        .from('engagements')
        .select(`
          *,
          user_jobs!inner (
            user_id,
            users!user_jobs_user_id_fkey (
              name,
              login_email
            ),
            job:job_id (
              title
            )
          )
        `)
        .eq('id', engagementId)
        .single();

      if (engagementError) throw engagementError;

      // Fetch all applicable requirements with left join to status
      const { data: requirementsData, error: requirementsError } = await supabase
        .from('onboarding_requirements')
        .select(`
          *,
          consultant_onboarding_status (
            id,
            status,
            uploaded_file_url,
            rejection_notes,
            updated_at,
            engagement_id
          )
        `)
        .or(`requirement_type.eq.global,client_id.eq.${engagementData.client_id}`);

      if (requirementsError) throw requirementsError;

      // Process requirements to add 'New' status where needed
      const processedRequirements = requirementsData.map(req => {
        const status = req.consultant_onboarding_status?.find(
          s => s.engagement_id === engagementId
        );
        return {
          ...req,
          consultant_onboarding_status: status ? [status] : [{ status: 'New' }]
        };
      });

      const sortedRequirements = sortRequirements(processedRequirements);

      setEngagements([{ ...engagementData, requirements: sortedRequirements }]);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const sortRequirements = (reqs) => {
    return [...reqs].sort((a, b) => {
      for (let config of sortConfig) {
        let aValue;
        let bValue;

        switch (config.key) {
          case 'requirement_name':
            aValue = a.requirement_name || '';
            bValue = b.requirement_name || '';
            break;
          case 'status':
            // Handle 'New' status first
            const aStatus = a.consultant_onboarding_status[0]?.status || 'New';
            const bStatus = b.consultant_onboarding_status[0]?.status || 'New';
            if (aStatus === 'New' && bStatus !== 'New') return -1;
            if (bStatus === 'New' && aStatus !== 'New') return 1;
            aValue = aStatus;
            bValue = bStatus;
            break;
          default:
            aValue = a[config.key] || '';
            bValue = b[config.key] || '';
        }

        aValue = aValue.toString().toLowerCase();
        bValue = bValue.toString().toLowerCase();

        if (aValue < bValue) return config.direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return config.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => {
      const existingConfig = prevConfig.find(config => config.key === key);
      let newDirection = 'asc';

      if (existingConfig) {
        newDirection = existingConfig.direction === 'asc' ? 'desc' : 'asc';
        const newConfig = [{ key, direction: newDirection }, ...prevConfig.filter(config => config.key !== key)];
        return newConfig;
      } else {
        return [{ key, direction: 'asc' }, ...prevConfig];
      }
    });
  };

  const getSortIndicator = (key) => {
    const config = sortConfig.find(c => c.key === key);
    if (config) {
      return config.direction === 'asc' ? '▲' : '▼';
    }
    return '';
  };

  const handleRejectFile = async (notes) => {
    if (!selectedRequirement || !user) return;

    try {
      const { error } = await supabase
        .from('consultant_onboarding_status')
        .update({
          status: 'Rejected',
          rejection_notes: notes,
          rejected_at: new Date().toISOString(),
          rejected_by: user.user_id
        })
        .eq('id', selectedRequirement.consultant_onboarding_status[0].id);

      if (error) throw error;
      // Optionally, send notification to the consultant

      fetchUserAndEngagements();
    } catch (error) {
      console.error('Error rejecting file:', error.message);
      alert('Failed to reject file. Please try again.');
    }
  };

  const handleApproveFile = async (requirement) => {
    try {
      const { data: { user: authUser } } = await supabase.auth.getUser();

      const { error } = await supabase
        .from('consultant_onboarding_status')
        .update({
          status: 'Approved',
          approved_at: new Date().toISOString(),
          approved_by: authUser.id
        })
        .eq('id', requirement.consultant_onboarding_status[0].id);

      if (error) throw error;

      fetchUserAndEngagements();
    } catch (error) {
      console.error('Error approving file:', error.message);
      alert('Failed to approve file. Please try again.');
    }
  };

  const renderTable = () => (
    <div className="bg-white shadow-md rounded-lg overflow-hidden">
      <table className="min-w-full table-auto">
        <thead className="bg-gray-50">
          <tr>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('requirement_name')}
            >
              Requirement {getSortIndicator('requirement_name')}
            </th>
            <th
              className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
              onClick={() => handleSort('status')}
            >
              Status {getSortIndicator('status')}
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y text-left divide-gray-200">
          {engagements[0]?.requirements.map((requirement) => (
            <tr key={requirement.id} className="hover:bg-gray-50">
              <td className="px-6 py-4 text-sm text-gray-900">
                {requirement.requirement_name}
                {requirement.is_mandatory && <span className="text-red-500 ml-1">*</span>}
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">
                <span className={`font-semibold ${
                  requirement.consultant_onboarding_status[0]?.status === 'Approved' ? 'text-green-600' :
                  requirement.consultant_onboarding_status[0]?.status === 'Rejected' ? 'text-red-600' :
                  requirement.consultant_onboarding_status[0]?.status === 'Pending Approval' ? 'text-yellow-600' :
                  'text-gray-600'
                }`}>
                  {requirement.consultant_onboarding_status[0]?.status || 'New'}
                </span>
              </td>
              <td className="px-6 py-4 text-sm text-gray-500">
                <div className="flex items-center space-x-4">
                  <Info
                    size={20}
                    className={`cursor-pointer ${
                      requirement.consultant_onboarding_status[0]?.status === 'Rejected' ? 'text-red-500' : 'text-gray-400'
                    }`}
                    onClick={() => alert(
                      `${requirement.description || 'No description available.'}${
                        requirement.consultant_onboarding_status[0]?.status === 'Rejected'
                          ? `\n\nRejection reason: ${requirement.consultant_onboarding_status[0].rejection_notes}`
                          : ''
                      }`
                    )}
                  />
                  {requirement.consultant_onboarding_status[0]?.uploaded_file_url && (
                    <a
                      href={requirement.consultant_onboarding_status[0].uploaded_file_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      <File size={20} />
                    </a>
                  )}
                  {requirement.consultant_onboarding_status[0]?.status === 'Pending Approval' && (
                    <>
                      <SquareCheck
                        size={20}
                        className="text-green-600 cursor-pointer"
                        onClick={() => handleApproveFile(requirement)}
                      />
                      <SquareX
                        size={20}
                        className="text-red-600 cursor-pointer"
                        onClick={() => {
                          setSelectedRequirement(requirement);
                          setIsRejectModalOpen(true);
                        }}
                      />
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const renderCards = () => (
    <div className="space-y-4 text-left">
      {engagements[0]?.requirements.map((requirement) => (
        <div key={requirement.id} className="bg-white shadow rounded-lg p-4">
          <h3 className="text-lg font-medium text-gray-900">
            {requirement.requirement_name}
            {requirement.is_mandatory && <span className="text-red-500 ml-1">*</span>}
          </h3>
          <p className="text-sm text-gray-500 mt-1">
            Status: <span className={`font-semibold ${
              requirement.consultant_onboarding_status[0]?.status === 'Approved' ? 'text-green-600' :
              requirement.consultant_onboarding_status[0]?.status === 'Rejected' ? 'text-red-600' :
              requirement.consultant_onboarding_status[0]?.status === 'Pending Approval' ? 'text-yellow-600' :
              'text-gray-600'
            }`}>
              {requirement.consultant_onboarding_status[0]?.status || 'New'}
            </span>
          </p>
          {requirement.consultant_onboarding_status[0]?.rejection_notes && (
            <p className="text-sm text-red-600 mt-1">
              Rejection reason: {requirement.consultant_onboarding_status[0].rejection_notes}
            </p>
          )}
          <div className="mt-4 flex items-center space-x-4">
            <Info
              size={20}
              className={`cursor-pointer ${
                requirement.consultant_onboarding_status[0]?.status === 'Rejected' ? 'text-red-500' : 'text-gray-400'
              }`}
              onClick={() => alert(
                `${requirement.description || 'No description available.'}${
                  requirement.consultant_onboarding_status[0]?.status === 'Rejected'
                    ? `\n\nRejection reason: ${requirement.consultant_onboarding_status[0].rejection_notes}`
                    : ''
                }`
              )}
            />
            {requirement.consultant_onboarding_status[0]?.uploaded_file_url && (
              <a
                href={requirement.consultant_onboarding_status[0].uploaded_file_url}
                target="_blank"
                rel="noopener noreferrer"
                className="text-indigo-600 hover:text-indigo-900"
              >
                <File size={20} />
              </a>
            )}
            {requirement.consultant_onboarding_status[0]?.status === 'Pending Approval' && (
              <>
                <SquareCheck
                  size={20}
                  className="text-green-600 cursor-pointer"
                  onClick={() => handleApproveFile(requirement)}
                />
                <SquareX
                  size={20}
                  className="text-red-600 cursor-pointer"
                  onClick={() => {
                    setSelectedRequirement(requirement);
                    setIsRejectModalOpen(true);
                  }}
                />
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Helmet>
        <title>Onboarding Progress | fetchConsultant</title>
        <meta name="description" content="Track consultant onboarding progress and manage document submissions." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="onboarding" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">
                {engagements[0]?.user_jobs?.users?.name} - {engagements[0]?.user_jobs?.job?.title}
              </h1>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => navigate('/app/client-onboarding-requirements')}
                className="bg-indigo-600 text-white px-4 py-2 rounded mr-4"
              >
                Manage Requirements
              </button>
              <NotificationBell />
            </div>
          </header>

          <main className="p-4">
            {engagements.length === 0 ? (
              <p className="text-left">No engagements found.</p>
            ) : (
              isMobile ? renderCards() : renderTable()
            )}
          </main>
        </div>
      </div>

      <RejectFileModal
        isOpen={isRejectModalOpen}
        onClose={() => setIsRejectModalOpen(false)}
        onReject={handleRejectFile}
      />
    </>
  );
};

export default ClientOnboardingProgress;