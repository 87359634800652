import { supabase } from "./supabaseClient.js"

export const signInWithGoogle = async () => {
  console.log("Initiating Google sign in...");
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: `https://fetchconsultant.com/app/auth/callback`
    }
  });
  if (error) {
    console.error("Supabase Apple sign in error:", error);
    throw error;
  }
  console.log("Supabase Apple sign in data:", data);
  return data;
}

export const signInWithApple = async () => {
  console.log("Initiating Apple sign in...");
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'apple',
    options: {
      redirectTo: `${window.location.origin}/app/auth/callback`
    }
  });
  if (error) {
    console.error("Supabase Apple sign in error:", error);
    throw error;
  }
  console.log("Supabase Apple sign in data:", data);
  return data;
}

export const signUpWithEmail = async (email, password) => {
  console.log("Attempting to sign up with email:", email);
  const { data, error } = await supabase.auth.signUp({
    email,
    password,
    options: {
      emailRedirectTo: `${window.location.origin}/app/auth/callback`
    }
  });
  if (error) {
    console.error("Sign up error:", error);
    throw error;
  }
  console.log("Sign up successful:", data);
  return { user: data.user, session: data.session };
}

export const signInWithEmail = async (email, password) => {
  console.log("Attempting to sign in with email:", email);
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (error) {
    console.error("Sign in error:", error);
    throw error;
  }
  console.log("Sign in successful:", data);
  return { user: data.user, session: data.session };
}

export const signOut = async () => {
  const { error } = await supabase.auth.signOut()
  if (error) throw error
}

export const getCurrentUser = async () => {
  const { data, error } = await supabase.auth.getUser()
  if (error) throw error
  return data.user
}

export const onAuthStateChange = (callback) => {
  return supabase.auth.onAuthStateChange((event, session) => {
    callback(session?.user || null)
  })
}

export const checkEmailExists = async (email) => {
  try {
    const { data, error } = await supabase.functions.invoke('checkEmailExists', {
      body: { email },
    });

    if (error) {
      throw error;
    }

    return data.exists;
  } catch (error) {
    console.error('Error in checkEmailExists:', error);
    throw error;
  }
};

export const resetPasswordForEmail = async (email) => {
  const { data, error } = await supabase.auth.resetPasswordForEmail(email);
  if (error) throw error;
  return data;
};

export const createUserRecord = async (userId, email, userTypeId = 1) => {
  try {
    const { data, error } = await supabase.functions.invoke('createUserRecord', {
      body: { userId, email, userTypeId },
    });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in createUserRecord:', error);
    throw error;
  }
};

export const fetchReferralDetails = async (token) => {
  try {
    const { data, error } = await supabase.functions.invoke('fetchReferralDetails', {
      body: { token },
    });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in fetchReferralDetails:', error);
    throw error;
  }
};

export const handleReferral = async (userId, referralToken) => {
  try {
    const { data, error } = await supabase.functions.invoke('handleReferral', {
      body: { userId, referralToken },
    });

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error('Error in handleReferral:', error);
    throw error;
  }
};

export const getUserType = async (userId) => {
  try {
    const { data, error } = await supabase.functions.invoke('getUserType', {
      body: { userId },
    });

    if (error) throw error;

    return data.user_type_id;
  } catch (error) {
    console.error('Error in getUserType:', error);
    throw error;
  }
};