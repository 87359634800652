import React from 'react';
import Modal from './Modal';
import { AlertTriangle } from 'lucide-react';

const SubmitConfirmationModal = ({ isOpen, onClose, onConfirm, isSubmitting }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Submit Timesheet for Approval"
      size="md"
    >
      <div className="space-y-4">
        <div className="flex items-start space-x-3">
          <div className="flex-shrink-0">
            <AlertTriangle className="h-6 w-6 text-yellow-500" />
          </div>
          <div>
            <h3 className="text-lg font-medium text-gray-900">
              Are you sure you want to submit this timesheet?
            </h3>
            <p className="mt-2 text-sm text-gray-500">
              Once submitted, you won't be able to make any changes unless the timesheet is rejected.
              Please review your entries carefully before submitting.
            </p>
          </div>
        </div>

        <div className="bg-yellow-50 p-4 rounded-md">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-yellow-800">
                Important Note
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <ul className="list-disc pl-5 space-y-1">
                  <li>All entries will be locked after submission</li>
                  <li>Your timesheet will be sent for approval</li>
                  <li>You will be notified once it's approved or rejected</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-5 flex justify-end space-x-3">
          <button
            type="button"
            className="fetch-button bg-gray-500 hover:bg-gray-600"
            onClick={onClose}
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            type="button"
            className="fetch-button"
            onClick={onConfirm}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Yes, submit timesheet'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SubmitConfirmationModal;