import React from 'react';

const Toggle = ({ enabled, onChange, label }) => (
  <div className="flex items-center">
    <button
      type="button"
      className={`${
        enabled ? 'bg-brandPrimary' : 'bg-gray-200'
      } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
      role="switch"
      aria-checked={enabled}
      onClick={onChange}
    >
      <span className="sr-only">{label}</span>
      <span
        aria-hidden="true"
        className={`${
          enabled ? 'translate-x-5' : 'translate-x-0'
        } pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
      />
    </button>
    <span className="ml-2 text-sm text-gray-900">{label}</span>
  </div>
);

export default Toggle;