import React from 'react';
import { AlertCircle, Check, Loader } from 'lucide-react';

const StatusBanner = ({ status, message }) => {
  const getBannerStyles = () => {
    switch (status) {
      case 'saving':
        return 'bg-blue-50 text-blue-700';
      case 'saved':
        return 'bg-green-50 text-green-700';
      case 'error':
        return 'bg-red-50 text-red-700';
      case 'success':
        return 'bg-green-50 text-green-700';
      default:
        return 'bg-gray-50 text-gray-700';
    }
  };

  const getIcon = () => {
    switch (status) {
      case 'saving':
        return <Loader className="animate-spin h-5 w-5" />;
      case 'saved':
        return <Check className="h-5 w-5" />;
      case 'error':
        return <AlertCircle className="h-5 w-5" />;
      case 'success':
        return <Check className="h-5 w-5" />;
      default:
        return null;
    }
  };

  return (
    <div className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${status ? 'translate-y-0' : '-translate-y-full'}`}>
      <div className={`flex items-center justify-center p-4 ${getBannerStyles()}`}>
        <div className="flex items-center space-x-2">
          {getIcon()}
          <span className="text-sm font-medium">{message}</span>
        </div>
      </div>
    </div>
  );
};

export default StatusBanner;