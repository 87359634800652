import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, ChevronRight } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import NotificationBell from "./NotificationBell";

const SystemAdminHoursDashboard = () => {
  const [timesheetEntries, setTimesheetEntries] = useState([]);
  const [filter, setFilter] = useState('all');
  const [weekStart, setWeekStart] = useState(getWeekStart(new Date()));
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'System Admin Hours Dashboard',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchTimesheetEntries();
  }, [filter, weekStart]);

  function getWeekStart(date) {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  }

  const fetchTimesheetEntries = async () => {
    try {
      const weekEnd = new Date(weekStart);
      weekEnd.setDate(weekEnd.getDate() + 6);

      let query = supabase
        .from('timesheet_entries')
        .select(`
          *,
          users:consultant_id (name),
          jobs (title, client:clients(name))
        `)
        .gte('date', weekStart.toISOString().split('T')[0])
        .lte('date', weekEnd.toISOString().split('T')[0])
        .order('date', { ascending: true });

      if (filter !== 'all') {
        query = query.eq('status', filter);
      }

      const { data, error } = await query;

      if (error) throw error;

      const groupedEntries = data.reduce((acc, entry) => {
        const key = `${entry.consultant_id}-${entry.job_id}`;
        if (!acc[key]) {
          acc[key] = {
            consultant: entry.users.name,
            job: entry.jobs.title,
            client: entry.jobs.client.name,
            entries: [],
            totalHours: 0,
            status: entry.status,
          };
        }
        acc[key].entries.push(entry);
        acc[key].totalHours += entry.hours;
        return acc;
      }, {});

      setTimesheetEntries(Object.values(groupedEntries));
    } catch (error) {
      console.error('Error fetching timesheet entries:', error.message);
    }
  };

  const handleStatusChange = async (entries, newStatus) => {
    try {
      const { error } = await supabase
        .from('timesheet_entries')
        .update({ status: newStatus })
        .in('id', entries.map(entry => entry.id));

      if (error) throw error;
      alert(`Status updated to ${newStatus} successfully`);
      fetchTimesheetEntries();
    } catch (error) {
      console.error('Error updating status:', error.message);
      alert('Error updating status. Please try again.');
    }
  };

  const changeWeek = (direction) => {
    const newWeekStart = new Date(weekStart);
    newWeekStart.setDate(newWeekStart.getDate() + (direction === 'next' ? 7 : -7));
    setWeekStart(newWeekStart);
  };

  return (
    <>
      <Helmet>
        <title>System Admin Hours Dashboard | fetchConsultant</title>
        <meta name="description" content="Manage and oversee consultant hours across all projects on fetchConsultant. Review, invoice, and process payments for timesheet entries." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col">
        <header className="fetch-header">
          <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
          <h1 className="fetch-page-title">Hours Dashboard</h1>
          <div className="flex items-center">
            <NotificationBell />
          </div>
        </header>
        <main className="fetch-container">
          <div className="fetch-card">
            <div className="flex justify-between items-center mb-4">
              <button onClick={() => changeWeek('prev')} className="fetch-button"><ChevronLeft /></button>
              <h2 className="text-xl font-bold">Week of {weekStart.toLocaleDateString()}</h2>
              <button onClick={() => changeWeek('next')} className="fetch-button"><ChevronRight /></button>
            </div>
            <div className="mb-4">
              <label htmlFor="filter" className="fetch-label">Filter by Status</label>
              <select
                id="filter"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                className="fetch-select"
              >
                <option value="all">All</option>
                <option value="submitted">Submitted</option>
                <option value="approved">Approved</option>
                <option value="invoiced">Invoiced</option>
                <option value="paid">Paid</option>
              </select>
            </div>
            {timesheetEntries.map((weekData, index) => (
              <div key={index} className="mb-6 p-4 border rounded">
                <h3 className="text-lg font-semibold">{weekData.consultant}</h3>
                <p>Job: {weekData.job}</p>
                <p>Client: {weekData.client}</p>
                <p>Total Hours: {weekData.totalHours}</p>
                <p>Status: {weekData.status}</p>
                <table className="w-full mt-2">
                  <thead>
                    <tr>
                      <th className="text-left">Date</th>
                      <th className="text-left">Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    {weekData.entries.map((entry) => (
                      <tr key={entry.id}>
                        <td>{new Date(entry.date).toLocaleDateString()}</td>
                        <td>{entry.hours}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {weekData.status === 'approved' && (
                  <button
                    onClick={() => handleStatusChange(weekData.entries, 'invoiced')}
                    className="fetch-button mt-2"
                  >
                    Mark as Invoiced
                  </button>
                )}
                {weekData.status === 'invoiced' && (
                  <button
                    onClick={() => handleStatusChange(weekData.entries, 'paid')}
                    className="fetch-button mt-2"
                  >
                    Mark as Paid
                  </button>
                )}
              </div>
            ))}
          </div>
        </main>
      </div>
    </>
  );
};

export default SystemAdminHoursDashboard;