// SystemAdminOnboardingRequirements.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { getAnalytics, logEvent } from "firebase/analytics";
import { Helmet } from 'react-helmet';
import { ChevronLeft, Plus, Trash, Upload } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const SystemAdminOnboardingRequirements = () => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('');
  const [requirements, setRequirements] = useState([]);
  const [newRequirement, setNewRequirement] = useState({ name: '', is_mandatory: true, description: '' });
  const [loading, setLoading] = useState(true);
  const [documentFile, setDocumentFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Admin Onboarding Requirements',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchClients();
  }, []);

  useEffect(() => {
    if (selectedClient !== '') {
      fetchRequirements();
    } else {
      fetchGlobalRequirements();
    }
  }, [selectedClient]);

  const fetchClients = async () => {
    try {
      const { data, error } = await supabase
        .from('clients')
        .select('client_id, name')
        .order('name', { ascending: true });

      if (error) throw error;
      setClients(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching clients:', error.message);
      setLoading(false);
    }
  };

  const fetchRequirements = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('onboarding_requirements')
        .select('*')
        .eq('client_id', selectedClient)
        .order('created_at', { ascending: true });

      if (error) throw error;
      setRequirements(data);
    } catch (error) {
      console.error('Error fetching requirements:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchGlobalRequirements = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('onboarding_requirements')
        .select('*')
        .eq('requirement_type', 'global')
        .order('created_at', { ascending: true });

      if (error) throw error;
      setRequirements(data);
    } catch (error) {
      console.error('Error fetching global requirements:', error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddRequirement = async () => {
    if (!newRequirement.name.trim()) {
      alert('Requirement name is required.');
      return;
    }

    try {
      let documentUrl = null;
      if (documentFile) {
        const fileExt = documentFile.name.split('.').pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const folder = selectedClient !== '' ? `${selectedClient}` : 'global';
        const filePath = `${folder}/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('onboarding_documents')
          .upload(filePath, documentFile);

        if (uploadError) throw uploadError;

        const { data: publicUrlData } = await supabase.storage
          .from('onboarding_documents')
          .getPublicUrl(filePath);

        documentUrl = publicUrlData.publicUrl;
      }

      const { data: { user: authUser } } = await supabase.auth.getUser();
      if (!authUser) throw new Error("No authenticated user found");

      const requirementData = {
        client_id: selectedClient !== '' ? selectedClient : null,
        requirement_name: newRequirement.name.trim(),
        is_mandatory: newRequirement.is_mandatory,
        description: newRequirement.description.trim(),
        document_url: documentUrl,
        requirement_type: selectedClient !== '' ? 'client-specific' : 'global',
        created_by: authUser.id
      };

      const { data, error } = await supabase
        .from('onboarding_requirements')
        .insert(requirementData)
        .select();

      if (error) throw error;
      setRequirements([...requirements, data[0]]);
      setNewRequirement({ name: '', is_mandatory: true, description: '' });
      setDocumentFile(null);
    } catch (error) {
      console.error('Error adding requirement:', error.message);
      alert('Error adding requirement. Please try again.');
    }
  };

  const handleDeleteRequirement = async (id) => {
    if (!window.confirm('Are you sure you want to delete this requirement?')) {
      return;
    }

    try {
      const { error } = await supabase
        .from('onboarding_requirements')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setRequirements(requirements.filter(req => req.id !== id));
    } catch (error) {
      console.error('Error deleting requirement:', error.message);
      alert('Error deleting requirement. Please try again.');
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Admin Onboarding Requirements | fetchConsultant</title>
        <meta name="description" content="Manage onboarding requirements for clients on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12">
        <div className="relative py-3 sm:max-w-xl sm:mx-auto">
          <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
            <div className="max-w-md mx-auto">
              <div className="flex items-center space-x-5">
                <ChevronLeft className="h-6 w-6 text-indigo-600 cursor-pointer" onClick={() => navigate(-1)} />
                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                  <h2 className="leading-relaxed">Manage Onboarding Requirements</h2>
                </div>
              </div>
              <div className="divide-y divide-gray-200">
                <div className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                  <div>
                    <label htmlFor="client" className="block text-sm font-medium text-gray-700">Select Client</label>
                    <select
                      id="client"
                      value={selectedClient}
                      onChange={(e) => setSelectedClient(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm"
                    >
                      <option value="">Global Requirements</option>
                      {clients.map((client) => (
                        <option key={client.client_id} value={client.client_id}>{client.name}</option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mt-4">
                      {selectedClient !== '' ? 'Client-Specific Requirements' : 'Global Requirements'}
                    </h3>
                    {requirements.length === 0 ? (
                      <p className="text-gray-500 mt-2">No requirements added yet.</p>
                    ) : (
                      <ul className="mt-3 space-y-3">
                        {requirements.map((req) => (
                          <li key={req.id} className="flex items-start justify-between p-3 bg-gray-50 rounded-lg">
                            <div>
                              <span className="font-medium">{req.requirement_name}</span>
                              <span className={`ml-2 text-sm ${req.is_mandatory ? 'text-red-600' : 'text-gray-500'}`}>
                                ({req.is_mandatory ? 'Required' : 'Optional'})
                              </span>
                              <p className="text-sm text-gray-600 mt-1">{req.description}</p>
                              {req.document_url && (
                                <a
                                  href={req.document_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-indigo-600 hover:text-indigo-800 text-sm mt-1 block"
                                >
                                  View Attached Document
                                </a>
                              )}
                            </div>
                            <button onClick={() => handleDeleteRequirement(req.id)} className="text-red-600 hover:text-red-800">
                              <Trash size={20} />
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mt-4">Add New Requirement</h3>
                    <input
                      type="text"
                      value={newRequirement.name}
                      onChange={(e) => setNewRequirement({ ...newRequirement, name: e.target.value })}
                      placeholder="Requirement name"
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm"
                    />
                    <textarea
                      value={newRequirement.description}
                      onChange={(e) => setNewRequirement({ ...newRequirement, description: e.target.value })}
                      placeholder="Requirement description"
                      className="mt-2 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm"
                    />
                    <div className="mt-2">
                      <label className="inline-flex items-center">
                        <input
                          type="checkbox"
                          checked={newRequirement.is_mandatory}
                          onChange={(e) => setNewRequirement({ ...newRequirement, is_mandatory: e.target.checked })}
                          className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700">Mandatory</span>
                      </label>
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Upload Associated Document (Optional)
                      </label>
                      <input
                        type="file"
                        onChange={(e) => setDocumentFile(e.target.files[0])}
                        className="fetch-input"
                      />
                    </div>
                    <button
                      onClick={handleAddRequirement}
                      className="mt-3 w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      <Plus className="mr-2" size={20} />
                      Add Requirement
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </div>
      </div>    
    </>
  );
};

export default SystemAdminOnboardingRequirements;