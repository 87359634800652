import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, Plus, X } from 'lucide-react';
import { ArrowLongLeftIcon, HomeIcon } from '@heroicons/react/20/solid';
import DatePicker from "react-datepicker";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";


const RequestSecondInterview = () => {
  const [interviewSlots, setInterviewSlots] = useState(['']);
  const [interviewDetails, setInterviewDetails] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { userJobId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Request Second Interview',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchInterviewDetails();
  }, [userJobId]);

  const fetchInterviewDetails = async () => {
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs(title),
          consultant:users!user_jobs_user_id_fkey(name)
        `)
        .eq('user_job_id', userJobId)
        .single();

      if (error) throw error;
      setInterviewDetails(data);
    } catch (error) {
      console.error('Error fetching interview details:', error.message);
    }
  };

  const handleAddSlot = () => {
    if (interviewSlots.length < 3) {
      setInterviewSlots([...interviewSlots, '']);
    }
  };

  const handleRemoveSlot = (index) => {
    const newSlots = interviewSlots.filter((_, i) => i !== index);
    setInterviewSlots(newSlots);
  };

  const handleSlotChange = (index, value) => {
    const newSlots = [...interviewSlots];
    newSlots[index] = value;
    setInterviewSlots(newSlots);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { error } = await supabase
        .from('user_jobs')
        .update({
          status: 'Second Interview Requested',
          second_interview_slots: interviewSlots,
        })
        .eq('user_job_id', userJobId);

      if (error) throw error;

      const analytics = getAnalytics();
      logEvent(analytics, 'second_interview_requested', {
        user_job_id: userJobId,
      });

      alert('Second interview request sent successfully!');
      navigate('/app/client-interviews');
    } catch (error) {
      console.error('Error requesting second interview:', error.message);
      alert('Failed to request second interview. Please try again.');
    }
  };

  return (
    <>
      <Helmet>
        <title>Request Second Interview | fetchConsultant</title>
        <meta name="description" content="Request a second interview with a consultant on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 md:ml-64 flex flex-col">
          <header className="bg-white shadow-md p-4 flex justify-between items-center">
          <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              {/* Use isMenuOpen to conditionally render breadcrumb or back arrow */}
              {isMenuOpen ? (
                <div className="flex items-center cursor-pointer" onClick={() => navigate(-1)}>
                  <ArrowLongLeftIcon className="h-6 w-6 text-gray-500" />
                  <span className="text-gray-500">Back to Applicant Details</span>
                </div>
              ) : (
                <nav className="flex items-center text-sm space-x-2">
                  <HomeIcon className="h-4 w-4 text-gray-500 cursor-pointer" onClick={() => navigate('/app/client-dashboard')} aria-hidden="true" />
                  <span className="text-gray-400">/</span>
                  <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-2)}>Applicants</span>
                  <span className="text-gray-400">/</span>
                  <span className="text-gray-500 cursor-pointer" onClick={() => navigate(-1)}>Applicant Details</span>
                  <span className="text-gray-400">/</span>
                  <span className="text-gray-500">Request Second Interview</span>
                </nav>
              )}
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-1 overflow-y-auto p-4">
            {interviewDetails && (
              <div className="max-w-md mx-auto bg-white rounded-lg shadow-md p-6">
                <h2 className="text-2xl font-bold mb-4">{interviewDetails.job.title}</h2>
                <p className="mb-6">Consultant: {interviewDetails.consultant.name}</p>
                <form onSubmit={handleSubmit} className="space-y-4">
                  <div>
                    <label className="block mb-2 font-semibold">Proposed Interview Slots</label>
                    {interviewSlots.map((slot, index) => (
                        <div key={index} className="mb-4 flex flex-col items-start w-full">
                        <label htmlFor={`slot-${index}`} className="fetch-label mb-2">Slot {index + 1}</label>
                        <div className="w-full flex items-center">
                          <div className="w-full">
                          <DatePicker
                            id={`slot-${index}`}
                            selected={slot ? new Date(slot) : null}
                            onChange={(date) => handleSlotChange(index, date)}
                            showTimeSelect
                            timeIntervals={30}
                            timeFormat="h:mm aa"
                            timeCaption="Time"
                            dateFormat="MMMM d, yyyy h:mm aa"
                            className="fetch-input w-full date-picker-custom"
                            placeholderText="Select date and time"
                            popperClassName="custom-popper date-picker-popper"
                            calendarClassName="custom-calendar"
                            dayClassName={() => "custom-day"}
                            timeClassName={() => "custom-time"}
                            wrapperClassName="date-picker-wrapper"
                          />
                          </div>
                          {index > 0 && (
                            <button
                              type="button"
                              onClick={() => handleRemoveSlot(index)}
                              className="text-red-600 ml-2"
                              aria-label="Remove slot"
                            >
                              <X size={20} />
                            </button>
                          )}
                        </div>
                      </div>
                    ))}
                    {interviewSlots.length < 3 && (
                      <button
                        type="button"
                        onClick={handleAddSlot}
                        className="flex items-center text-brandPrimary mt-2"
                      >
                        <Plus size={20} className="mr-1" />
                        Add Another Slot
                      </button>
                    )}
                  </div>
                  <button type="submit" className="w-full bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300">
                    Send Request
                  </button>
                </form>
              </div>
            )}
          </main>
          </div>
      </div>
    </>
  );
};

export default RequestSecondInterview;