import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from "./NotificationBell";
import Modal from './ui/Modal';
import StatusBanner from './ui/StatusBanner';

const ClientTimesheetDetail = () => {
  const { weekStartDate, consultantId } = useParams(); // Extract parameters
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [consultant, setConsultant] = useState(null);
  const [timesheetRows, setTimesheetRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [weekStart, setWeekStart] = useState(null);
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [timesheetStatus, setTimesheetStatus] = useState('submitted');
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [isWithdrawing, setIsWithdrawing] = useState(false);
  const [rawTimesheetData, setRawTimesheetData] = useState([]);
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
  const analytics = getAnalytics();

  // Helper Functions
  const formatDate = (date) => {
    return date.toISOString().split('T')[0];
  };

  const parseDate = (dateStr) => {
    const [year, month, day] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day);
  };

  const getWeekStart = (date) => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    d.setDate(d.getDate() - d.getDay());
    return d;
  };

  // Fetch user and timesheet data
  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Timesheet Detail',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
  }, []);

  useEffect(() => {
    if (user) {
      fetchTimesheetData();
    }
  }, [user]);

  const fetchUser = async () => {
    try {
      const { data: { user: authUser }, error } = await supabase.auth.getUser();
      if (error) throw error;
      setUser(authUser);
    } catch (error) {
      console.error('Error fetching user:', error);
      setError('Failed to fetch user data');
    }
  };

  const fetchTimesheetData = async () => {
    try {
      setIsLoading(true);

      // Parse the week start date from the URL parameter
      const weekStartDateObj = parseDate(weekStartDate);
      const weekEndDateObj = new Date(weekStartDateObj);
      weekEndDateObj.setDate(weekEndDateObj.getDate() + 6);

      // Format dates for querying
      const formattedWeekStartDate = formatDate(weekStartDateObj);
      const formattedWeekEndDate = formatDate(weekEndDateObj);

      // Fetch timesheet entries with related data, filtered by consultantId and date range
      const { data, error } = await supabase
        .from('timesheet_entries')
        .select(`
          *,
          timesheet_tasks (
            id,
            title,
            is_billable
          ),
          engagements (
            id,
            job:job_id (
              title,
              client:client_id (
                name
              )
            )
          ),
          users:consultant_id (
            user_id,
            name,
            photo
          )
        `)
        .eq('consultant_id', consultantId)
        .gte('date', formattedWeekStartDate)
        .lte('date', formattedWeekEndDate)
        .order('date', { ascending: true });

      if (error) throw error;

      setRawTimesheetData(data); // Save the raw data for later use

      if (data.length > 0) {
        setConsultant(data[0].users);
        setWeekStart(weekStartDateObj);
      } else {
        setError('No timesheet entries found for this consultant and week.');
        setIsLoading(false);
        return;
      }

      const organizedData = organizeTimesheetData(data, weekStartDateObj);
      setTimesheetRows(organizedData);
      
    } catch (error) {
      console.error('Error fetching timesheet data:', error);
      setError('Failed to load timesheet data');
    } finally {
      setIsLoading(false);
    }
  };

  const organizeTimesheetData = (data, weekStartDateObj) => {
    const rows = {};
    const weekDays = 7;

    data.forEach(entry => {
      const rowKey = `${entry.engagement_id}-${entry.task_id}`;
      if (!rows[rowKey]) {
        rows[rowKey] = {
          engagement_id: entry.engagement_id,
          task_id: entry.task_id,
          projectTitle: `${entry.engagements.job.title}`,
          taskTitle: entry.timesheet_tasks.title,
          is_billable: entry.timesheet_tasks.is_billable,
          hours: Array(weekDays).fill('0.00'),
          entryIds: Array(weekDays).fill(null)
        };
      }
      const entryDate = parseDate(entry.date);
      const dayIndex = Math.floor((entryDate - weekStartDateObj) / (1000 * 60 * 60 * 24));
      if (dayIndex >= 0 && dayIndex < weekDays) {
        rows[rowKey].hours[dayIndex] = parseFloat(entry.hours).toFixed(2);
        rows[rowKey].entryIds[dayIndex] = entry.id;
      }
    });
    return Object.values(rows);
  };

  useEffect(() => {
    if (rawTimesheetData.length > 0) {
      const allApproved = rawTimesheetData.every(entry => entry.status === 'approved');
      setTimesheetStatus(allApproved ? 'approved' : 'submitted');
    }
  }, [rawTimesheetData]);
  
  const handleApproveTimesheet = async () => {
    try {
      setIsApproving(true);
      const now = new Date().toISOString();
  
      // Get the engagement details first
      const { data: engagement, error: engagementError } = await supabase
        .from('engagements')
        .select('*')
        .eq('id', rawTimesheetData[0].engagement_id)
        .single();
  
      if (engagementError) throw engagementError;
  
      // Update all timesheet entries
      const entryIds = timesheetRows.flatMap(row => 
        row.entryIds.filter(id => id !== null)
      );
  
      const { error } = await supabase
        .from('timesheet_entries')
        .update({
          status: 'approved',
          approved_at: now,
          approved_by_id: user.user_id,
          is_locked: true
        })
        .in('id', entryIds);
  
      if (error) throw error;
  
      // If auto_invoice is enabled, generate invoice
      if (engagement.auto_invoice) {
        try {
          // Create invoice record
          const { data: invoice, error: invoiceError } = await supabase
            .from('invoices')
            .insert({
              client_id: engagement.client_id,
              engagement_id: engagement.id,
              invoice_number: await generateInvoiceNumber(),
              amount: calculateTotalAmount(rawTimesheetData, engagement.contract_rate),
              status: 'Draft',
              sent_date: now,
              due_date: calculateDueDate(now),
              created_at: now,
              updated_at: now
            })
            .select()
            .single();
  
          if (invoiceError) throw invoiceError;
  
          // Call the send-invoice function
          const response = await fetch(`${supabaseUrl}/functions/v1/send-invoice`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${(await supabase.auth.getSession()).data.session?.access_token}`
            },
            body: JSON.stringify({ invoiceId: invoice.id })
          });
  
          if (!response.ok) throw new Error('Failed to send invoice');
  
          logEvent(analytics, 'invoice_generated_automatically', {
            engagement_id: engagement.id,
            invoice_id: invoice.id
          });
        } catch (invoiceError) {
          console.error('Error generating invoice:', invoiceError);
          // Continue with success flow even if invoice generation fails
          // The invoice can be manually generated later
        }
      }
  
      logEvent(analytics, 'timesheet_approved', {
        consultant_id: consultant.user_id,
        entry_count: entryIds.length,
      });
  
      setApprovalStatus('success');
      setTimeout(() => {
        navigate('/app/client-approve-hours');
      }, 2000);
    } catch (error) {
      console.error('Error approving timesheet:', error);
      setApprovalStatus('error');
    } finally {
      setIsApproving(false);
      setIsApprovalModalOpen(false);
    }
  };
  
  // Helper function to generate invoice number
  const generateInvoiceNumber = async () => {
    const { data, error } = await supabase
      .from('invoices')
      .select('invoice_number')
      .order('created_at', { ascending: false })
      .limit(1);
  
    if (error) throw error;
  
    const lastNumber = data.length > 0 ? 
      parseInt(data[0].invoice_number.replace('INV-', '')) : 
      0;
  
    return `INV-${String(lastNumber + 1).padStart(6, '0')}`;
  };
  
  // Helper function to calculate total amount
  const calculateTotalAmount = (timesheetEntries, rate) => {
    return timesheetEntries.reduce((total, entry) => 
      total + (parseFloat(entry.hours) * rate), 
      0
    );
  };
  
  // Helper function to calculate due date (30 days from sent date)
  const calculateDueDate = (sentDate) => {
    const dueDate = new Date(sentDate);
    dueDate.setDate(dueDate.getDate() + 30);
    return dueDate.toISOString();
  };

  const handleWithdrawApproval = async () => {
    try {
      setIsWithdrawing(true);
      const entryIds = timesheetRows.flatMap(row => 
        row.entryIds.filter(id => id !== null)
      );
  
      const { error } = await supabase
        .from('timesheet_entries')
        .update({
          status: 'submitted',
          approved_at: null,
          is_locked: false
        })
        .in('id', entryIds);
  
      if (error) throw error;
  
      const analytics = getAnalytics();
      logEvent(analytics, 'timesheet_approval_withdrawn', {
        consultant_id: consultant.user_id,
        entry_count: entryIds.length,
      });
  
      setApprovalStatus('success');
      setTimesheetStatus('submitted');
      setTimeout(() => {
        navigate('/app/client-approve-hours');
      }, 2000);
    } catch (error) {
      console.error('Error withdrawing timesheet approval:', error);
      setApprovalStatus('error');
    } finally {
      setIsWithdrawing(false);
      setIsWithdrawModalOpen(false);
    }
  };

  const handleMessageConsultant = () => {
    navigate(`/app/chats`, { state: { newChatUserId: consultant.user_id } });
  };

  // Calculate totals
  const rowTotal = (rowIndex) => {
    const row = timesheetRows[rowIndex];
    return row.hours.reduce((sum, h) => sum + (parseFloat(h) || 0), 0).toFixed(2);
  };

  const columnTotals = () => {
    const totals = Array(7).fill(0);
    timesheetRows.forEach(row => {
      row.hours.forEach((h, i) => {
        totals[i] += parseFloat(h) || 0;
      });
    });
    return totals.map(total => total.toFixed(2));
  };

  const grandTotal = () => {
    return timesheetRows.reduce((sum, row, rowIndex) => 
      sum + parseFloat(rowTotal(rowIndex)), 0
    ).toFixed(2);
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-gray-500">Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <div className="text-red-500">{error}</div>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Review Timesheet | fetchConsultant</title>
        <meta 
          name="description" 
          content="Review and approve consultant timesheet entries on fetchConsultant." 
        />
      </Helmet>

      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger 
          user={user} 
          currentPage="approveHours" 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)} 
        />
        
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold">Review Timesheet</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>

          <main className="flex-1 p-4">
            <div className="max-w-7xl mx-auto">
              <div className="mb-4">
                <StatusBanner
                  status={approvalStatus}
                  message={
                    approvalStatus === 'success'
                      ? 'Timesheet approved successfully'
                      : approvalStatus === 'error'
                      ? 'Error approving timesheet'
                      : ''
                  }
                />
              </div>

              {consultant && (
                <div className="bg-white p-4 rounded-lg shadow-sm mb-4">
                  <div className="flex items-center">
                    <img 
                      src={consultant.photo || '/default-avatar.png'} 
                      alt={consultant.name} 
                      className="h-12 w-12 rounded-full mr-4"
                    />
                    <div>
                      <h2 className="text-lg text-left font-semibold">{consultant.name}</h2>
                      <p className="text-gray-500">
                        Week of {weekStart?.toLocaleDateString()}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="bg-white rounded-lg shadow-sm overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="px-2 text-left">Project/Task</th>
                      {[...Array(7)].map((_, i) => {
                        const date = new Date(weekStart);
                        date.setDate(date.getDate() + i);
                        return (
                          <th key={i} className="text-center">
                            <div>{['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][i]}</div>
                            <div className="text-sm text-gray-500">
                              {date.toLocaleDateString(undefined, { month: 'numeric', day: 'numeric' })}
                            </div>
                          </th>
                        );
                      })}
                      <th className="text-right">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timesheetRows.map((row, rowIndex) => (
                      <tr key={rowIndex} className="border-t">
                        <td className="py-2 px-2 text-left">
                          <div className="font-medium">{row.projectTitle}</div>
                          <div className="text-sm text-gray-500">
                            {row.taskTitle}
                            {!row.is_billable && (
                              <span className="ml-2 text-xs text-red-500">(Non-billable)</span>
                            )}
                          </div>
                        </td>
                        {row.hours.map((hours, dayIndex) => (
                          <td key={dayIndex} className="px-2">
                            <input
                              type=""
                              value={hours}
                              className="w-20 text-center fetch-input bg-gray-50"
                              disabled={true}
                            />
                          </td>
                        ))}
                        <td className="font-medium text-right pr-4">{rowTotal(rowIndex)}</td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="border-t text-left font-bold">
                      <td class="px-2"> Total</td>
                      {columnTotals().map((total, index) => (
                        <td key={index} className="text-center">{total}</td>
                      ))}
                      <td className="text-right pr-4">{grandTotal()}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="mt-6 flex justify-end space-x-4">
                <button
                    onClick={handleMessageConsultant}
                    className="bg-gray-500 hover:bg-gray-600 text-white px-6 py-2 rounded"
                >
                    Message {consultant?.name}
                </button>
                {timesheetStatus === 'approved' ? (
                    <button
                    onClick={() => setIsWithdrawModalOpen(true)}
                    className="bg-red-600 hover:bg-red-700 text-white px-6 py-2 rounded"
                    >
                    Withdraw Approval
                    </button>
                ) : (
                    <button
                    onClick={() => setIsApprovalModalOpen(true)}
                    className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded"
                    >
                    Approve Timesheet
                    </button>
                )}
                </div>
            </div>
          </main>
        </div>
      </div>

      <Modal
        isOpen={isApprovalModalOpen}
        onClose={() => setIsApprovalModalOpen(false)}
        title="Confirm Timesheet Approval"
      >
        <div className="p-6">
          <p className="mb-4">Are you sure you want to approve this timesheet?</p>
          <div className="flex justify-end space-x-4">
            <button
              onClick={() => setIsApprovalModalOpen(false)}
              className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleApproveTimesheet}
              className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700"
              disabled={isApproving}
            >
              {isApproving ? 'Approving...' : 'Approve'}
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={isWithdrawModalOpen}
        onClose={() => setIsWithdrawModalOpen(false)}
        title="Confirm Withdrawal of Approval"
        >
        <div className="p-6">
            <p className="mb-4">Are you sure you want to withdraw approval for this timesheet? This will allow the consultant to make changes.</p>
            <div className="flex justify-end space-x-4">
            <button
                onClick={() => setIsWithdrawModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50"
            >
                Cancel
            </button>
            <button
                onClick={handleWithdrawApproval}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                disabled={isWithdrawing}
            >
                {isWithdrawing ? 'Withdrawing...' : 'Withdraw Approval'}
            </button>
            </div>
        </div>
    </Modal>
    </>
  );
};

export default ClientTimesheetDetail;