import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu } from 'lucide-react';
import React, { useRef, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { UserContext } from '../UserContext';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';

const ClientAddJobTermsAndConditions = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [job, setJob] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const scrollRef = useRef(null);
  const navigate = useNavigate();
  const { jobId } = useParams();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Client Terms of Service',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchJob();
  }, [jobId]);

  const fetchJob = async () => {
    if (!jobId) return;

    try {
      const { data, error } = await supabase
        .from('jobs')
        .select('*')
        .eq('job_id', jobId)
        .single();

      if (error) throw error;
      setJob(data);
    } catch (error) {
      console.error('Error fetching job:', error);
      alert('Error fetching job details');
    }
  };

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setIsScrolledToBottom(true);
    }
  };

  const handleAccept = async () => {
    if (!job) return;

    try {
      const { error } = await supabase
        .from('jobs')
        .update({
          status: 'Active',
          terms_and_conditions: true,
          posted_at: new Date().toISOString(),
        })
        .eq('job_id', jobId);

      if (error) throw error;

      const analytics = getAnalytics();
      logEvent(analytics, 'terms_accepted', {
        job_id: jobId,
      });

      alert('Terms and conditions accepted. Job posted successfully!');
      navigate('/app/client-dashboard');
    } catch (error) {
      console.error('Error accepting terms and conditions:', error);
      alert('Error accepting terms and conditions');
    }
  };

  return (
    <>
      <Helmet>
        <title>Client Terms of Service | fetchConsultant</title>
        <meta name="description" content="Review and accept the client terms of service for posting jobs on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="terms" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate(-1)} />
              <h1 className="text-xl font-bold ml-4">Client Terms of Service</h1>
            </div>
            <NotificationBell />
          </header>
          
          <main className="flex-grow container text-left mx-auto px-4 py-8">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <div
                ref={scrollRef}
                onScroll={handleScroll}
                className="max-h-[60vh] overflow-y-auto mb-6"
              >
                <p className="text-gray-600 mb-4">Last Updated: October 16, 2024</p>

                <h3 className="text-xl font-semibold mb-4">1. Acceptance of Client Terms</h3>
              <p className="text-gray-600 mb-4">
                These Client Terms of Service (“Client Terms”) supplement the General Terms of Service and specifically govern your use of the fetchConsultant platform (“Platform”) to post contracts or job opportunities. By posting any contract or job, you agree to these Client Terms. If you do not agree, you may not post any opportunities.
              </p>

              <h3 className="text-xl font-semibold mb-4">2. Master Services Agreement (MSA)</h3>
              <p className="text-gray-600 mb-4">
                <strong>Existing MSA:</strong> If you have previously entered into a Master Services Agreement with fetchConsultant, the terms of that agreement shall apply to any contracts or jobs posted on the Platform.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>No Existing MSA:</strong> If you do not have an MSA in place, you agree that before any consultant engagement begins, you will enter into a Master Services Agreement with fetchConsultant. No services will commence until the MSA is executed.
              </p>

              <h3 className="text-xl font-semibold mb-4">3. Posting Contracts/Jobs</h3>
              <p className="text-gray-600 mb-4">
                <strong>Accuracy:</strong> You agree to provide complete, truthful, and accurate information regarding the job or contract you are posting. This includes, but is not limited to, job descriptions, timelines, deliverables, and compensation details.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Compliance:</strong> You agree that any job or contract posted on the Platform will comply with all applicable laws, regulations, and ethical standards, including but not limited to labor laws, non-discrimination policies, and data privacy requirements.
              </p>

              <h3 className="text-xl font-semibold mb-4">4. Fees and Payment</h3>
              <p className="text-gray-600 mb-4">
                <strong>Fees:</strong> You agree to pay any applicable fees related to posting jobs or contracts as outlined in the forthcoming MSA or displayed on the Platform. The specific fee schedule will be based on your agreement with fetchConsultant.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Payment Terms:</strong> All payments will be due according to the schedule specified in the MSA or as separately agreed upon in writing. Failure to comply with payment terms may result in suspension of services or termination of your account.
              </p>

              <h3 className="text-xl font-semibold mb-4">5. Confidentiality</h3>
              <p className="text-gray-600 mb-4">
                <strong>User Information:</strong> You agree to keep confidential any information regarding consultants, including but not limited to profiles, personal details, and proprietary information, obtained through the Platform. This information is to be used solely for the purpose of evaluating consultant suitability for your posted contracts.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Platform Confidentiality:</strong> You also agree not to disclose any non-public information about the Platform, including its features, functionalities, or business processes, to third parties without the prior written consent of fetchConsultant.
              </p>

              <h3 className="text-xl font-semibold mb-4">6. Prohibited Actions</h3>
              <p className="text-gray-600 mb-4">
                <strong>Off-Platform Engagement:</strong> You agree that any engagement or contract that results from a posting on the Platform will be conducted exclusively through the Platform. Engaging a consultant off the Platform is strictly prohibited and may result in termination of your account. Additionally, fetchConsultant reserves the right to seek damages for any such violations.
              </p>
              <p className="text-gray-600 mb-4">
                <strong>Software Abuse:</strong> You agree not to reverse-engineer, tamper with, or otherwise attempt to interfere with the software, code, or functionality of the Platform in any way.
              </p>

              <h3 className="text-xl font-semibold mb-4">7. Privacy</h3>
              <p className="text-gray-600 mb-4">
                <strong>Personal Information:</strong> Any personal information collected from you or consultants during the use of the Platform will be handled in accordance with fetchConsultant’s Privacy Policy. You agree to review and abide by the Privacy Policy as part of these Terms.
              </p>

              <h3 className="text-xl font-semibold mb-4">8. Termination</h3>
              <p className="text-gray-600 mb-4">
                <strong>Breach of Terms:</strong> fetchConsultant reserves the right to terminate your access to the Platform or remove any posted job or contract at its sole discretion if you violate these Client Terms or engage in conduct that is harmful to the Platform or its users.
              </p>

              <h3 className="text-xl font-semibold mb-4">9. Governing Law</h3>
              <p className="text-gray-600 mb-4">
                These Client Terms are governed by and construed in accordance with the laws of the state of Texas, without regard to its conflict of law provisions.
              </p>

              <h3 className="text-xl font-semibold mb-4">10. Acceptance</h3>
              <p className="text-gray-600 mb-4">
                By clicking “Accept” or by posting a contract or job on the Platform, you acknowledge that you have read, understood, and agree to be bound by these Client Terms. Your continued use of the Platform after any updates to these Terms constitutes acceptance of such changes.
              </p>
              </div>

              <button
                className={`fetch-button w-full ${!isScrolledToBottom ? 'opacity-50 cursor-not-allowed' : ''}`}
                onClick={handleAccept}
                disabled={!isScrolledToBottom}
              >
                Accept
              </button>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientAddJobTermsAndConditions;