import { getAnalytics, logEvent } from "firebase/analytics";
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams } from 'react-router-dom';

const ReviewBenefits = () => {
  const { token } = useParams();

  React.useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Review Benefits',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Join fetchConsultant to Submit Your Review | fetchConsultant</title>
        <meta name="description" content="Learn about the benefits of joining fetchConsultant and how you can submit your review after creating an account." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center p-4">
        <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
          <h1 className="text-2xl font-bold mb-4">Join fetchConsultant to Submit Your Review</h1>
          <p className="mb-4">By creating a fetchConsultant account, you'll be able to:</p>
          <ul className="list-disc pl-5 mb-6">
            <li>Submit reviews for Epic consultants</li>
            <li>Access a network of top healthcare IT professionals</li>
            <li>Stay updated on the latest Epic consulting opportunities</li>
            <li>Manage your professional profile and credentials</li>
          </ul>
          <p className="mb-6">Once you've created your account, you'll be able to complete the review you were invited to submit.</p>
          <Link to={`/app/auth?review_token=${token}`} className="block w-full bg-brandPrimary text-white py-2 px-4 rounded text-center hover:bg-brandHover transition duration-300">
            Create Your Account
          </Link>
        </div>
      </div>
    </>
  );
};

export default ReviewBenefits;