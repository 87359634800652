import React from 'react';
import { X, Star } from 'lucide-react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

const NoteModal = ({ note, onClose }) => {
  if (!note) return null;

  const content = note.interview?.ai_summary || note.content;
  const notewriterName = note.interview?.ai_summary ? 'AI Summary' : note.notewriter?.name;
  
  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50" onClick={onClose}>
      <div className="relative bg-white w-full max-w-xl mx-auto p-5 border shadow-lg rounded-md" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-gray-500">
          <X size={24} />
        </button>
        <div className="mt-3">
          <div className="mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {notewriterName}
            </h3>
            <p className="text-sm text-gray-500">{new Date(note.created_at).toLocaleDateString()}</p>
            {note.feedback_score && (
              <div className="mt-2">
                <span className="text-sm text-gray-900">Rating:</span>
                <div className="flex mt-1">
                  {[1, 2, 3, 4, 5].map((star) => (
                    <Star
                      key={star}
                      size={16}
                      className={star <= note.feedback_score ? 'text-yellow-400' : 'text-gray-300'}
                      fill={star <= note.feedback_score ? 'currentColor' : 'none'}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="mt-2 max-h-96 overflow-y-auto">
            <ReactQuill
              value={content}
              readOnly={true}
              theme="bubble"
              modules={{ toolbar: false }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoteModal;