import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Info, Upload } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const ClientOnboardingPersonal = () => {
  const [formData, setFormData] = useState({
    name: '',
    work_email: '',
    phone: '',
  });
  const [photo, setPhoto] = useState(null);
  const [photoName, setPhotoName] = useState('');
  const [originalWorkEmail, setOriginalWorkEmail] = useState('');
  const [formValid, setFormValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showVerificationMessage, setShowVerificationMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isEditMode = location.state?.isEditMode || false;
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isEditMode ? 'Edit Profile - Personal' : 'Client Onboarding - Personal',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
  }, [isEditMode]);

  const formatPhoneNumber = (value) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const [userData, clientsData] = await Promise.all([
        supabase
          .from('users')
          .select('name, work_email, phone, login_email, photo')
          .eq('user_id', user.id)
          .single(),
        supabase
          .from('clients')
          .select('client_id, name, email_domain1, email_domain2')
      ]);

      if (userData.error) throw userData.error;
      if (clientsData.error) throw clientsData.error;

      const loginEmailDomain = userData.data.login_email.split('@')[1];
      const matchingClient = clientsData.data.find(client =>
        client.email_domain1 === loginEmailDomain || client.email_domain2 === loginEmailDomain
      );
  
      setFormData({
        name: userData.data.name || '',
        work_email: matchingClient ? userData.data.login_email : (userData.data.work_email || ''),
        phone: formatPhoneNumber(userData.data.phone ? userData.data.phone.toString() : '') || '',
      });
      setPhoto(userData.data.photo || null);
      setPhotoName(userData.data.photo ? userData.data.photo.split('/').pop() : '');
      setOriginalWorkEmail(userData.data.work_email || '');
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  useEffect(() => {
    const isFormValid = (formData.name || '').trim() !== '' &&
                        (formData.work_email || '').trim() !== '' &&
                        String(formData.phone || '').trim() !== '';
  
    setFormValid(isFormValid);
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'phone') {
      const formattedPhone = formatPhoneNumber(value);
      setFormData(prevData => ({
        ...prevData,
        [name]: formattedPhone
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const unformatPhoneNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check file type
      if (!['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
        alert('Please upload a valid image file (JPEG, PNG, or GIF)');
        return;
      }
      // Check file size (10MB limit)
      if (file.size > 10 * 1024 * 1024) {
        alert('File size should not exceed 10MB');
        return;
      }
      setPhoto(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const phoneNumber = unformatPhoneNumber(formData.phone);
      const phoneNumberAsNumber = phoneNumber ? parseInt(phoneNumber, 10) : null;

      let photoUrl = photo;
      if (photo instanceof File) {
        const fileExt = photo.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `${user.id}/${fileName}`;

        const { data, error } = await supabase.storage
          .from('photos')
          .upload(filePath, photo);
        
        if (error) throw error;
        
        const { data: { publicUrl }, error: urlError } = supabase.storage
          .from('photos')
          .getPublicUrl(filePath);

        if (urlError) throw urlError;
        photoUrl = publicUrl;
      }

      let updateData = {
        name: formData.name,
        work_email: formData.work_email,
        phone: phoneNumberAsNumber,
        photo: photoUrl,
        last_updated_at: new Date().toISOString(),
      };

      if (isEditMode && formData.work_email !== originalWorkEmail) {
        updateData.user_type_id = 3;
      }
  
      const { error } = await supabase
        .from('users')
        .update(updateData)
        .eq('user_id', user.id);
  
      if (error) throw error;
  
      navigate('/app/client-onboarding-professional', { state: { isEditMode } });
    } catch (error) {
      console.error('Error updating user info:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <Helmet>
        <title>{isEditMode ? 'Edit Profile - Personal Information' : 'Client Onboarding - Personal Information'} | fetchConsultant</title>
        <meta name="description" content={isEditMode ? "Update your personal profile as a healthcare organization on fetchConsultant." : "Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects."} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Client Onboarding - Personal Information | fetchConsultant" />
        <meta property="og:description" content="Complete your personal profile as a healthcare organization on fetchConsultant. Connect with top Epic consultants for your healthcare IT projects." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/client-onboarding-personal" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      
      {/* Split screen */}
      <div className="flex flex-col md:flex-row w-full max-w-7xl shadow-lg">
  
        {/* Left section */}
        <div className="bg-white p-4 sm:p-8 w-full md:w-1/2 flex flex-col justify-start sm:justify-center items-center min-h-screen sm:min-h-0 overflow-y-auto">
          <header className="fetch-header w-full">
            <ChevronLeft className="fetch-back-button" onClick={() => navigate(-1)} />
            <h1 className="fetch-page-title">{isEditMode ? 'Edit Profile' : 'Client Onboarding'}</h1>
          </header>
  
          <main className="fetch-container w-full">
            <div className="fetch-card">
              {!isEditMode && (
                <div className="mb-6 text-center">
                  <p className="fetch-secondary-text">Step 1 of 2</p>
                  <div className="fetch-progress-bar">
                    <div className="fetch-progress-bar-fill" style={{ width: '50%' }}></div>
                  </div>
                </div>
              )}
              {/* Form area (unchanged) */}
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="name" className="fetch-label">Name<span className="fetch-required">*</span></label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                    className="fetch-input"
                    placeholder="Full Name"
                  />
                </div>
                <div>
                  <label htmlFor="photo" className="fetch-label flex items-center relative group">
                    {photoName ? 'Update Photo' : 'Upload Photo'}
                    <Info
                        className="ml-2 text-blue-500 cursor-pointer"
                        size={18}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => setShowTooltip(!showTooltip)}
                      />
                      {showTooltip && (
                        <div className="tooltip">
                          Your photo is only used when chatting with other users.
                        </div>
                      )}
                  </label>
                  <div className="mt-1 flex flex-col items-start">
                    <input
                      type="file"
                      id="photo"
                      accept="image/*"
                      onChange={handlePhotoUpload}
                      className="hidden"
                    />
                    <label
                      htmlFor="photo"
                      className="file-input-button"
                    >
                      <Upload className="w-5 h-5 mr-2" />
                      {photoName ? 'Update Photo' : 'Upload Photo'}
                    </label>
                    {(photo instanceof File ? photo.name : photoName) && (
                      <span className="text-sm text-gray-600 mt-2">
                        {photo instanceof File ? photo.name : photoName}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                <label
                  htmlFor="work_email" className="fetch-label flex items-center relative group">
                  Employer Email<span className="fetch-required">*</span>
                  <Info
                    className="ml-2 text-blue-500 cursor-pointer"
                    size={18}
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                    onClick={() => setShowTooltip(!showTooltip)}
                  />
                  {showTooltip && (
                    <div className="tooltip">
                      This is needed to verify your employer.
                    </div>
                  )}
                </label>
              </div>
              <input
                type="email"
                id="work_email"
                name="work_email"
                value={formData.work_email}
                onChange={handleInputChange}
                required
                className="fetch-input mt-2"
                placeholder="Email address"
              />
                <div>
                  <label htmlFor="phone" className="fetch-label">Phone Number<span className="fetch-required">*</span></label>
                  <input
                    type="tel"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                    placeholder="(###) ###-####"
                    maxLength="14"
                    required
                    className="fetch-input"
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className={`fetch-button ${!formValid || isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={!formValid || isLoading}
                  >
                    {isLoading ? 'Processing...' : 'Next'}
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
  
        {/* Right section */}
        <div className="bg-brandPrimary text-white p-10 w-full md:w-1/2 flex flex-col justify-between overflow-y-auto">
              <div className="flex flex-col mt-2 justify-center flex-grow">
                <h2 className="text-center text-3xl mt-1 font-extrabold">Why fetchConsultant</h2>
              </div>
              <div className="flex flex-col justify-center flex-grow p-8 pt-16"> {/* Add padding to account for the absolute title */}
                <div className="flex justify-center">
                  <ul className="space-y-6 flex flex-col">
                      <li>
                        <div className="flex items-start">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-.01 2a5 5 0 10-4-2.9 4.978 4.978 0 003.5-1.1 5.024 5.024 0 002.5 1.4 5 5 0 104.5-6.9A5 5 0 0012 18z" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Transparent, Fair Pricing</h3>
                    </div>
                    <p className="text-left ml-10">One rate with a simple 9% platform fee. No hidden costs, no inflated rates. Fair for everyone.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Real-Time Opportunities</h3>
                    </div>
                    <p className="text-left ml-10">Instant notifications for new jobs and applicants. Fill positions in days, not weeks.</p>
                  </li>
                  <li>
                    <div className="flex items-start mt-2">
                      <svg className="h-6 w-6 mr-4 flex-shrink-0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12v4m0-4v-2a4 4 0 00-8 0v2a2 2 0 100 4v1m0-5v-2a4 4 0 018 0v2" />
                      </svg>
                      <h3 className="text-lg text-left font-semibold">Streamlined Contracting Process</h3>
                    </div>
                    <p className="text-left ml-10">From job posting to Zoom interviews, our integrated platform accelerates every step of hiring.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default ClientOnboardingPersonal;