import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { UserContext } from '../UserContext';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';

const SolicitReview = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [reviewType, setReviewType] = useState('peer');
  const [selectedUserJob, setSelectedUserJob] = useState('');
  const [userJobs, setUserJobs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pendingReviews, setPendingReviews] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Solicit Review',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchPendingReviews();
    fetchUserJobs();
  }, [user]);

  const fetchPendingReviews = async () => {
    if (!user) return;
    try {
      const { data, error } = await supabase
        .from('review_tokens')
        .select('*, user_jobs(job:jobs(title))')
        .eq('consultant_id', user.user_id)
        .eq('status', 'pending')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setPendingReviews(data);
    } catch (error) {
      console.error('Error fetching pending reviews:', error.message);
    }
  };

  const fetchUserJobs = async () => {
    if (!user) return;
    try {
      const { data, error } = await supabase
        .from('user_jobs')
        .select('user_job_id, job:jobs(job_id, title)')
        .eq('user_id', user.user_id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUserJobs(data);
    } catch (error) {
      console.error('Error fetching user jobs:', error.message);
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      if (!email && !phone) {
        throw new Error("Please provide either an email or a phone number");
      }
    
      if (!user) throw new Error("No authenticated user found");
    
      const cleanPhone = phone ? phone.replace(/[^\d]/g, '') : '';

      // Check if the reviewer exists
      const { data: existingUser, error: userError } = await supabase
        .from('users')
        .select('user_id')
        .or(`login_email.eq.${email},work_email.eq.${email}${cleanPhone ? `,phone.eq.${cleanPhone}` : ''}`)
        .single();
    
      if (userError && userError.code !== 'PGRST116') {
        throw userError;
      }
    
      console.log('Existing user check result:', existingUser, userError);
    
      if (existingUser) {
        // User exists - create only review_tokens and send the review-specific notification
        const { data, error } = await supabase.functions.invoke('send-review-request', {
          body: {
            reviewerEmail: email,
            reviewerPhone: cleanPhone,
            reviewType,
            consultantId: user.user_id,
            senderName: user.name,
            isNewUser: false,  // Reviewer exists
            reviewedUserJob: selectedUserJob || null
          }
        });
    
        if (error) throw error;
    
        console.log('Review request response:', data);
    
      } else {
        // User does not exist - create review_tokens, referral_tokens, referrals, and send the referral-specific notification
        const { data, error } = await supabase.functions.invoke('send-review-request', {
          body: {
            reviewerEmail: email,
            reviewerPhone: cleanPhone,
            reviewType,
            consultantId: user.user_id,
            senderName: user.name,
            isNewUser: true,  // Reviewer does not exist
            referredEmail: email,
            referredPhone: phone ? parseInt(phone, 10) : null,
            referralType: 'consultant',
            referrerId: user.user_id,
            reviewedUserJob: selectedUserJob || null
          }
        });
    
        if (error) throw error;
    
        console.log('Referral creation for new user:', data);
      }
    
      setShowPopup(true);
      fetchPendingReviews();
    } catch (error) {
      console.error('Error soliciting review:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestAnother = () => {
    setEmail('');
    setPhone('');
    setReviewType('peer');
    setSelectedUserJob('');
    setShowPopup(false);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>Solicit Review | fetchConsultant</title>
        <meta name="description" content="How good of an Epic consultant are you? Solicit a review from a manager or peer on fetchConsultant." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Solicit Review | fetchConsultant" />
        <meta property="og:description" content="How good of an Epic consultant are you? Solicit a review from a manager or peer on fetchConsultant." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/solicit-review" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        <ConsultantHamburger user={user} currentPage="solicit-review" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Solicit Review</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card mb-8">
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              <h2 className="text-2xl font-bold mb-4">Request New Review</h2>
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="email" className="fetch-label">Reviewer's Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="fetch-input"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="fetch-label">Reviewer's Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    //the below i can change back to value={phone} when i udpate user.phone as well
                    value={formatPhoneNumber(phone)}
                    onChange={handlePhoneChange}
                    placeholder="(###) ###-####"
                    className="fetch-input"
                  />
                </div>
                <div>
                  <label htmlFor="reviewType" className="fetch-label">Review Type</label>
                  <select
                    id="reviewType"
                    value={reviewType}
                    onChange={(e) => setReviewType(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="peer">Peer</option>
                    <option value="manager">Manager</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="userJob" className="fetch-label">Associated Job (Optional)</label>
                  <select
                    id="userJob"
                    value={selectedUserJob}
                    onChange={(e) => setSelectedUserJob(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="">Select a job</option>
                    {userJobs.map((userJob) => (
                      <option key={userJob.user_job_id} value={userJob.user_job_id}>
                        {userJob.job.title}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="fetch-button" disabled={isLoading || (!email && !phone)}>
                  {isLoading ? 'Sending...' : 'Send Review Request'}
                </button>
              </form>
            </div>
  
            {pendingReviews.map((review) => (
              <div key={review.id} className="fetch-job-card mb-4">
                <div className="flex flex-col space-y-1">
                  <div className="flex justify-between items-center">
                    <h3 className="fetch-job-title text-xl font-semibold mb-0">{review.review_type === 'peer' ? 'Peer Review' : 'Manager Review'}</h3>
                    <span>Requested: {new Date(review.created_at).toLocaleDateString()}</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-gray-600">
                    <span className="font-semibold">{review.reviewer_email || review.reviewer_phone}</span>
                  </div>
                  {review.user_jobs && review.user_jobs.job && (
                    <div className="text-sm text-gray-600">
                      <span>Job: {review.user_jobs.job.title}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </main>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Review Request Sent</h2>
            <p className="mb-4">
              Your review request has been submitted successfully. The reviewer will receive a notification with instructions to submit their review.
            </p>
            <div className="flex justify-between">
              <button
                onClick={handleRequestAnother}
                className="fetch-button bg-brandPrimary text-white"
              >
                Request Another Review
              </button>
              <button
                onClick={() => navigate('/app/reviews')}
                className="fetch-button bg-gray-300 text-gray-800"
              >
                Go to Reviews
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SolicitReview;