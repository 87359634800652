import { getAnalytics, logEvent } from "firebase/analytics";
import React from 'react';
import { MessageCircle, X, Star } from 'lucide-react';

const ReviewModal = ({ review, onClose, onStartChat }) => {
    if (!review) return null;
  
    const handleStartChat = () => {
      const analytics = getAnalytics();
      logEvent(analytics, 'start_chat_from_review', {
        reviewer_id: review.reviewer.user_id,
        review_id: review.review_id
      });
      onStartChat(review.reviewer.user_id);
    };
  
    return (
      <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white rounded-lg p-6 w-full max-w-lg relative">
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-500"
          >
            <X size={24} />
          </button>
  
          <h2 className="text-xl font-bold mb-4">Review by {review.reviewer.name}</h2>
          <div className="flex items-center mb-4">
            {[1, 2, 3, 4, 5].map((star) => (
              <Star
                key={star}
                size={20}
                className={star <= review.rating ? 'text-yellow-400' : 'text-gray-300'}
                fill={star <= review.rating ? 'currentColor' : 'none'}
              />
            ))}
          </div>
          
          <p className="text-left text-gray-700 mb-4">{review.review_text}</p>
          
          {review.share_contact_info && (
            <div className="border-t pt-4 mt-4">
              <h3 className="text-lg font-semibold mb-2">Contact Information</h3>
              <div className="space-y-2 mb-4">
                {review.reviewer.work_email && (
                  <p className="text-sm">
                    <span className="font-medium">Email:</span>{' '}
                    <a 
                      href={`mailto:${review.reviewer.work_email}`}
                      className="text-brandPrimary hover:underline"
                    >
                      {review.reviewer.work_email}
                    </a>
                  </p>
                )}
                {review.reviewer.phone && (
                  <p className="text-sm">
                    <span className="font-medium">Phone:</span>{' '}
                    <a 
                      href={`tel:${review.reviewer.phone}`}
                      className="text-brandPrimary hover:underline"
                    >
                      {review.reviewer.phone}
                    </a>
                  </p>
                )}
              </div>
              <button
                onClick={handleStartChat}
                className="flex items-center justify-center w-full px-4 py-2 bg-brandPrimary text-white rounded-md hover:bg-brandHover transition duration-300"
              >
                <MessageCircle size={20} className="mr-2" />
                Message Reviewer
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };

  export default ReviewModal;