import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, Info } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import NotificationBell from './NotificationBell';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ClientOfferContract = () => {
  const { userJobId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [applicant, setApplicant] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  
  // Get extension data from navigation state
  const isExtension = location.state?.isExtension || false;
  const existingEngagement = location.state?.existingEngagement;

  const [formData, setFormData] = useState({
    contract_rate: '',
    start_date: '',
    end_date: '',
    job_title: '',
    job_description: ''
  });
  const modules = {
    toolbar: [
      [{ list: 'bullet' }], // Enable bullet points
    ],
  };
  
  const formats = [
    'list', // Allow bullet point formatting
  ];

  const addOneDay = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: isExtension ? 'Extend Contract' : 'Offer Contract',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUserData();
    if (isExtension && existingEngagement) {
      // Pre-fill form with existing engagement data for extensions
      setFormData({
        contract_rate: existingEngagement.contract_rate,
        start_date: addOneDay(existingEngagement.end_date),
        end_date: '',
        job_title: existingEngagement.job_title || existingEngagement.job?.title,
        job_description: existingEngagement.job_description
      });
      // Set applicant data from existing engagement
      setApplicant({
        user_id: existingEngagement.consultant_id,
        job: {
          ...existingEngagement.job,
          client_id: existingEngagement.client_id
        },
        users: { name: existingEngagement.consultant_name }
      });
      setLoading(false);
    } else {
      fetchApplicantDetails();
    }
  }, [isExtension, existingEngagement]);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const fetchApplicantDetails = async () => {
    if (isExtension) return; // Skip for extensions as we already have the data
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          users!user_jobs_user_id_fkey (
            name,
            login_email
          ),
          job:job_id (
            title,
            description,
            client_id,
            duration,
            created_by
          )
        `)
        .eq('user_job_id', userJobId)
        .single();
  
      if (error) throw error;
      setApplicant(data);
      setFormData(prevData => ({
        ...prevData,
        contract_rate: data.consultant_rate,
        start_date: data.available_start_date,
        job_title: data.job.title,
        job_description: data.job.description
      }));
    } catch (error) {
      console.error('Error fetching applicant details:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleDescriptionChange = (content) => {
    setFormData(prevData => ({
      ...prevData,
      job_description: content
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Fetch client's MSA status
      const { data: clientData, error: clientError } = await supabase
        .from('clients')
        .select('msa')
        .eq('client_id', user.employer)
        .single();
  
      if (clientError || !clientData) {
        alert('Error fetching client data. Please try again.');
        return;
      }
  
      if (clientData.msa !== 'Complete') {
        alert('Please complete the MSA before offering a contract.');
        return;
      }
  
      const engagementData = {
        user_job_id: userJobId,
        consultant_id: applicant.user_id,
        client_id: isExtension ? existingEngagement.client_id : applicant.job.client_id,
        job_id: isExtension ? existingEngagement.job.job_id : applicant.job_id,
        contract_rate: formData.contract_rate,
        start_date: formData.start_date,
        end_date: formData.end_date,
        status: 'Offered',
        job_title: formData.job_title,
        job_description: formData.job_description,
        decision_maker_id: applicant.job.created_by
      };
  
      if (isExtension) {
        engagementData.contract_extension = true;
        engagementData.parent_engagement_id = existingEngagement.id;
      }
  
      const { data: newEngagement, error: engagementError } = await supabase
        .from('engagements')
        .insert(engagementData)
        .select()
        .single();
  
      if (engagementError) throw engagementError;
  
      // Update user_jobs status if not an extension
      if (!isExtension) {
        await supabase
          .from('user_jobs')
          .update({ status: 'Contract Offered' })
          .eq('user_job_id', userJobId);
      }
  
      const { data: { session } } = await supabase.auth.getSession();
      
      // Generate SOW
      const sowResponse = await fetch(`${process.env.REACT_APP_SUPABASE_FUNCTIONS_URL}/generateSOW`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          engagementId: newEngagement.id,
          isExtension
        }),
      });
  
      const sowResult = await sowResponse.json();
      if (!sowResult.success || !sowResult.documentId) {
        throw new Error(sowResult.error || 'Failed to generate SOW.');
      }
  
      // Get signing link for the decision maker
      const signingResponse = await fetch(`${process.env.REACT_APP_SUPABASE_FUNCTIONS_URL}/getSigningLink`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session?.access_token}`
        },
        body: JSON.stringify({
          documentId: sowResult.documentId,
          recipientEmail: user.work_email,
          recipientId: 'Customer'
        }),
      });
  
      const signingResult = await signingResponse.json();
      if (!signingResult.success || !signingResult.signingLink) {
        throw new Error('Failed to get signing link');
      }
  
      const analytics = getAnalytics();
      logEvent(analytics, isExtension ? 'contract_extension_offered' : 'contract_offered', {
        engagement_id: newEngagement.id,
        is_extension: isExtension
      });
  
      // Redirect to signing page
      window.location.href = signingResult.signingLink;
  
    } catch (error) {
      console.error('Error sending contract offer:', error);
      alert('Failed to send contract offer. Please try again.');
    }
  };

  if (loading) return <div>Loading...</div>;
  if (!applicant) return <div>Applicant not found</div>;

  return (
    <>
      <Helmet>
        <title>{isExtension ? 'Extend Contract' : 'Offer Contract'} | fetchConsultant</title>
        <meta 
          name="description" 
          content={isExtension ? 
            "Extend an existing contract with a consultant on fetchConsultant." : 
            "Offer a contract to a consultant on fetchConsultant."} 
        />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ClientHamburger user={user} currentPage="offer-contract" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white shadow-md p-4 flex items-center justify-between">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Offer Contract</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card">
            <h2 className="text-2xl font-semibold mb-4">
              {isExtension ? 'Extend Contract for ' : 'Offer Contract to '} 
              {applicant?.users?.name}
            </h2>
            {isExtension && (
              <div className="mb-4 p-4 bg-blue-50 text-blue-700 rounded-md">
                <p>You are extending an existing contract. The new contract will start after the current contract ends.</p>
              </div>
            )}
              <form onSubmit={handleSubmit} className="fetch-form">
                <div className="relative">
                  <label className="fetch-label flex items-center">
                    Job Title
                    <Info
                        className="ml-2 text-blue-500 cursor-pointer"
                        size={18}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => setShowTooltip(!showTooltip)}
                      />
                  </label>
                  {showTooltip && (
                    <div className="tooltip">
                      This title will appear in the Statement of Work.
                    </div>
                  )}
                  <input
                    type="text"
                    name="job_title"
                    value={formData.job_title}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>

                <div className="relative">
                  <label className="fetch-label flex items-center">
                    Job Description
                    <Info
                        className="ml-2 text-blue-500 cursor-pointer"
                        size={18}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => setShowTooltip(!showTooltip)}
                      />
                  </label>
                  {showTooltip && (
                    <div className="tooltip">
                      This title will appear in the Statement of Work.
                    </div>
                  )}
                  <ReactQuill
                    value={formData.job_description}
                    onChange={handleDescriptionChange}
                    className="fetch-input"
                    modules={modules}
                    formats={formats}
                  />
                </div>

                <div className="relative">
                  <label className="fetch-label flex items-center">
                    Contract Rate ($/hour)
                    <Info
                        className="ml-2 text-blue-500 cursor-pointer"
                        size={18}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => setShowTooltip(!showTooltip)}
                      />
                  </label>
                  {showTooltip && (
                    <div className="tooltip">
                      This title will appear in the Statement of Work.
                    </div>
                  )}
                  <input
                    type="number"
                    name="contract_rate"
                    value={formData.contract_rate}
                    onChange={handleInputChange}
                    className="fetch-input"
                    required
                  />
                </div>

                <div className="relative">
                  <label className="fetch-label flex items-center">
                    Contract Dates
                    <Info
                        className="ml-2 text-blue-500 cursor-pointer"
                        size={18}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onClick={() => setShowTooltip(!showTooltip)}
                      />
                  </label>
                  {showTooltip && (
                    <div className="tooltip">
                      This title will appear in the Statement of Work.
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <label className="fetch-label">Start Date</label>
                      <input
                        type="date"
                        name="start_date"
                        value={formData.start_date}
                        onChange={handleInputChange}
                        className="fetch-input"
                        required
                      />
                    </div>
                    <div>
                      <label className="fetch-label">End Date</label>
                      <input
                        type="date"
                        name="end_date"
                        value={formData.end_date}
                        onChange={handleInputChange}
                        className="fetch-input"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="flex justify-between mt-6">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="fetch-button bg-gray-500 hover:bg-gray-600"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="fetch-button"
                  >
                    Send Contract Offer
                  </button>
                </div>
              </form>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default ClientOfferContract;