// src/components/ClientLanding.js

import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Clock, DollarSign, Shield, CheckCircle } from 'lucide-react';

const ClientLanding = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm fixed w-full z-10">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-indigo-600">fetchConsultant</Link>
            <div>
              <Link to="/app" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
                Post a Job
              </Link>
            </div>
          </div>
        </nav>
      </header>

      <main className="pt-16">
        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h1 className="text-5xl font-bold mb-4">Find Top Epic Consultants Fast</h1>
            <p className="text-xl mb-8">Streamline your hiring process and reduce costs with direct access to skilled professionals.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Post a Job
            </Link>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Why Choose fetchConsultant?</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Users className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Quality Talent Pool</h3>
                <p className="text-gray-600">Access a curated network of experienced Epic consultants.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Clock className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Faster Hiring</h3>
                <p className="text-gray-600">Reduce time-to-hire with our streamlined process and direct consultant interactions.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <DollarSign className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Cost Savings</h3>
                <p className="text-gray-600">Save on staffing costs with our transparent 9% fee structure.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">How It Works</h2>
            <div className="max-w-3xl mx-auto">
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">1</div>
                <p className="text-lg"><span className="font-semibold">Post Your Job:</span> Describe your Epic consulting needs and requirements.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">2</div>
                <p className="text-lg"><span className="font-semibold">Review Applications:</span> Receive and evaluate applications from qualified consultants.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">3</div>
                <p className="text-lg"><span className="font-semibold">Interview and Select:</span> Conduct interviews and choose the best fit for your project.</p>
              </div>
              <div className="flex items-center">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">4</div>
                <p className="text-lg"><span className="font-semibold">Hire and Onboard:</span> Finalize the contract and begin your project with your new consultant.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Benefits for Healthcare Organizations</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="flex items-start">
              <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Cost-Effective Staffing</h3>
                  <p className="text-gray-600">Reduce your staffing costs with our transparent pricing model and direct access to consultants.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Faster Time-to-Hire</h3>
                  <p className="text-gray-600">Fill your Epic consulting positions quickly with our streamlined hiring process.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Quality Assurance</h3>
                  <p className="text-gray-600">Access a network of vetted, experienced Epic consultants with verified skills and certifications.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Flexibility and Control</h3>
                  <p className="text-gray-600">Manage your hiring process directly, with the ability to communicate with consultants in real-time.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">What Our Clients Say</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mb-4">"fetchConsultant has revolutionized our Epic staffing process. We've reduced our time-to-hire by 50% and found high-quality consultants that perfectly fit our needs."</p>
                <p className="font-semibold">- Sarah Johnson, CIO at Metro Health System</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <p className="text-gray-600 mb-4">"The direct access to consultants and transparent pricing model have significantly reduced our staffing costs. fetchConsultant is now our go-to platform for Epic talent."</p>
                <p className="font-semibold">- Michael Lee, IT Director at Central Hospital</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Transform Your Epic Staffing?</h2>
            <p className="text-xl mb-8">Join fetchConsultant today and experience the future of healthcare IT staffing.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Post Your First Job
            </Link>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-6">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">fetchConsultant</h3>
              <p className="text-sm">Revolutionizing Epic consulting connections for healthcare organizations.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
              <ul className="text-sm">
                <li><Link to="/" className="hover:text-indigo-400">Home</Link></li>
                <li><Link to="/consultants" className="hover:text-indigo-400">For Consultants</Link></li>
                <li><a href="#" className="hover:text-indigo-400">FAQ</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h3 className="text-lg font-semibold mb-2">Connect With Us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/fetchconsultant" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-400">
                  <img src="/images/x-logo.png" alt="X logo" className="w-6 h-6" />
                </a>
                <a href="https://linkedin.com/company/fetchconsultant" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-400">
                  <img src="/images/linkedin-logo.png" alt="LinkedIn logo" className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-sm text-center">
            <p>&copy; 2024 fetchConsultant. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ClientLanding;