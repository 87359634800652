// Import necessary modules and components
import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu, } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import ConfirmationDialog from './ui/ConfirmationDialog';

// Import date-fns functions
import { format } from 'date-fns-tz';
// Import the createZoomMeeting function
import { createZoomMeeting } from '../utils/zoomIntegration';

const ConsultantRequests = () => {
  const [requests, setRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [notification, setNotification] = useState({ message: '', type: '', show: false });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogAction, setDialogAction] = useState(() => () => {});
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [dialogReason, setDialogReason] = useState(''); // Reason input for decline dialog
  const [showReasonField, setShowReasonField] = useState(false); // Controls whether reason field is shown
  const [confirmButtonText, setConfirmButtonText] = useState(''); // Text for the confirm button
  const [confirmButtonColor, setConfirmButtonColor] = useState('blue'); // Color for the confirm button
  const navigate = useNavigate();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Consultant Requests',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchRequests();
  }, []);

  useEffect(() => {
    const hasPendingRequests = requests.length > 0;
    localStorage.setItem('hasRequests', hasPendingRequests.toString());
  }, [requests]);

  const formatContractDate = (dateString) => {
    if (!dateString) return '';
    const [year, month, day] = dateString.split('-');
    return `${month}-${day}-${year}`;
  };

  const fetchUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error("No authenticated user found");

      const { data, error } = await supabase
        .from('users')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (error) throw error;
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchRequests = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      const { data, error } = await supabase
        .from('user_jobs')
        .select(`
          *,
          job:jobs!inner(
            job_id, 
            title,
            client:clients(name)
          ),
          engagements(
            id,
            ica_document_id,
            contract_rate,
            start_date,
            end_date,
            status,
            job_title,
            job_description
          ),
          interviews(interview_time, zoom_meeting_link)
        `)
        .eq('user_id', user.id)
        .in('status', ['Interview Requested', 'Second Interview Requested', 'Contract Offered'])
        .order('last_updated_at', { ascending: false });
  
      if (error) throw error;
      console.log('Fetched requests:', data);
  
      setRequests(data);
    } catch (error) {
      console.error('Error fetching requests:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to show notifications
  const showNotification = (message, type) => {
    setNotification({ message, type, show: true });
    // Hide notification after 5 seconds
    setTimeout(() => setNotification({ ...notification, show: false }), 5000);
  };

  // Function to generate ICS data
  const generateICS = (startDate, request, meetingDetails, user) => {
    const endDate = new Date(startDate.getTime() + 60 * 60 * 1000); // 1 hour later
    const now = new Date();

    const formatDate = (date) => format(date, "yyyyMMdd'T'HHmmss'Z'", { timeZone: 'UTC' });

    const attendees = [
      user.email, // Consultant's email
      request.job.client.email, // Client's email
    ].join(',');

    const isSecondInterview = request.status === 'Second Interview Requested';
    const interviewType = isSecondInterview ? 'Second' : 'First';

    return `BEGIN:VCALENDAR
VERSION:2.0
PRODID:-//fetchConsultant//Interview//EN
CALSCALE:GREGORIAN
METHOD:REQUEST
BEGIN:VEVENT
DTSTART:${formatDate(startDate)}
DTEND:${formatDate(endDate)}
DTSTAMP:${formatDate(now)}
ORGANIZER;CN=${user.name}:MAILTO:${user.email}
UID:${Math.random().toString(36).substring(2)}@fetchconsultant.com
ATTENDEE;ROLE=REQ-PARTICIPANT;PARTSTAT=NEEDS-ACTION;RSVP=TRUE:${attendees}
SUMMARY:${interviewType} Interview for ${request.job.title}
DESCRIPTION:${interviewType} interview with ${request.job.client.name} for ${request.job.title}\\nZoom link: ${meetingDetails.join_url}
LOCATION:${meetingDetails.join_url}
URL:${meetingDetails.join_url}
STATUS:CONFIRMED
SEQUENCE:0
END:VEVENT
END:VCALENDAR`;
};

  const confirmAction = (
    action, 
    title, 
    message, 
    confirmButtonText = 'Confirm', 
    confirmButtonColor = 'blue', 
    showReasonField = false
  ) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogAction(() => action);
    setDialogOpen(true);
    setShowReasonField(showReasonField); // Dynamically toggle the reason field
    setConfirmButtonText(confirmButtonText);
    setConfirmButtonColor(confirmButtonColor);
  };

  const getDialogDetails = (status) => {
    switch (status) {
      case 'Interview Requested':
        return { title: 'Decline First Interview', message: 'Are you sure you want to decline this first interview?' };
      case 'Second Interview Requested':
        return { title: 'Decline Second Interview', message: 'Are you sure you want to decline this second interview?' };
      case 'Contract Offered':
        return { title: 'Decline Contract', message: 'Are you sure you want to decline this contract offer?' };
      default:
        return { title: 'Confirmation', message: 'Are you sure?' };
    }
  };

  // Function to handle accepting a request
  const handleAccept = (userJobId, slot = null) => {
    const request = requests.find(r => r.user_job_id === userJobId);

    confirmAction(
      async () => {
        setIsLoading(true);

        try {

        if (request.status === 'Interview Requested') {
          if (!slot) {
            showNotification('Please select a time slot.', 'error');
            return;
          }
    
          // Convert slot to Date object
          const slotDate = new Date(slot);
          console.log('Slot date (local):', slotDate);
    
          // Create Zoom meeting
          const zoomUtcDate = slotDate.toISOString().split('.')[0];
          console.log('Zoom UTC date:', zoomUtcDate);
    
          const meetingTopic = `First Interview for ${request.job.title} with ${request.job.client.name}`;
    
          const meetingDetails = await createZoomMeeting(
            meetingTopic,
            zoomUtcDate,
            60 // Assuming 60 minutes duration
          );
    
          console.log('Zoom meeting details:', meetingDetails);
    
          if (!meetingDetails || !meetingDetails.id || !meetingDetails.join_url) {
            throw new Error('Failed to create Zoom meeting. Please try again.');
          }
    
          // Generate ICS data
          const icsData = generateICS(slotDate, request, meetingDetails, user);
          console.log('Generated ICS data:', icsData);
    
          // Insert new interview record
          const { data: interviewData, error: interviewError } = await supabase
            .from('interviews')
            .insert({
              user_job_id: userJobId,
              interview_time: slotDate.toISOString(),
              is_second_interview: false,
              zoom_meeting_link: meetingDetails.join_url,
              zoom_meeting_id: meetingDetails.id.toString(),
              ics_data: icsData,
              additional_invitees: [user.email, request.job.client.email],
            })
            .select()
            .single();
    
          if (interviewError) throw interviewError;
    
          console.log('Inserted interview data:', interviewData);
    
          // Update user_jobs table
          const { error: userJobError } = await supabase
            .from('user_jobs')
            .update({ status: 'Interview Scheduled' })
            .eq('user_job_id', userJobId);
    
          if (userJobError) throw userJobError;
    
          showNotification('First interview scheduled successfully!', 'success');
        }
    
        if (request.status === 'Second Interview Requested') {
          if (!slot) {
            showNotification('Please select a time slot.', 'error');
            return;
          }
    
          // Convert slot to Date object
          const slotDate = new Date(slot);
          console.log('Slot date (local):', slotDate);
    
          // Create Zoom meeting
          const zoomUtcDate = slotDate.toISOString().split('.')[0];
          console.log('Zoom UTC date:', zoomUtcDate);
    
          const meetingTopic = `Second Interview for ${request.job.title} with ${request.job.client.name}`;
    
          const meetingDetails = await createZoomMeeting(
            meetingTopic,
            zoomUtcDate,
            60 // Assuming 60 minutes duration
          );
    
          console.log('Zoom meeting details:', meetingDetails);
    
          if (!meetingDetails || !meetingDetails.id || !meetingDetails.join_url) {
            throw new Error('Failed to create Zoom meeting. Please try again.');
          }
    
          // Generate ICS data
          const icsData = generateICS(slotDate, request, meetingDetails, user);
          console.log('Generated ICS data:', icsData);
    
          // Insert new interview record
          const { data: interviewData, error: interviewError } = await supabase
            .from('interviews')
            .insert({
              user_job_id: userJobId,
              interview_time: slotDate.toISOString(),
              is_second_interview: true,
              zoom_meeting_link: meetingDetails.join_url,
              zoom_meeting_id: meetingDetails.id.toString(),
              ics_data: icsData,
              additional_invitees: [user.email, request.job.client.email],
            })
            .select()
            .single();
    
          if (interviewError) throw interviewError;
    
          console.log('Inserted interview data:', interviewData);
    
          // Update user_jobs table
          const { error: userJobError } = await supabase
            .from('user_jobs')
            .update({ status: 'Second Interview Scheduled' })
            .eq('user_job_id', userJobId);
    
          if (userJobError) throw userJobError;

          // Update requests state to remove accepted record
          setRequests((prevRequests) =>
            prevRequests.filter((r) => r.user_job_id !== userJobId)
          );
    
          showNotification('Second interview scheduled successfully!', 'success');
        }

        if (request.status === 'Contract Offered') {
          const engagement = request.engagements[0];
          if (!engagement?.id) {
            console.error('Invalid engagement:', engagement);
            throw new Error('Invalid engagement ID');
          }
        
          try {
            const { data: { session } } = await supabase.auth.getSession();
            
            // Step 1: Generate ICA
            let response = await fetch(`${process.env.REACT_APP_SUPABASE_FUNCTIONS_URL}/generateICA`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session?.access_token}`
              },
              body: JSON.stringify({
                engagementId: engagement.id
              }),
            });
        
            if (!response.ok) {
              const error = await response.json();
              throw new Error(error.error || 'Failed to generate ICA');
            }
        
            const icaData = await response.json();
            if (!icaData.documentId) {
              throw new Error('No document ID received');
            }
        
            // Step 2: Get Signing Link
            response = await fetch(`${process.env.REACT_APP_SUPABASE_FUNCTIONS_URL}/getSigningLink`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${session?.access_token}`
              },
              body: JSON.stringify({
                documentId: icaData.documentId,
                recipientEmail: user.login_email,
                recipientId: 'consultant'
              }),
            });
        
            const responseData = await response.text();
            console.log('Signing link response:', responseData);
        
            let signingData;
            try {
              signingData = JSON.parse(responseData);
            } catch (e) {
              throw new Error(`Invalid JSON response: ${responseData}`);
            }
        
            if (!response.ok || !signingData.success) {
              throw new Error(signingData.error || 'Failed to get signing link');
            }
        
            if (!signingData.signingLink) {
              throw new Error('No signing link received');
            }
        
          // Step 3: Update User Jobs Status and Engagements
          try {
            const now = new Date().toISOString();

            // Update the user_jobs table
            const { error: userJobError } = await supabase
              .from('user_jobs')
              .update({
                status: 'Contract Accepted', // Update the status as required
              })
              .eq('user_job_id', userJobId);

            if (userJobError) throw userJobError;

            // Update the engagements table
            const { error: engagementError } = await supabase
              .from('engagements')
              .update({
                status: 'Contract Accepted', // Update the status as required
                accepted_at: now, // Set the accepted_at timestamp
              })
              .eq('id', engagement.id);

            if (engagementError) throw engagementError;

            console.log('Successfully updated user_jobs and engagements statuses');
          } catch (error) {
            console.error('Error updating statuses:', error);
            throw error;
          }
        
            // Step 4: Redirect to Signing Link
            window.location.href = signingData.signingLink;
        
          } catch (error) {
            console.error('Error processing contract offer:', error);
            showNotification('Failed to process the contract offer. Please try again.', 'error');
          }
        }

          setRequests((prevRequests) =>
            prevRequests.filter((r) => r.user_job_id !== userJobId)
          );
          
        } catch (error) {
          console.error('Error accepting request:', error.message);
          showNotification('Failed to process your request. Please try again.', 'error');
        } finally {
          setIsLoading(false);
        }
      },
      'Accept Request',
      'Are you sure you want to accept this request?',
      'Accept',
      'green',
      false // Do NOT show reason field
    );
  };

  const handleDecline = (userJobId) => {
    const request = requests.find(r => r.user_job_id === userJobId);
    confirmAction(
      async (reason) => {
        // Handle the decline action with a reason
        try {
          const { error } = await supabase
            .from('user_jobs')
            .update({
              status: request.status === 'Interview Requested' ? 'Interview Declined' : 
                     request.status === 'Second Interview Requested' ? 'Second Interview Declined' : 
                     'Contract Declined',
              decline_reason: reason,
            })
            .eq('user_job_id', userJobId);
  
          if (error) throw error;
  
          if (request.status === 'Contract Offered' && request.engagements?.length > 0) {
            const { error: engagementError } = await supabase
              .from('engagements')
              .update({ 
                status: 'Contract Declined', 
                contract_decline_reason: dialogReason 
              })
              .eq('id', request.engagements[0].id);
  
            if (engagementError) throw engagementError;
          }
  
          fetchRequests();
        } catch (error) {
          console.error('Error declining request:', error);
          throw error;
        }
      },
      'Decline Request',
      'Are you sure you want to decline this request?',
      'Decline',
      'red',
      true // Show reason field
    );
  };

  const formatLocalDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    return date.toLocaleString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };

  const formatJobDescription = (html) => {
    try {
      let formatted = html
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '\n')
        .replace(/<strong>/g, '**')
        .replace(/<\/strong>/g, '**')
        .replace(/<em>/g, '_')
        .replace(/<\/em>/g, '_')
        .replace(/<ol>/g, '\n')
        .replace(/<\/ol>/g, '\n')
        .replace(/<ul>/g, '\n')
        .replace(/<\/ul>/g, '\n')
        .replace(/<li>/g, (_, i) => html.includes('<ol>') ? `${i + 1}. ` : '• ')
        .replace(/<\/li>/g, '\n');
      
      formatted = formatted.replace(/\n\s*\n/g, '\n');
      return formatted.trim();
    } catch (error) {
      console.error('Error formatting job description:', error);
      return html;
    }
  };

  return (
    <>
      <Helmet>
        <title>Consultant Requests | fetchConsultant</title>
        <meta name="description" content="Manage your interview requests and contract offers on fetchConsultant." />
      </Helmet>
      <div className="min-h-screen bg-gray-100 flex">
        <ConsultantHamburger user={user} currentPage="requests" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} hasRequests={requests.length >0} />
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Requests</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            {notification.show && (
              <div className={`mb-4 p-4 rounded ${notification.type === 'error' ? 'bg-red-200' : 'bg-green-200'}`}>
                {notification.message}
              </div>
            )}
            {isLoading ? (
              <p>Loading requests...</p>
            ) : requests.length === 0 ? (
              <p>No pending requests or offers.</p>
            ) : (
              <ul className="space-y-4">
                {requests.map((request) => (
                  <li key={request.user_job_id} className="bg-white p-6 rounded-lg shadow-md relative">
                    <div className="absolute top-0 left-0 flex">
                    <span className="bg-indigo-800 text-white text-xs font-semibold px-3 py-1 rounded">
                      {request.status === 'Second Interview Requested' ? 'Second Interview Request' : 
                      request.status === 'Interview Requested' ? 'First Interview Request' : 
                      'Contract Offer'}
                    </span>
                    </div>
                    {request.status === 'Interview Requested' && (
                      <div className="text-left">
                        <h2 className="text-xl mt-2 font-semibold">{request.job.client.name}</h2>
                        <p className="mb-6">{request.job.title}</p>
                        <div className="mb-6">
                          <h3 className="font-semibold mb-2">Choose one of the proposed alternative interview times:</h3>
                          <ul className="space-y-2">
                            {request.client_slots && request.client_slots.map((slot, index) => (
                              <li
                                key={index}
                                className="p-2 bg-gray-50 rounded cursor-pointer hover:bg-blue-600 hover:text-white text-left"
                                onClick={() => handleAccept(request.user_job_id, slot)}
                              >
                                {formatLocalDateTime(slot)}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="flex justify-end space-x-2 mt-6">
                          <button
                            onClick={() => handleDecline(request.user_job_id)}
                            className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          >
                            Decline
                          </button>
                        </div>
                      </div>
                    )}
                    {request.status === 'Second Interview Requested' && (
                    <div className="text-left">
                      <h2 className="text-xl mt-2 font-semibold">{request.job.client.name}</h2>
                      <p className="mb-6">{request.job.title}</p>
                        <div className="mb-6">
                          <h3 className="font-semibold mb-2">Choose one of the proposed interview times:</h3>
                          <ul className="space-y-2">
                            {request.second_interview_slots && request.second_interview_slots.map((slot, index) => (
                              <li
                                key={index}
                                className="p-2 bg-gray-50 rounded cursor-pointer hover:bg-blue-600 hover:text-white text-left"
                                onClick={() => handleAccept(request.user_job_id, slot)}
                              >
                                {formatLocalDateTime(slot)}
                              </li>
                            ))}
                          </ul>
                        </div>
                      <div className="flex justify-end space-x-2 mt-6">
                        <button
                          onClick={() => handleDecline(request.user_job_id)}
                          className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Decline
                        </button>
                      </div>
                    </div>
                    )}
                    {request.status === 'Contract Offered' && request.engagements && request.engagements.length > 0 && (
                      <div className="text-left">
                        <h2 className="text-xl mt-2 font-semibold">{request.job.client.name}</h2>
                        <h3 className="mb-6">{request.engagements[0].job_title || request.job.title}</h3>
                        <div 
                          className="mt-2 text-sm whitespace-pre-line"
                          style={{ listStyle: 'disc', marginLeft: '20px' }}
                          dangerouslySetInnerHTML={{ 
                            __html: formatJobDescription(request.engagements[0].job_description || '')
                          }}
                        />
                        <div className="mb-6">
                          <div className="mb-4">
                            <p>Start Date: {request.engagements[0].start_date ? formatContractDate(request.engagements[0].start_date) : 'TBD'}</p>
                            <p>End Date: {request.engagements[0].end_date ? formatContractDate(request.engagements[0].end_date) : 'TBD'}</p>
                            <p>Rate: ${request.engagements[0].contract_rate?.toFixed(2) || request.consultant_rate}/hr</p>
                          </div>
                        </div>
                        <div className="flex justify-end space-x-2 mt-6">
                          <button
                            onClick={() => handleDecline(request.user_job_id)}
                            className="inline-flex items-center justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                          >
                            Decline
                          </button>
                          <button
                            onClick={() => handleAccept(request.user_job_id)} 
                            className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          >
                            Accept Offer
                          </button>
                        </div>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </main>
        </div>
      </div>
      <ConfirmationDialog
  isOpen={dialogOpen}
  onClose={() => {
    setDialogOpen(false);
    setDialogReason('');
  }}
  onConfirm={async () => {
    try {
      await dialogAction(dialogReason);
      if (dialogTitle !== 'Accept Contract') {
        showNotification('Action successfully completed!', 'success');
      }
    } catch (error) {
      console.error('Error confirming action:', error.message);
      showNotification('Failed to complete action. Please try again.', 'error');
    } finally {
      setDialogOpen(false);
      setDialogReason('');
    }
  }}
  title={dialogTitle}
  message={dialogMessage}
  confirmButtonText={confirmButtonText}
  confirmButtonColor={confirmButtonColor}
  showReasonField={showReasonField}
  reason={dialogReason}
  setReason={setDialogReason}
/>
    </>
  );
};

export default ConsultantRequests;