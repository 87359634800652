// src/components/ConsultantLanding.js

import React from 'react';
import { Link } from 'react-router-dom';
import { DollarSign, Users, Clock, Shield, CheckCircle } from 'lucide-react';

const ConsultantLanding = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm fixed w-full z-10">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <Link to="/" className="text-2xl font-bold text-indigo-600">fetchConsultant</Link>
            <div>
              <Link to="/app" className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
                Sign Up
              </Link>
            </div>
          </div>
        </nav>
      </header>

      <main className="pt-16">
        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h1 className="text-5xl font-bold mb-4">Maximize Your Epic Consulting Career</h1>
            <p className="text-xl mb-8">Higher earnings, more control, and direct client connections.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Get Started
            </Link>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Why Choose fetchConsultant?</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-md">
                <DollarSign className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Higher Earnings</h3>
                <p className="text-gray-600">Keep more of what you earn with our flat 9% fee, compared to traditional agencies taking 30-40%.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Users className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Direct Client Connections</h3>
                <p className="text-gray-600">Connect directly with healthcare organizations, bypassing traditional staffing agencies.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <Clock className="text-indigo-600 mb-4" size={32} />
                <h3 className="text-xl font-semibold mb-2">Faster Placements</h3>
                <p className="text-gray-600">Get placed in days, not weeks, with our streamlined process and direct client interactions.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-gray-100 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">How It Works</h2>
            <div className="max-w-3xl mx-auto">
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">1</div>
                <p className="text-lg"><span className="font-semibold">Create Your Profile:</span> Showcase your skills, certifications, and experience.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">2</div>
                <p className="text-lg"><span className="font-semibold">Browse Opportunities:</span> Find contracts that match your skills and preferences.</p>
              </div>
              <div className="flex items-center mb-6">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">3</div>
                <p className="text-lg"><span className="font-semibold">Apply Directly:</span> Submit your application and desired rate to clients.</p>
              </div>
              <div className="flex items-center">
                <div className="bg-indigo-600 rounded-full p-3 text-white mr-4">4</div>
                <p className="text-lg"><span className="font-semibold">Get Hired:</span> Interview, receive offers, and start your new contract.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">Benefits of fetchConsultant</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Transparent Pricing</h3>
                  <p className="text-gray-600">Know exactly what you'll earn with our simple 9% fee structure.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Career Control</h3>
                  <p className="text-gray-600">Choose contracts that align with your career goals and preferences.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Efficient Process</h3>
                  <p className="text-gray-600">Our streamlined platform reduces time-to-hire and administrative overhead.</p>
                </div>
              </div>
              <div className="flex items-start">
                <CheckCircle className="text-indigo-600 mr-4 flex-shrink-0" size={24} />
                <div>
                  <h3 className="text-xl font-semibold mb-2">Quality Opportunities</h3>
                  <p className="text-gray-600">Access a wide range of vetted Epic consulting opportunities.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-indigo-700 text-white py-20">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-3xl font-bold mb-4">Ready to Elevate Your Consulting Career?</h2>
            <p className="text-xl mb-8">Join fetchConsultant today and experience the future of Epic consulting.</p>
            <Link to="/app" className="bg-white text-indigo-700 font-bold py-3 px-8 rounded-full text-lg hover:bg-gray-100 transition duration-300">
              Sign Up Now
            </Link>
          </div>
        </section>
      </main>

      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-6">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">fetchConsultant</h3>
              <p className="text-sm">Revolutionizing Epic consulting connections.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
              <ul className="text-sm">
                <li><Link to="/" className="hover:text-indigo-400">Home</Link></li>
                <li><Link to="/clients" className="hover:text-indigo-400">For Clients</Link></li>
                <li><a href="#" className="hover:text-indigo-400">FAQ</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h3 className="text-lg font-semibold mb-2">Connect With Us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/fetchconsultant" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-400">
                  <img src="/images/x-logo.png" alt="X logo" className="w-6 h-6" />
                </a>
                <a href="https://linkedin.com/company/fetchconsultant" target="_blank" rel="noopener noreferrer" className="hover:text-indigo-400">
                  <img src="/images/linkedin-logo.png" alt="LinkedIn logo" className="w-6 h-6" />
                </a>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-700 mt-8 pt-8 text-sm text-center">
            <p>&copy; 2024 fetchConsultant. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ConsultantLanding;