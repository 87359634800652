import { getAnalytics, logEvent } from "firebase/analytics";
import { ChevronLeft, Menu, MoreHorizontal, Star } from 'lucide-react';
import React, { useEffect, useState, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import PullToRefresh from 'react-pull-to-refresh';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { UserContext } from '../UserContext';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from "./NotificationBell";
import Spinner from './Spinner';

const ReviewModal = ({ review, onClose }) => {
  if (!review) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg">
        <h2 className="text-xl font-bold mb-4">Review by {review.reviewer.name}</h2>
        <p className="text-left text-gray-700 mb-4">{review.review_text}</p>
        <button
          className="bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [averageRatings, setAverageRatings] = useState({ overall: 0, peer: 0, manager: 0 });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null); // Add state for the modal
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'desc' });
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Reviews',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });
  
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener('resize', checkMobile);
  
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
  
    if (user) {
      fetchReviews();
    }
  
    return () => {
      window.removeEventListener('resize', checkMobile);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [user, sortConfig, dropdownRef]);

  const fetchReviews = async () => {
    if (!user) return;
    setIsLoading(true);
    try {
      // Fetch completed reviews
      const { data: completedReviews, error: reviewsError } = await supabase
        .from('reviews')
        .select(`
          *,
          reviewer:users!reviews_reviewer_id_fkey(
            name,
            employer
          )
        `)
        .eq('consultant_id', user.user_id)
        .order(sortConfig.key, { ascending: sortConfig.direction === 'asc' });
  
      if (reviewsError) throw reviewsError;
  
      // Fetch pending review tokens
      const { data: pendingReviews, error: tokensError } = await supabase
        .from('review_tokens')
        .select(`
          *,
          user_jobs(job:jobs(title))
        `)
        .eq('consultant_id', user.user_id)
        .eq('status', 'pending')
        .order('created_at', { ascending: false });
  
      if (tokensError) throw tokensError;
  
      // Combine completed and pending reviews
      const allReviews = [
        ...completedReviews.map(review => ({ ...review, status: 'completed' })),
        ...pendingReviews.map(token => ({
          review_id: token.id,
          review_type: token.review_type,
          rating: null,
          review_text: 'Pending review',
          created_at: token.used_at ? token.used_at : token.created_at, // Use 'used_at' if available, else 'created_at'
          reviewer: { name: token.reviewer_email || token.reviewer_phone },
          status: 'pending',
          user_jobs: token.user_jobs
        }))
      ];
  
      // Fetch client information for each review
      const reviewsWithClients = await Promise.all(
        allReviews.map(async (review) => {
          if (review.reviewer.employer) {
            const { data: clientData, error: clientError } = await supabase
              .from('clients')
              .select('name')
              .eq('client_id', review.reviewer.employer)
              .single();
  
            if (clientError) {
              console.error('Error fetching client:', clientError);
              return { ...review, clientName: null };
            }
  
            return { ...review, clientName: clientData.name };
          }
          return { ...review, clientName: null };
        })
      );
  
      setReviews(reviewsWithClients);
  
      // Calculate average ratings (only for completed reviews)
      const completedReviewsWithClients = reviewsWithClients.filter(review => review.status === 'completed');
      const peerReviews = completedReviewsWithClients.filter(review => review.review_type === 'peer');
      const managerReviews = completedReviewsWithClients.filter(review => review.review_type === 'manager');
  
      const calcAverage = (reviews) => reviews.length > 0 ? reviews.reduce((sum, review) => sum + review.rating, 0) / reviews.length : 0;
  
      setAverageRatings({
        overall: calcAverage(completedReviewsWithClients),
        peer: calcAverage(peerReviews),
        manager: calcAverage(managerReviews),
        overallCount: completedReviewsWithClients.length,
        peerCount: peerReviews.length,
        managerCount: managerReviews.length
      });
  
    } catch (error) {
      console.error('Error fetching reviews:', error.message);
    } finally {
      setIsLoading(false);
    }
  };

const handleSort = (key) => {
  const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
  setSortConfig({ key, direction });
};

const toggleDropdown = (reviewId) => {
  setActiveDropdown(activeDropdown === reviewId ? null : reviewId);
};

const renderStars = (rating) => {
  const stars = [];
  const roundedRating = Math.round(rating * 2) / 2; // Round to nearest 0.5
  for (let i = 1; i <= 5; i++) {
    let starFill = Math.min(Math.max(roundedRating - (i - 1), 0), 1);
    if (starFill === 1) {
      stars.push(<Star key={i} size={20} fill="gold" stroke="gold" />);
    } else if (starFill > 0) {
      stars.push(
        <div key={i} className="relative">
          <Star size={20} fill="none" stroke="gold" />
          <div
            className="absolute top-0 left-0 overflow-hidden"
            style={{ width: `${starFill * 100}%` }}
          >
            <Star size={20} fill="gold" stroke="gold" />
          </div>
        </div>
      );
    } else {
      stars.push(<Star key={i} size={20} fill="none" stroke="gold" />);
    }
  }
  return stars;
};


const handleViewFullReview = (review) => {
  setSelectedReview(review); // Set the selected review for the modal
};

const closeReviewModal = () => {
  setSelectedReview(null); // Close the modal
};

const renderReviewsTable = () => (
  <div className="bg-white shadow-md rounded-lg">
    <table className="min-w-full table-auto">
      <thead className="bg-gray-100">
        <tr>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('reviewer.name')} className="cursor-pointer">
              Reviewer {sortConfig.key === 'reviewer.name' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('review_type')} className="cursor-pointer">
              Type {sortConfig.key === 'review_type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('rating')} className="cursor-pointer">
              Rating {sortConfig.key === 'rating' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('user_jobs.job.title')} className="cursor-pointer">
              Job {sortConfig.key === 'user_jobs.job.title' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3 text-left text-sm font-medium text-gray-700">
            <div onClick={() => handleSort('created_at')} className="cursor-pointer">
              Requested {sortConfig.key === 'created_at' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </div>
          </th>
          <th className="px-6 py-3"></th>
        </tr>
      </thead>
      <tbody>
        {reviews.map((review) => (
          <tr key={review.review_id} className="hover:bg-gray-50">
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {review.reviewer.name}
            </td>
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {review.review_type.charAt(0).toUpperCase() + review.review_type.slice(1)}
            </td>
            <td className="px-6 py-4 text-sm text-left">
              {review.status === 'completed' ? (
                <div className="flex items-center">
                  {renderStars(review.rating)}
                  <span className="ml-2">{review.rating.toFixed(1)}</span>
                </div>
              ) : (
                <span className="text-yellow-600">Pending</span>
              )}
            </td>
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {review.user_jobs?.job?.title || 'N/A'}
            </td>
            <td className="px-6 py-4 text-sm text-gray-700 text-left">
              {new Date(review.created_at).toLocaleDateString()}
            </td>
            <td className="px-6 py-4 text-left text-sm text-gray-700 relative">
              <MoreHorizontal
                size={24}
                className="cursor-pointer"
                onClick={() => toggleDropdown(review.review_id)}
              />
              {activeDropdown === review.review_id && (
                <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                    onClick={() => handleViewFullReview(review)} // Show modal on click
                    >
                    View Full Review
                  </div>
                </div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const renderReviewsCards = () => (
  <div className="grid grid-cols-1 gap-4">
    {reviews.map((review) => (
      <div 
        key={review.review_id} 
        className="bg-white shadow-md rounded-lg p-4 relative hover:shadow-lg transition-shadow"
      >
        <div className="mb-2 flex justify-between items-center">
          <span className="bg-indigo-100 text-brandHover font-semibold px-2 py-1 rounded-md">
            {review.review_type.charAt(0).toUpperCase() + review.review_type.slice(1)} Review
          </span>
          <span>{new Date(review.created_at).toLocaleDateString()}</span>
        </div>
        <h2 className="text-xl font-bold text-gray-900 text-left mb-2">
          {review.reviewer.name}
        </h2>
        {review.status === 'completed' ? (
          <div className="flex items-center mb-2">
            {renderStars(review.rating)}
            <span className="ml-2 font-bold">{review.rating.toFixed(1)}</span>
          </div>
        ) : (
          <div className="mb-2 text-yellow-600 font-semibold">Pending Review</div>
        )}
        <div className="text-sm text-gray-500 mb-2 text-left">
          {review.user_jobs?.job?.title && <p>Job: {review.user_jobs.job.title}</p>}
          {review.clientName && <p>Client: {review.clientName}</p>}
        </div>
        {review.status === 'completed' && (
          <>
            <p className="text-gray-700 mb-2 text-left">
              {review.review_text.length > 100 
                ? `${review.review_text.substring(0, 100)}...` 
                : review.review_text}
            </p>
            {review.review_text.length > 100 && (
              <button className="text-brandPrimary hover:underline text-sm">
                Show more
              </button>
            )}
          </>
        )}
        <div className="absolute bottom-4 right-4">
          <MoreHorizontal 
            size={24} 
            className="cursor-pointer"
            onClick={() => toggleDropdown(review.review_id)}
          />
          {activeDropdown === review.review_id && (
            <div ref={dropdownRef} className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
              <div
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer text-left"
                onClick={() => handleViewFullReview(review)} // Show modal on click
                >
                View Full Review
              </div>
            </div>
          )}
        </div>
      </div>
    ))}
  </div>
);



return (
  <>
    <Helmet>
      <title>My Reviews | fetchConsultant</title>
      <meta name="description" content="View your reviews and ratings on fetchConsultant." />
    </Helmet>
    <div className="min-h-screen bg-gray-100 flex">
      <ConsultantHamburger user={user} currentPage="reviews" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
      <div className="flex-1 flex flex-col md:ml-64">
        <header className="bg-white shadow-md p-4 flex items-center justify-between">
          <div className="flex items-center">
            <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
              <Menu size={24} />
            </button>
            <ChevronLeft className="h-6 w-6 text-brandPrimary cursor-pointer" onClick={() => navigate('/app/consultant-dashboard')} />
            <h1 className="text-xl font-bold ml-4">My Reviews</h1>
          </div>
          <div className="flex items-center">
            <button
              onClick={() => navigate('/app/solicit-review')}
              className="mr-4 bg-brandPrimary text-white px-4 py-2 rounded-md hover:bg-brandHover transition duration-300"
            >
              Solicit Review
            </button>
            <NotificationBell />
          </div>
        </header>

        <main className="p-4">
          <PullToRefresh
            onRefresh={fetchReviews}
            className="pull-to-refresh"
            pullingContent={<span>Pull down to refresh...</span>}
            refreshingContent={<div className="flex items-center justify-center"><Spinner /> Refreshing...</div>}
          >
            {isLoading ? (
              <div>Loading...</div>
            ) : (
              <>
                <div className="fetch-job-card text-center rounded-lg shadow-md p-6 mb-6">
                  <h2 className="text-2xl font-bold mb-4">Average Ratings</h2>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-left">Overall Rating:</span>
                    <div className="flex items-center">
                      <span className="mr-2">({averageRatings.overallCount})</span>
                      {renderStars(averageRatings.overall)}
                      <span className="ml-2 font-bold">{averageRatings.overall.toFixed(1)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center mb-2">
                    <span className="text-left">Manager Rating:</span>
                    <div className="flex items-center">
                      <span className="mr-2">({averageRatings.managerCount})</span>
                      {renderStars(averageRatings.manager)}
                      <span className="ml-2 font-bold">{averageRatings.manager.toFixed(1)}</span>
                    </div>
                  </div>
                  <div className="flex justify-between items-center">
                    <span className="text-left">Peer Rating:</span>
                    <div className="flex items-center">
                      <span className="mr-2">({averageRatings.peerCount})</span>
                      {renderStars(averageRatings.peer)}
                      <span className="ml-2 font-bold">{averageRatings.peer.toFixed(1)}</span>
                    </div>
                  </div>
                </div>
                {isMobile ? renderReviewsCards() : renderReviewsTable()}
              </>
            )}
          </PullToRefresh>
        </main>
      </div>
    </div>

    {/* Review Modal */}
    {selectedReview && <ReviewModal review={selectedReview} onClose={closeReviewModal} />}
  </>
);
};

export default Reviews;
