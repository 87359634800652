import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { supabase } from '../supabaseClient';

const RoleBasedRoute = ({ component: Component, allowedTypes, fallbackPath }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userType, setUserType] = useState(null);
  const location = useLocation();

  const fetchUserType = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      console.log("Current authenticated user:", user);

      if (user) {
        const { data, error } = await supabase
          .from('users')
          .select('user_type_id')
          .eq('user_id', user.id)
          .single();

        if (error) {
          console.error("Error fetching user type:", error);
        } else {
          console.log("Fetched user_type_id from DB:", data.user_type_id);
          setUserType(data.user_type_id);
        }
      } else {
        console.log("No authenticated user found");
      }
    } catch (error) {
      console.error("Error during user type fetch:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUserType();
  }, [location.pathname]); // Re-fetch user type on route change

  useEffect(() => {
    console.log("Updated state: userType =", userType, ", allowedTypes =", allowedTypes, ", isLoading =", isLoading);
  }, [userType, allowedTypes, isLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!userType || !allowedTypes.includes(userType)) {
    console.log("Redirecting to fallback path:", fallbackPath, "since userType =", userType);
    return <Navigate to={fallbackPath} state={{ from: location }} replace />;
  }

  console.log("Rendering allowed component for userType:", userType);
  return <Component />;
};

export default RoleBasedRoute;