import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { supabase } from './supabaseClient.js';
import { saveFcmToken } from './utils/fcmUtils.js';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);

export { app, analytics, messaging };

export const requestForToken = async () => {
  try {
    console.log('Requesting FCM token...');
    const currentToken = await getToken(messaging, { 
      vapidKey: 'BC8M13my3b9gFIngokcWX-Ph-vmAGUZdkAcIXFvD0GOqN_Z4kqZPuEWp-S6HrKYkSkT_57ATYmzZvZ8i57cnWhU' 
    });
    if (currentToken) {
      console.log('FCM token obtained:', currentToken);
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        console.log('Saving FCM token for user:', user.id);
        await saveFcmToken(user.id, currentToken);
      } else {
        console.log('No authenticated user found');
      }
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.error('An error occurred while retrieving or saving token:', err);
  }
};

// Remove the onTokenRefresh listener

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Foreground message received:', payload);
      resolve(payload);
    });
  });

// Add a function to periodically check for token changes
export const setupTokenRefresh = () => {
  setInterval(async () => {
    const newToken = await getToken(messaging, { 
      vapidKey: 'BC8M13my3b9gFIngokcWX-Ph-vmAGUZdkAcIXFvD0GOqN_Z4kqZPuEWp-S6HrKYkSkT_57ATYmzZvZ8i57cnWhU' 
    });
    const { data: { user } } = await supabase.auth.getUser();
    if (user) {
      const { data: userData } = await supabase
        .from('users')
        .select('fcm_token')
        .eq('user_id', user.id)
        .single();
      
      if (userData && userData.fcm_token !== newToken) {
        console.log('Token has changed. Updating...');
        await saveFcmToken(user.id, newToken);
      }
    }
  }, 60 * 60 * 1000); // Check every hour
};