// src/components/Spinner.jsx
import { Loader } from 'lucide-react'; // Import the Loader icon from Lucide React
import React from 'react';

// CSS for the rotating spinner
const spinnerStyle = {
  animation: 'spin 1s linear infinite', // Add a simple spinning animation
};

const Spinner = () => {
  return (
    <div className="flex items-center justify-center">
      <Loader size={24} style={spinnerStyle} /> {/* Use Lucide's Loader icon */}
    </div>
  );
};

export default Spinner;