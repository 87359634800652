// src/components/SolicitRefer.js

import { getAnalytics, logEvent } from "firebase/analytics";
import { Menu } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import ClientHamburger from './ClientHamburger';
import ConsultantHamburger from './ConsultantHamburger';
import NotificationBell from './NotificationBell';

const SolicitRefer = () => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [referralType, setReferralType] = useState('consultant');
  const [selectedJob, setSelectedJob] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [pendingReferrals, setPendingReferrals] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_title: 'Solicit Referral',
      page_location: window.location.href,
      page_path: window.location.pathname,
    });

    fetchUser();
    fetchPendingReferrals();
    fetchJobs();

    // Auto-select the job from the previous page
    if (location.state && location.state.jobId) {
      setSelectedJob(location.state.jobId);
    }
  }, [location]);

  const fetchUser = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No authenticated user found");

      const response = await fetch(`${supabaseUrl}/functions/v1/user-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchUser',
          userId: session.user.id
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      setUser(data);
    } catch (error) {
      console.error('Error fetching user:', error.message);
    }
  };

  const fetchPendingReferrals = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No authenticated user found");

      const response = await fetch(`${supabaseUrl}/functions/v1/referral-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          action: 'fetchPendingReferrals',
          data: { userId: session.user.id } // Ensure `userId` is passed here
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      setPendingReferrals(data);
    } catch (error) {
      console.error('Error fetching pending referrals:', error.message);
    }
  };

  const fetchJobs = async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;
      if (!accessToken) throw new Error("No authenticated user found");

      const response = await fetch(`${supabaseUrl}/functions/v1/job-operations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify({
          action: 'fetchActiveJobs',
          userId: session.user.id
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error);
      }

      const { data } = await response.json();
      setJobs(data);
    } catch (error) {
      console.error('Error fetching jobs:', error.message);
    }
  };

  const formatPhoneNumber = (value) => {
    const phoneNumber = value.replace(/[^\d]/g, '');
    if (phoneNumber.length < 4) return phoneNumber;
    if (phoneNumber.length < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
  };

  const handlePhoneChange = (e) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setPhone(formattedPhoneNumber);
  };

  const unformatPhoneNumber = (value) => {
    return value.replace(/\D/g, '');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      if (!email && !phone) {
        throw new Error("Please provide either an email or a phone number");
      }

      if (!user) throw new Error("No authenticated user found");

      const unformattedPhone = unformatPhoneNumber(phone);

      console.log('Sending referral request with data:', {
        referredEmail: email,
        referredPhone: unformattedPhone,
        referralType,
        referrerId: user.user_id,
        jobId: selectedJob,
        senderName: user.name
      });

      const { data, error } = await supabase.functions.invoke('send-review-request', {
        body: {
          referredEmail: email,
          referredPhone: unformattedPhone,
          referralType,
          referrerId: user.user_id,
          jobId: selectedJob,
          senderName: user.name
        }
      });

      if (error) throw error;

      console.log('Referral request response:', data);

      const analytics = getAnalytics();
      logEvent(analytics, 'referral_solicited', {
        referral_type: referralType,
        job_id: selectedJob
      });

      setShowPopup(true);
      fetchPendingReferrals();
    } catch (error) {
      console.error('Error soliciting referral:', error);
      setError(error.message || 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRequestAnother = () => {
    setEmail('');
    setPhone('');
    setReferralType('consultant');
    setSelectedJob(null);
    setShowPopup(false);
    setError(null);
  };

  return (
    <>
      <Helmet>
        <title>Refer Someone | fetchConsultant</title>
        <meta name="description" content="Refer a consultant or client to fetchConsultant and help grow our community." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex, nofollow" />
        <meta property="og:title" content="Refer Someone | fetchConsultant" />
        <meta property="og:description" content="Refer a consultant or client to fetchConsultant and help grow our community." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://fetchconsultant.com/app/refer" />
        <meta property="og:image" content="https://fetchconsultant.com/app/path-to-image.jpg" />
      </Helmet>
      <div className="min-h-screen bg-white flex">
        {user ? (
          user.user_type_id === 4 ? (
            <ClientHamburger user={user} currentPage="refer" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
          ) : user.user_type_id === 2 ? (
            <ConsultantHamburger user={user} currentPage="refer" isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
          ) : null
        ) : null}
        <div className="flex-1 flex flex-col md:ml-64">
          <header className="bg-white p-4 flex justify-between items-center">
            <div className="flex items-center">
              <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="mr-4 md:hidden">
                <Menu size={24} />
              </button>
              <h1 className="text-xl font-bold">Refer Someone</h1>
            </div>
            <div className="flex items-center">
              <NotificationBell />
            </div>
          </header>
          <main className="flex-grow container mx-auto px-4 py-8">
            <div className="fetch-card mb-8">
              {error && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4" role="alert">
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">{error}</span>
                </div>
              )}
              <h2 className="text-2xl font-bold mb-4">Refer New Person</h2>
              <form onSubmit={handleSubmit} className="fetch-form">
                <div>
                  <label htmlFor="email" className="fetch-label">Referred Person's Email</label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="fetch-input"
                  />
                </div>
                <div>
                  <label htmlFor="phone" className="fetch-label">Referred Person's Phone Number</label>
                  <input
                    type="tel"
                    id="phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="(###) ###-####"
                    className="fetch-input"
                  />
                </div>
                <div>
                  <label htmlFor="referralType" className="fetch-label">Referral Type</label>
                  <select
                    id="referralType"
                    value={referralType}
                    onChange={(e) => setReferralType(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="consultant">Consultant</option>
                    <option value="client">Client</option>
                  </select>
                </div>
                <div>
                  <label htmlFor="job" className="fetch-label">Job (Optional)</label>
                  <select
                    id="job"
                    value={selectedJob || ''}
                    onChange={(e) => setSelectedJob(e.target.value)}
                    className="fetch-select"
                  >
                    <option value="">Select a job</option>
                    {jobs.map((job) => (
                      <option key={job.job_id} value={job.job_id}>
                        {job.clients.name} - {job.title}
                      </option>
                    ))}
                  </select>
                </div>
                <button type="submit" className="fetch-button" disabled={isLoading || (!email && !phone)}>
                  {isLoading ? 'Sending...' : 'Send Referral'}
                </button>
              </form>
            </div>

            {pendingReferrals.map((referral) => (
              <div key={referral.id} className="fetch-job-card mb-4">
                <div className="flex flex-col space-y-1">
                  <div className="flex justify-between items-center">
                    <h3 className="fetch-job-title text-xl font-semibold mb-0">{referral.referral_type === 'consultant' ? 'Consultant Referral' : 'Client Referral'}</h3>
                    <span>Referred: {new Date(referral.created_at).toLocaleDateString()}</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-gray-600">
                    <span className="font-semibold">{referral.referred_email || referral.referred_phone}</span>
                  </div>
                  {referral.referred_job && (
                    <div className="text-sm text-gray-600">
                      <span>Job: {jobs.find(job => job.job_id === referral.referred_job)?.title || 'Unknown Job'}</span>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </main>
        </div>
      </div>
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg max-w-md">
            <h2 className="text-2xl font-bold mb-4">Referral Sent</h2>
            <p className="mb-4">
              Your referral has been submitted successfully. The person you referred will receive an invitation to join fetchConsultant.
            </p>
            <div className="flex justify-between">
              <button
                onClick={handleRequestAnother}
                className="fetch-button bg-brandPrimary text-white"
              >
                Refer Another Person
              </button>
              <button
                onClick={() => navigate(user && user.user_type_id === 4 ? '/app/client-referrals' : '/app/refer')}
                className="fetch-button bg-gray-300 text-gray-800"
              >
                Go to Referrals
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SolicitRefer;