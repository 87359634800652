import React, { useState } from 'react';

const RejectFileModal = ({ isOpen, onClose, onReject }) => {
  const [notes, setNotes] = useState('');

  if (!isOpen) return null;

  const handleSubmit = () => {
    onReject(notes);
    setNotes('');
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
        <h3 className="text-lg font-semibold mb-4">Reject File</h3>
        <textarea
          className="fetch-textarea mb-4"
          placeholder="Enter rejection reason..."
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          rows={4}
        />
        <div className="flex justify-end space-x-3">
          <button
            className="px-4 py-2 bg-gray-200 rounded-md hover:bg-gray-300"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
            onClick={handleSubmit}
          >
            Reject
          </button>
        </div>
      </div>
    </div>
  );
};

export default RejectFileModal;